import _invert from 'lodash-es/invert';
import _mapValues from 'lodash-es/mapValues';

export const RAW_LATIN_AMERICA_CARIBBEAN =  'Latin America and the Caribbean';
export const LATIN_AMERICA_CARIBBEAN = 'Latin America & Caribbean';
export const LATIN_AMERICA_CARIBBEAN_OPTION = {
	name: LATIN_AMERICA_CARIBBEAN,
	id: [LATIN_AMERICA_CARIBBEAN]
}

export const RAW_NORTHERN_AMERICA =  'Northern America';
export const SPECIAL_REGIONS = [LATIN_AMERICA_CARIBBEAN, RAW_NORTHERN_AMERICA];

export const NORTH_AMERICA = 'North America';
export const SOUTH_AMERICA = 'South America';
export const AMERICA = [NORTH_AMERICA, SOUTH_AMERICA];

export const COUNTRY_NAMES_OVERRIDES = {
	US: 'United States of America',
	UK: 'United Kingdom'
};

export const COUNTRY_NAMES_TO_CODES_OVERRIDES = _invert(COUNTRY_NAMES_OVERRIDES);

export const COUNTRY_NAMES_TO_CODES_OPTIONS = _mapValues(COUNTRY_NAMES_TO_CODES_OVERRIDES, (code) => ({
	name: code,
	id: [code]
}));