import * as actionTypes from '../actions/actionTypes/filters.actionTypes';
import _mapValues from 'lodash-es/mapValues';
import _isFunction from 'lodash-es/isFunction';
import { FiltersAction } from '../actions/actionTypes/filters.actionTypes';
import { FILTER_PANELS } from '../../constants/filters';
import { FILTER_TYPES } from '../../constants/common';

export interface FiltersState {
    isLoading: boolean;
    error: any;
    filtersOptions: any;
    filtersConfig: any;
    filters: any;
    initialFilterValues: any;
}

const filtersInitialState: FiltersState = {
    isLoading: false,
    error: undefined,
    filtersOptions: {},
    filtersConfig: {},
    filters: {},
    initialFilterValues: {}
};

const getFiltersInitialValues = (filtersConfig, filterOptions) => {
    const unifiedFiltersConfig = Array.isArray(filtersConfig) ? filtersConfig : [filtersConfig];

    return unifiedFiltersConfig.reduce((filtersInitialValues, configEntry) => ({
        ...filtersInitialValues,
        ..._mapValues(configEntry, ({ filterType, defaultValueSelector }, key) => ({
            filterType,
            value: filterType === FILTER_TYPES.MULTISELECT ?
                (_isFunction(defaultValueSelector) ? defaultValueSelector(filterOptions[key]) : []) :
                {
                    fromDate: filterOptions[key]?.minDate,
                    toDate: filterOptions[key]?.maxDate
                }
        }))
    }), {});
};

export default function filtersReducer(state = filtersInitialState, action: FiltersAction): FiltersState {
    switch (action.type) {
        case actionTypes.GET_FILTERS: {
            const config = FILTER_PANELS[action.payload.search][action.payload.entity];

            return {
                ...state,
                error: undefined,
                isLoading: true,
                filters: undefined,
                initialFilterValues: undefined,
                filtersConfig: config,
            };
        }
        case actionTypes.GET_FILTERS_SUCCESS: {
            const initialLoadedFilterValues = getFiltersInitialValues(state.filtersConfig, action.payload);

            return {
                ...state,
                isLoading: false,
                error: undefined,
                filtersOptions: action.payload,
                initialFilterValues: initialLoadedFilterValues,
                filters: initialLoadedFilterValues
            };
        }
        case actionTypes.GET_FILTERS_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        }
        case actionTypes.SET_FILTERS: {
            return {
                ...state,
                filters: action.payload.filters
            };
        }
        case actionTypes.RESET_FILTER_STATE: {
            return filtersInitialState;
        }

        default:
            return state;
    }
}