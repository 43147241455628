import { reportsCellRenderer, newsCellRenderer } from '../core/helpers/CellRenderer/tableCellRenderers';
import {
    TRIAL_TABLE_COLUMNS,
    DEALS_TABLE_COLUMNS,
    PIPELINE_TABLE_COLUMNS,
    DISEASE_PIPELINE_TABLE_COLUMNS,
    REPORTS_TABLE_COLUMNS,
    NEWS_TABLE_COLUMNS,
    DRUG_FORECAST_TABLE_COLUMNS,
} from './configs/tableConfigs';

import { ENTITY_TYPES, SEARCH_TYPES, CHART_TYPES } from './common';
import {
    CompanyTrialsDeepDiveContent,
    CompanyForecastDeepDiveContent,
    DiseaseTrialsDeepDiveContent,
    DrugTrialsDeepDiveContent,
    CompanyDealsDeepDiveContent,
    DiseaseDealsDeepDiveContent,
    CompanyPipelinesDeepDiveContent,
    DiseasePipelinesDeepDiveContent,
    DrugDealsDeepDiveContent,
    DrugPipelineDeepDiveContent,
    DiseaseReportsDeepDiveContent,
    DrugReportsDeepDiveContent,
    CompanyReportDeepDiveContent,
    DiseaseEpiDeepDiveContent,
    DiseaseNewsDeepDiveContent,
    CompanyNewsDeepDiveContent,
    DrugNewsDeepDiveContent,
    DrugForecastDeepDiveContent,
    DiseaseForecastDeepDiveContent,
    DiseaseEventsAndCatalysts,
    DrugEventsAndCatalysts,
    CompanyEventsAndCatalysts
} from '../components/DeepDiveContent';
import {
    trialRowsMapper,
    dealRowsMapper,
    pipelineRowsMapper,
    reportsRowsMapper,
    defaultRowsMapper, eventsRowsMapper, catalystsRowsMapper,
} from '../core/helpers/CellRenderer/table';

export const NON_SUPPORTED_DEEP_DIVES_LEGEND = {
    [SEARCH_TYPES.COMPANY]: [ENTITY_TYPES.REPORTS],
    [SEARCH_TYPES.DISEASE]: [ENTITY_TYPES.REPORTS],
    [SEARCH_TYPES.DRUG]: [ENTITY_TYPES.REPORTS],
};

export const DEEP_DIVE_TITLES = {
    [ENTITY_TYPES.TRIAL]: 'Trials',
    [ENTITY_TYPES.DEALS]: 'Deals',
    [ENTITY_TYPES.PIPELINE]: 'Pipeline',
    [ENTITY_TYPES.EPIDEMIOLOGY]: 'Epidemiology',
    [ENTITY_TYPES.REPORTS]: 'Reports',
    [ENTITY_TYPES.NEWS]: 'News & Insights',
    [ENTITY_TYPES.FORECAST]: 'Sales Forecast',
    [ENTITY_TYPES.EVENTS_CATALYSTS]: 'Events & Catalysts'
};

export const SUPPORTED_DEEP_DIVES = {
    [SEARCH_TYPES.COMPANY]: [
        ENTITY_TYPES.TRIAL,
        ENTITY_TYPES.DEALS,
        ENTITY_TYPES.PIPELINE,
        ENTITY_TYPES.REPORTS,
        ENTITY_TYPES.NEWS,
        ENTITY_TYPES.FORECAST,
        ENTITY_TYPES.EVENTS_CATALYSTS
    ],
    [SEARCH_TYPES.DISEASE]: [
        ENTITY_TYPES.TRIAL,
        ENTITY_TYPES.DEALS,
        ENTITY_TYPES.PIPELINE,
        ENTITY_TYPES.EPIDEMIOLOGY,
        ENTITY_TYPES.REPORTS,
        ENTITY_TYPES.NEWS,
        ENTITY_TYPES.FORECAST,
        ENTITY_TYPES.EVENTS_CATALYSTS
    ],
    [SEARCH_TYPES.DRUG]: [
        ENTITY_TYPES.TRIAL,
        ENTITY_TYPES.DEALS,
        ENTITY_TYPES.PIPELINE,
        ENTITY_TYPES.REPORTS,
        ENTITY_TYPES.NEWS,
        ENTITY_TYPES.FORECAST,
        ENTITY_TYPES.EVENTS_CATALYSTS
    ]
};

export const DEEP_DIVE_CONTENT = {
    [SEARCH_TYPES.COMPANY]: {
        [ENTITY_TYPES.TRIAL]: CompanyTrialsDeepDiveContent,
        [ENTITY_TYPES.DEALS]: CompanyDealsDeepDiveContent,
        [ENTITY_TYPES.PIPELINE]: CompanyPipelinesDeepDiveContent,
        [ENTITY_TYPES.REPORTS]: CompanyReportDeepDiveContent,
        [ENTITY_TYPES.NEWS]: CompanyNewsDeepDiveContent,
        [ENTITY_TYPES.FORECAST]: CompanyForecastDeepDiveContent,
        [ENTITY_TYPES.EVENTS_CATALYSTS]: CompanyEventsAndCatalysts
    },
    [SEARCH_TYPES.DISEASE]: {
        [ENTITY_TYPES.TRIAL]: DiseaseTrialsDeepDiveContent,
        [ENTITY_TYPES.DEALS]: DiseaseDealsDeepDiveContent,
        [ENTITY_TYPES.PIPELINE]: DiseasePipelinesDeepDiveContent,
        [ENTITY_TYPES.EPIDEMIOLOGY]: DiseaseEpiDeepDiveContent,
        [ENTITY_TYPES.REPORTS]: DiseaseReportsDeepDiveContent,
        [ENTITY_TYPES.NEWS]: DiseaseNewsDeepDiveContent,
        [ENTITY_TYPES.FORECAST]: DiseaseForecastDeepDiveContent,
        [ENTITY_TYPES.EVENTS_CATALYSTS]: DiseaseEventsAndCatalysts
    },
    [SEARCH_TYPES.DRUG]: {
        [ENTITY_TYPES.TRIAL]: DrugTrialsDeepDiveContent,
        [ENTITY_TYPES.DEALS]: DrugDealsDeepDiveContent,
        [ENTITY_TYPES.PIPELINE]: DrugPipelineDeepDiveContent,
        [ENTITY_TYPES.REPORTS]: DrugReportsDeepDiveContent,
        [ENTITY_TYPES.NEWS]: DrugNewsDeepDiveContent,
        [ENTITY_TYPES.FORECAST]: DrugForecastDeepDiveContent,
        [ENTITY_TYPES.EVENTS_CATALYSTS]: DrugEventsAndCatalysts
    },
};

export const TABLE_DATA_CONFIG = {
    [CHART_TYPES.PATENT_EXPIRY_BY_REGION]: {
        disableHeader: false,
        rowHeight: 30,
        height: 150,
        headerHeight: 30,
        cellPadding: '3px 5px 3px 13px',
        columns: [
            {
                width: 50,
                label: 'Region',
                dataKey: 'region',
            },
            {
                width: 50,
                label: 'First Approval',
                dataKey: 'fa',
            },
            {
                width: 50,
                label: 'Expiry Date',
                dataKey: 'exp',
            },
        ],
        rowsMapper: defaultRowsMapper,
    },
    [CHART_TYPES.EVENTS_TABLE]: {
        disableHeader: false,
        rowHeight: 40,
        height: 150,
        headerHeight: 40,
        cellPadding: '0 5px 3px 13px',
        columns: [
            {
                width: 50,
                label: 'Event Date',
                dataKey: 'eventdate',
                type: 'date'
            },
            {
                width: 50,
                label: 'Change to LoA (%)',
                dataKey: 'overallchange'
            },
            {
                width: 50,
                label: 'Lead Company',
                dataKey: 'eventcompany'
            },
            {
                width: 50,
                label: 'Drug Name',
                dataKey: 'eventbrandname'
            },
            {
                width: 50,
                label: 'Indication',
                dataKey: 'subsubindicationname'
            },
            {
                width: 50,
                label: 'Type',
                dataKey: 'eventgroupname'
            },
            {
                width: 50,
                label: '',
                dataKey: 'link',
                disableSort: true,
                isLink: true,
                linkText: 'Profile',
            }
        ],
        rowsMapper: eventsRowsMapper
    },
    [CHART_TYPES.CATALYSTS_TABLE]: {
        disableHeader: false,
        rowHeight: 40,
        height: 150,
        headerHeight: 40,
        cellPadding: '0 5px 3px 13px',
        columns: [
            {
                width: 50,
                label: 'Expected Date Range',
                dataKey: 'expecteddaterangebegin',
                type: 'date'
            },
            {
                width: 50,
                label: 'Catalyst Impact',
                dataKey: 'catalystimpacttype'
            },
            {
                width: 50,
                label: 'Lead Company',
                dataKey: 'catalystcompany'
            },
            {
                width: 50,
                label: 'Drug Name',
                dataKey: 'catalystbrandname'
            },
            {
                width: 50,
                label: 'Indication',
                dataKey: 'catalystsubindicationname'
            },
            {
                width: 50,
                label: 'Type',
                dataKey: 'catalystgroupname'
            },
            {
                width: 50,
                label: '',
                dataKey: 'link',
                disableSort: true,
                isLink: true,
                linkText: 'Profile',
            }
        ],
        rowsMapper: catalystsRowsMapper
    }
};

export const DEEP_DIVE_PIVOT_TABLE_CONFIG = {
    [SEARCH_TYPES.DRUG]: {
        [ENTITY_TYPES.FORECAST]: {
            columns: ['year'],
            rows: ['company', 'region', 'product', 'event'],
            values: ['adjustment'],
            height: '350px',
        },
    },
    [SEARCH_TYPES.DISEASE]: {
        [ENTITY_TYPES.FORECAST]: {
            columns: ['year'],
            rows: ['drug', 'start', 'end', 'peak', 'CAGR', 'Growth'],
            values: ['revenue'],
            height: '350px',
        },
    },
};

export const PIVOT_TABLE_CONFIG = {};

export const NEWS_AND_REPORTS_TABLE_CONFIG = {
    [ENTITY_TYPES.NEWS]: {
        columns: NEWS_TABLE_COLUMNS,
        rowsMapper: reportsRowsMapper,
        disableHeader: true,
        rowHeight: 140,
        cellRenderer: newsCellRenderer,
    },
    [ENTITY_TYPES.REPORTS]: {
        columns: REPORTS_TABLE_COLUMNS,
        rowsMapper: reportsRowsMapper,
        disableHeader: true,
        rowHeight: 100,
        cellRenderer: reportsCellRenderer,
    }
}

export const DEEP_DIVE_TABLE_CONFIG = {
    [SEARCH_TYPES.COMPANY]: {
        [ENTITY_TYPES.TRIAL]: {
            columns: TRIAL_TABLE_COLUMNS,
            rowsMapper: trialRowsMapper,
        },
        [ENTITY_TYPES.DEALS]: {
            columns: DEALS_TABLE_COLUMNS,
            rowsMapper: dealRowsMapper,
        },
        [ENTITY_TYPES.PIPELINE]: {
            columns: PIPELINE_TABLE_COLUMNS,
            rowsMapper: pipelineRowsMapper,
        },
        [ENTITY_TYPES.REPORTS]: {
            columns: REPORTS_TABLE_COLUMNS,
            rowsMapper: reportsRowsMapper,
            disableHeader: true,
            rowHeight: 100,
            cellRenderer: reportsCellRenderer,
        },
        [ENTITY_TYPES.NEWS]: {
            columns: NEWS_TABLE_COLUMNS,
            rowsMapper: reportsRowsMapper,
            disableHeader: true,
            rowHeight: 140,
            cellRenderer: newsCellRenderer,
        },
    },
    [SEARCH_TYPES.DISEASE]: {
        [ENTITY_TYPES.TRIAL]: {
            columns: TRIAL_TABLE_COLUMNS,
            rowsMapper: trialRowsMapper,
        },
        [ENTITY_TYPES.DEALS]: {
            columns: DEALS_TABLE_COLUMNS,
            rowsMapper: dealRowsMapper,
        },
        [ENTITY_TYPES.PIPELINE]: {
            columns: DISEASE_PIPELINE_TABLE_COLUMNS,
            rowsMapper: pipelineRowsMapper,
        },
        [ENTITY_TYPES.REPORTS]: {
            columns: REPORTS_TABLE_COLUMNS,
            rowsMapper: reportsRowsMapper,
            disableHeader: true,
            rowHeight: 100,
            cellRenderer: reportsCellRenderer,
        },
        [ENTITY_TYPES.NEWS]: {
            columns: NEWS_TABLE_COLUMNS,
            rowsMapper: reportsRowsMapper,
            disableHeader: true,
            rowHeight: 140,
            cellRenderer: newsCellRenderer,
        },
    },
    [SEARCH_TYPES.DRUG]: {
        [ENTITY_TYPES.TRIAL]: {
            columns: TRIAL_TABLE_COLUMNS,
            rowsMapper: trialRowsMapper,
        },
        [ENTITY_TYPES.DEALS]: {
            columns: DEALS_TABLE_COLUMNS,
            rowsMapper: dealRowsMapper,
        },
        [ENTITY_TYPES.PIPELINE]: {
            columns: PIPELINE_TABLE_COLUMNS,
            rowsMapper: dealRowsMapper,
        },
        [ENTITY_TYPES.REPORTS]: {
            columns: REPORTS_TABLE_COLUMNS,
            rowsMapper: reportsRowsMapper,
            disableHeader: true,
            rowHeight: 100,
            cellRenderer: reportsCellRenderer,
        },
        [ENTITY_TYPES.NEWS]: {
            columns: NEWS_TABLE_COLUMNS,
            rowsMapper: reportsRowsMapper,
            disableHeader: true,
            rowHeight: 140,
            cellRenderer: newsCellRenderer,
        },
        [ENTITY_TYPES.FORECAST]: {
            columns: DRUG_FORECAST_TABLE_COLUMNS,
            rowsMapper: defaultRowsMapper,
        },
    },
};

export const DEEP_DIVE_REDUX_KEY = 'deepdive';

export const MULTIPLE_LEGENDS_LABELS = {
    [ENTITY_TYPES.EVENTS_CATALYSTS]: {
        events: 'Events',
        catalyst: 'Catalysts'
    }
};
