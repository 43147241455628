import React from 'react';
import styles from './ForbiddenPage.module.scss';
import { useLocation } from 'react-router-dom';
import BaseLayout from '../BaseLayout/BaseLayout';

export default () => {
    const location = useLocation();
    return (
        <BaseLayout location={location}>
            <div className={styles.content}>
                <h1>Thank you for your interest in Pharma360</h1>
                <p>
                    Pharma360 is the transformational solution that empowers you to quickly create a personal view of the
                    Pharma landscape, leveraging the industry’s most robust and trusted intelligence:
                </p>
                <p>
                    A single, targeted search across disease data, with <b>new drug and company search capabilities</b>,
                    surfaces content from our core portfolio delivering relevant information and eliminating unnecessary
                    data overload.
                </p>
                <p>
                    <b>Dynamic dashboards</b> provide a complete view of the Pharma market. Filter, visualize, and deep dive
                    into relevant data at the click of a button.
                </p>
                <p>
                    Build a personalized library of visualizations in <b>Project Folders</b>, creating truly unique
                    dashboards to drive key industry insights tailored to your business needs and objectives.
                </p>
                <p>
                    If your organization currently does not subscribe to Pharma360 and you’d like to learn more, please
                    visit{' '}
                    <a href="https://www.citeline.com/en/contact-us">
                        https://www.citeline.com/en/contact-us
                    </a>{' '}
                    and speak to a representative who can further assist you.
                </p>
                <p>
                    If your organization subscribes to Pharma360 and you need to activate your access, please contact{' '}
                    <a href='mailto:clientservices@citeline.com'>clientservices@citeline.com</a>
                </p>
            </div>
        </BaseLayout>
    );
};
