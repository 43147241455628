import React, { memo } from 'react';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import removeWhite from "../../../../images/remove-folder-white.svg";
import removeGrey from "../../../../images/remove-folder-grey.svg";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

export type ProjectFolderAction = 'add' | 'remove';

interface IFolderIcon {
    actionType?: ProjectFolderAction;
    removeIconVariant?: 'white' | undefined;
}

interface IAddRemoveProjectIconProps extends IFolderIcon {
    addActionText?: string;
    removeActionText?: string;
    onClick: () => void;
}

const ADD_TO_PROJECT = 'Add to project folder';
const REMOVE_FROM_PROJECT = 'Remove from project folder';

const FolderIcon: React.FunctionComponent<IFolderIcon> = (props) => {
    if (!props.actionType || props.actionType === 'add') {
        return (
            <CreateNewFolderOutlinedIcon
                fontSize='small'
                className='pointer'
                data-testid="add-icon"
            />
        );
    }

    return (
        <img
            data-testid="remove-icon"
            src={props.removeIconVariant === 'white' ? removeWhite : removeGrey}
            className='pointer'
            alt='Remove from project folder' />
    );
};

const AddRemoveProjectIcon: React.FunctionComponent<IAddRemoveProjectIconProps> = (props) => {
    const addActionText = props.addActionText || ADD_TO_PROJECT;
    const removeActionText = props.removeActionText || REMOVE_FROM_PROJECT;

    return (
        <Tooltip arrow disableFocusListener title={props.actionType === 'add' ? addActionText : removeActionText} placement="bottom">
            <span className="flex-center">
                <IconButton
                    size="small"
                    onClick={props.onClick}
                    color="inherit"
                >
                    <FolderIcon
                        removeIconVariant={props.removeIconVariant}
                        actionType={props.actionType} />
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default memo(AddRemoveProjectIcon);
