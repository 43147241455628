import React, { memo } from 'react';
import { PharmaSplitStackedBarChart } from '@informa/pharma-common-react-components';
import { ChartContainer } from '../ChartContainer';
import { ChartCommonProps, ChartRendererProps } from '../ChartContainer/ChartContainer';

const SplitStackedBarChartRenderer = ({ data, componentProps }: ChartRendererProps) => (
      <React.Fragment>
            {componentProps && <h4>{componentProps.label}</h4>}
            <PharmaSplitStackedBarChart
                  categories={data.categories}
                  series={data.series}
                  onClick={componentProps?.onClick}
                  onLabelClick={componentProps?.onLabelClick}
                  firstColumnTitle={componentProps?.firstColumnTitle || ''}
                  firstColumnWidth={componentProps?.firstColumnWidth}
                  secondColumnTitle={componentProps?.secondColumnTitle || ''}
                  secondColumnWidth={componentProps?.secondColumnWidth}
                  options={componentProps?.options}
            />
      </React.Fragment>
);

export default memo((props: ChartCommonProps) => (
      <ChartContainer {...props} component={SplitStackedBarChartRenderer} />
));
