import * as actionTypes from '../actions/actionTypes/search.actionTypes';

export interface SearchDataState {
    isLoading: boolean;
    error: any;
    options: any;
}

const searchInitialState: SearchDataState = {
    isLoading: false,
    error: undefined,
    options: null,
};

export default function searchReducer(state = searchInitialState, action: actionTypes.SearchAction): SearchDataState {
    switch (action.type) {
        case actionTypes.GET_SEARCH_DATA: {
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        }
        case actionTypes.GET_SEARCH_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: undefined,
                options: action.payload,
            };
        }
        case actionTypes.GET_SEARCH_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        }

        default:
            return state;
    }
}
