import React, { memo, useMemo } from 'react';
import { PharmaStackedBarChart } from '@informa/pharma-common-react-components';
import _isEmpty from 'lodash-es/isEmpty';
import _merge from 'lodash-es/merge';
import cx from 'classnames';
import { ChartContainer } from '../ChartContainer';
import { ChartCommonProps, ChartRendererProps } from '../ChartContainer/ChartContainer';
import { NO_DATA_AVAILABLE_MESSAGE } from '../../../constants/common';
import { formatCurrency } from '../../../core/helpers/number';

const StackedBarChartRenderer = ({ data, componentProps, isCurrency }: ChartRendererProps) => {
    const isDataAvailable = useMemo(() => data && !_isEmpty(data.series) && !_isEmpty(data.categories), [data]);
    let options = componentProps?.options || {};

    if (isCurrency) {
        options = _merge(options, {
            chartOptions: {
                tooltip: {
                    formatter: function () {
                        //@ts-ignore
                        return `<b>${this?.series?.name}</b><br/>${this?.point?.category?.name}: ${formatCurrency(
                            //@ts-ignore
                            this?.y
                        )}<br/>`;
                    },
                },
                yAxis: {
                    stackLabels: {
                        enabled: true,
                        format: null,
                        formatter: function () {
                            //@ts-ignore
                            return formatCurrency(this.total);
                        },
                    },
                },
            },
        });
    }

    return (
        <div className={cx({ 'text-center': !isDataAvailable })}>
            {componentProps && <h4>{componentProps.label}</h4>}
            {isDataAvailable ? (
                <PharmaStackedBarChart
                    categories={data.categories}
                    series={data.series}
                    onClick={componentProps?.onClick}
                    onLabelClick={componentProps?.onLabelClick}
                    options={options}
                />
            ) : (
                    <p>{NO_DATA_AVAILABLE_MESSAGE}</p>
                )}
        </div>
    );
};

export default memo((props: ChartCommonProps) => <ChartContainer {...props} component={StackedBarChartRenderer} />);
