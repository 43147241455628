import React, { FunctionComponent, memo, useEffect, useMemo } from 'react';
import { Search } from '@mui/icons-material';
import { Link, useRouteMatch } from 'react-router-dom';
import Box from '@mui/material/Box';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import HelpMenu from '../HelpMenu/HelpMenu';
import AtAMenu from '../AtAMenu/AtAMenu';
import styles from './HeaderNavigation.module.scss';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { MY_360_ROUTE, DEEP_DIVE_ROUTE } from '../../constants/routes';
import { EntityType } from '../../typings';
import { getSubscriptionsRequest } from '../../core/actions/subscriptions.actions';
import { useSelector, useDispatch } from 'react-redux';
import { getUserSubscriptions } from '../../core/selectors/subscriptions.selectors';
import { PROVIDERS } from '../../constants/common';
import { isAuthenticated  as serviceIsAuth } from '../../core/services/auth.service';
import { FeedbackMenu } from '../FeedbackMenu/FeedbackMenu';
import Tooltip from '@mui/material/Tooltip';
import { useAuth0 } from '@auth0/auth0-react';
import { onClearUpStorageAuthStrategy } from '../../hooks/useSSOAuthenticator';
import cn from 'classnames';

export interface IHeaderNavigationProps {
    showSearchBox: boolean;
    isUserSubscriber: boolean;
};

const isSSOAuth = process.env.REACT_APP_IS_OATH === 'true';

const HeaderNavigation: FunctionComponent<IHeaderNavigationProps> = (props) => {
    const dispatch = useDispatch();
    const match = useRouteMatch(DEEP_DIVE_ROUTE);
    const params = match?.params as any;
    const entityType = params?.tile as EntityType;
    const { logout, isAuthenticated } = useAuth0();

    const ssoLogOut = () => {
        onClearUpStorageAuthStrategy();
        logout()
    }

    const subscriptions = useSelector(getUserSubscriptions);
    
    const askTheAnalyticVisibility = useMemo(() => {
        const providersForEntity = PROVIDERS[entityType] || '';
        const providers: string[] = Array.isArray(providersForEntity) ? providersForEntity : [providersForEntity];
    
        const subs = (subscriptions || []).filter((x) => providers.includes(x.name));

        return isSSOAuth ? subs?.[0] : subs?.find((x) => x.isActive)
    }, [entityType, subscriptions])


    useEffect(() => {
        if ((serviceIsAuth() || isAuthenticated) && props.isUserSubscriber) {
            dispatch(getSubscriptionsRequest());
        }
    }, [dispatch, isAuthenticated, props.isUserSubscriber]);

    return (
        <ul role='navigation' className={styles.navigation} data-testid='header-nav'>
            {props.showSearchBox && (
                <li className={cn(styles.navSearchBox, {
                    [styles.area_disable]: !props.isUserSubscriber
                })} data-testid='nav-searchbox'>
                    <Link to='/'>
                        <Box display='flex' alignItems='center'>
                            <Search />
                            <span className={styles.searchText}>New search</span>
                        </Box>
                    </Link>
                </li>
            )}
            {props.isUserSubscriber && (
                <li data-testid='my360' className={styles.myp360}>
                    <Link to={MY_360_ROUTE}>
                        <Tooltip arrow disableFocusListener title="Project folders" placement="bottom">
                            <span>
                                <FolderOpenIcon />
                            </span>
                        </Tooltip>
                    </Link>
                </li>
            )}
            {askTheAnalyticVisibility && (
                <li data-testid='aTa' className={styles.navLeftBorder}>
                    <AtAMenu email={askTheAnalyticVisibility.analystEmail} />
                </li>
            )}
            <li className={styles.navLeftBorder} data-testid='help-url'>
                <HelpMenu />
            </li>
            {props.isUserSubscriber && (
                <li className={styles.navLeftBorder} data-testid='feedback-menu'>
                    <FeedbackMenu />
                </li>
            )}
            <li className={styles.navLeftBorder} data-testid='user-icon'>
                <HeaderMenu subscriptions={subscriptions} ssoLogOut={ssoLogOut} />
            </li>
        </ul>
    );
};

export default memo(HeaderNavigation);
