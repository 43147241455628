export const PINK = '#d1497C';

export const CORE_COLORS = {
	PRIMARY: PINK,
	GREY1: '#e5e5e5',
	GREY2: '#bfbfbf',
	GREY3: '#6a7285',
	GREY4: '#c7c7c7',
	GREY5: '#a6aab6',
	GREY6: '#444957',
	GREEN1: '#66b2bd',
	GREEN2: '#4be5c1',
	PURPLE1: '#6f4d9a',
	PURPLE2: '#825998',
	WHITE: '#fff',
	BLACK: '#000',
	ERROR: '#BD2624',
};

export const COUNTER_CHART_COLOR = CORE_COLORS.GREY3;