import React, { memo } from 'react';
import { PharmaGroupedBarChart } from '@informa/pharma-common-react-components';
import { ChartContainer } from '../ChartContainer';
import { ChartCommonProps, ChartRendererProps } from '../ChartContainer/ChartContainer';

const GroupedBarChartRenderer = ({ data, componentProps }: ChartRendererProps) => (
	<React.Fragment>
		{componentProps && <h4>{componentProps.label}</h4>}
		<PharmaGroupedBarChart
			categories={data.categories}
			data={data.data}
			onClick={componentProps?.onClick}
			onLabelClick={componentProps?.onLabelClick}
		/>
	</React.Fragment>
);

export default memo((props: ChartCommonProps) => (
	<ChartContainer
		{...props}
		component={GroupedBarChartRenderer}
	/>
));