import React, { useState, memo, useEffect, useCallback, useRef } from 'react';
import { InfiniteTable } from '@informa/pharma-common-react-components';
import { ChartCommonProps } from '../ChartContainer/ChartContainer';
import { loadTableData } from '../../../core/services/charts.service';
import { prepareFilters } from '../../../core/helpers/filter';
import ChartError from '../ChartError/ChartError';
import { DEEP_DIVE_TABLE_CONFIG, TABLE_DATA_CONFIG, NEWS_AND_REPORTS_TABLE_CONFIG } from '../../../constants/deepDiveConfig';
import { IOrderBy, ChartPath } from '../../../typings';
import { SortDirectionType } from 'react-virtualized';

interface ITableRendererProps extends Omit<ChartCommonProps, 'pathConfig'> {
    action?: React.ReactNode;
    pathConfig: Partial<ChartPath>;
}

const TableChartRenderer = memo(({ pathConfig, componentProps, action }: ITableRendererProps) => {
    const pageSize = 100;
    const [orderBy, setOrderBy] = useState<IOrderBy | undefined>(componentProps?.defaultOrderByColumn || undefined);
    const [page, setNextPage] = useState<number>(0);
    const [lastPage, setLastPage] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<any[]>([]);
    const [rowCount, setRowCount] = useState<number>(1);
    const [hasError, setHasError] = useState<boolean>(false);
    const mounted = useRef(false);

    const config = pathConfig as ChartPath;
    let tableConfig;

    if (!componentProps?.isProjectFolder) {
        tableConfig = TABLE_DATA_CONFIG[config.chart] ||
            (config.search && DEEP_DIVE_TABLE_CONFIG[config.search][config.entity]);
    } else {
        tableConfig = NEWS_AND_REPORTS_TABLE_CONFIG[config.entity];
    }

    const mapRows = tableConfig.rowsMapper;
    const columns = tableConfig.columns;

    const handleLoadMoreRows = useCallback(
        (pageNumber: number) => {
            setIsLoading(true);
            const preparedFilters = componentProps?.filters ? prepareFilters(componentProps.filters) : [];

            return loadTableData(pathConfig, preparedFilters, {
                page: pageNumber,
                pageSize,
                orderBy: !!orderBy ? [orderBy] : [],
            }, componentProps?.isProjectFolder)
                .then((data) => {
                    setHasError(false);
                    setIsLoading(false);
                    return data;
                })
                .catch((error) => {
                    setHasError(true);
                });
        },
        [componentProps, orderBy, pathConfig]
    );

    const getFirstPageData = useCallback(() => handleLoadMoreRows(1).then((newRows) => mapRows(newRows)), [
        handleLoadMoreRows,
        mapRows,
    ]);

    const resetTableState = useCallback((mappedRows: any[]) => {
        // reset the paging and counts
        setNextPage(1);
        setLastPage(0);

        const newRowsLength: number = (mappedRows || []).length;
        const newRowsCount: number = newRowsLength > 0 ? newRowsLength + 1 :
            newRowsLength;

        setRowCount(newRowsCount);
        setIsLoading(false);
    }, []);

    const getTableFirstPage = useCallback(() => {
        setRows([]);
        setRowCount(1);

        return getFirstPageData().then((mappedRows) => {
            setRows(mappedRows);
            resetTableState(mappedRows);
        });
    }, [getFirstPageData, resetTableState]);

    useEffect(() => {
        const getData = async () => {
            if (mounted.current && (orderBy || componentProps?.filters)) {
                await getTableFirstPage();
            }
        };

        getData();
    }, [orderBy, getTableFirstPage, mounted, componentProps]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    const loadMoreRows = async () => {
        if (mounted.current && !isLoading && lastPage <= page) {
            const nextPage = page + 1;
            const newRows = await handleLoadMoreRows(nextPage);

            const newRowsLength = newRows?.length || 0
            const totalRows = rows.length + newRowsLength;

            const newRowCount = newRowsLength > 0 ? totalRows + 1 :
                totalRows;

            if (newRows && newRows.length) {
                setRows(rows.concat(mapRows(newRows)));
            }

            setNextPage(nextPage);
            setRowCount(newRowCount);
        }
    };

    const sort = ({ sortBy, sortDirection }) => {
        if (sortBy && sortDirection) {
            const orderBy: IOrderBy = {
                fieldName: sortBy,
                direction: sortDirection.toLowerCase() as SortDirectionType,
            };
            setOrderBy(orderBy);
        }
    };

    const CellRenderer = tableConfig.cellRenderer;

    const getCellRenderer = (props) => {
        return <CellRenderer {...props} actionComponent={action} />
    }

    return (
        <div style={{ marginTop: 15, height: 350, width: '100%' }} data-testid={pathConfig?.entity}>
            <InfiniteTable
                disableHeader={tableConfig.disableHeader}
                headerHeight={tableConfig.headerHeight}
                rowHeight={tableConfig.rowHeight}
                cellRenderer={CellRenderer ? getCellRenderer : undefined}
                loadMoreRows={loadMoreRows}
                sort={sort}
                sortBy={orderBy?.fieldName}
                sortDirection={orderBy?.direction?.toUpperCase() as SortDirectionType || 'ASC'}
                columns={columns}
                rowCount={rowCount}
                rows={rows}
                pagesize={pageSize}
                isLoadingData={isLoading}
                hasError={hasError}
                errorMessageRenderer={<ChartError onClick={async (e) => await getTableFirstPage()} />}
            />
        </div>
    );
});

export default memo((props: ITableRendererProps) => {
    return <TableChartRenderer {...props} />;
});
