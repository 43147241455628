import { ENVIRONMENTS } from '../core/services/enviroment';

const DEVELOPMENT_CONFIGURATION = {
	ACCOUNT: 'informapharma360dev',
	TRACKING_SERVER: 'metrics.citeline.com',
	TRACKING_SERVER_SECURE: 'smetrics.citeline.com',
	VISITOR_NAMESPACE: 'pharmaintelligenceuklimited',
	VISITOR_INSTANCE_ID: '0E5928EB645AA63A0A495CA3@AdobeOrg'
};

const PRODUCTION_CONFIGURATION = {
	ACCOUNT: 'informapharma360prod',
	TRACKING_SERVER: 'metrics.citeline.com',
	TRACKING_SERVER_SECURE: 'smetrics.citeline.com',
	VISITOR_NAMESPACE: 'pharmaintelligenceuklimited',
	VISITOR_INSTANCE_ID: '0E5928EB645AA63A0A495CA3@AdobeOrg'
};

export const ADOBE_ANALYTICS_CONFIGURATION = {
	[ENVIRONMENTS.LOCAL]: DEVELOPMENT_CONFIGURATION,
	[ENVIRONMENTS.DEVELOPMENT]: DEVELOPMENT_CONFIGURATION,
	[ENVIRONMENTS.TEST]: DEVELOPMENT_CONFIGURATION,
	[ENVIRONMENTS.PRODUCTION]: PRODUCTION_CONFIGURATION
};

export const UNSPECIFIED = 'Unspecified';