import React, { useState, memo, useEffect, useCallback, useRef } from 'react';
import { InfiniteTable } from '@informa/pharma-common-react-components';
import { ChartCommonProps } from '../ChartContainer/ChartContainer';
import { loadChartData } from '../../../core/services/charts.service';
import { prepareFilters } from '../../../core/helpers/filter';
import ChartError from '../ChartError/ChartError';
import { TABLE_DATA_CONFIG } from '../../../constants/deepDiveConfig';

const TableChartRenderer = memo(({ pathConfig, componentProps }: ChartCommonProps) => {
    const pageSize = 100;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [page, setNextPage] = useState<number>(0);
    const [lastPage, setLastPage] = useState<number>(0);
    const [rows, setRows] = useState<any[]>([]);
    const [rowCount, setRowCount] = useState<number>(1);
    const [hasError, setHasError] = useState<boolean>(false);
    const mounted = useRef(false);

    const tableConfig: any = TABLE_DATA_CONFIG[pathConfig.chart];
    const mapRows = tableConfig.rowsMapper;
    const columns = tableConfig.columns;

    const handleLoadMoreRows = useCallback(
        (pageNumber: number) => {
            setIsLoading(true);
            const preparedFilters = componentProps?.filters ? prepareFilters(componentProps.filters) : [];

            return loadChartData(pathConfig, preparedFilters, {
                page: pageNumber,
                pageSize,
            })
                .then((data) => {
                    setHasError(false);
                    setIsLoading(false);
                    return data;
                })
                .catch((error) => {
                    setHasError(true);
                });
        },
        [componentProps, pathConfig]
    );

    const getFirstPageData = useCallback(() => handleLoadMoreRows(1).then((newRows) => mapRows(newRows)), [
        handleLoadMoreRows,
        mapRows,
    ]);

    const resetTableState = useCallback((mappedRows: any[]) => {
        // reset the paging and counts
        setNextPage(1);
        setLastPage(0);

        const newRowsLength: number = (mappedRows || []).length;
        // in case the first page contains less data than the page size
        // its to hide the Loading message, as there are no more pages
        const newRowsCount: number = newRowsLength === pageSize ? newRowsLength + 1 : newRowsLength;

        setRowCount(newRowsCount);
        setIsLoading(false);
    }, []);

    const getTableFirstPage = useCallback(() => {
        setRows([]);
        setRowCount(1);

        return getFirstPageData().then((mappedRows) => {
            setRows(mappedRows);
            resetTableState(mappedRows);
        });
    }, [getFirstPageData, resetTableState]);

    useEffect(() => {
        const getData = async () => {
            if (mounted.current && componentProps?.filters) {
                await getTableFirstPage();
            }
        };

        getData();
    }, [getTableFirstPage, mounted, componentProps]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    const loadMoreRows = async () => {
        if (mounted.current && !isLoading && lastPage <= page) {
            const nextPage = page + 1;
            const newRows = await handleLoadMoreRows(nextPage);
            let newRowCount = rows.length + (newRows?.length || 0) + 1;
            if (newRows && newRows.length) {
                setRows(rows.concat(mapRows(newRows)));
            }
            if ((newRows?.length || 0) < pageSize) {
                newRowCount = newRowCount - 1;
            }
            setNextPage(nextPage);
            setRowCount(newRowCount);
        }
    };

    return (
        <div style={{ marginTop: -10, height: tableConfig.height, width: '100%' }}>
            <InfiniteTable
                disableHeader={tableConfig.disableHeader}
                headerHeight={tableConfig.headerHeight}
                rowHeight={tableConfig.rowHeight}
                cellPadding={tableConfig.cellPadding}
                cellRenderer={tableConfig.cellRenderer}
                loadMoreRows={loadMoreRows}
                columns={columns}
                rowCount={rowCount}
                rows={rows}
                pagesize={pageSize}
                isLoadingData={isLoading}
                hasError={hasError}
                errorMessageRenderer={<ChartError onClick={async (e) => await getTableFirstPage()} />}
            />
        </div>
    );
});

export default memo((props: ChartCommonProps) => {
    return <TableChartRenderer {...props} />;
});
