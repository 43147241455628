import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Variant } from '@mui/material/styles/createTypography';
import { wrapIf } from '../../decorators';
import { ComponentClassName } from '../../typings';

import styles from './Panel.module.scss';

export type HeightVariant = 'small' | 'medium' | 'large' | 'auto' | 'nodata' | 'collapsed';

export interface IPanelProps {
      title: string;
      titleCaption?: string;
      titleVariant?: Variant;
      renderHeaderActions?: React.ReactNode;
      children?: React.ReactNode;
      heightVariant?: HeightVariant;
      headerClasses?: ComponentClassName;
      bodyClasses?: ComponentClassName;
      wrapperClasses?: ComponentClassName;
      titleClasses?: ComponentClassName;
      titleCaptionClassNames?: ComponentClassName;
      disableElevation?: boolean;
      disableHeight?: boolean;
      disablePaddingBottom?: boolean;
      filtersApplied?: boolean;
}

const Panel: FunctionComponent<IPanelProps> = (props) => {
      const heightClass = props.heightVariant ? styles[props.heightVariant] : styles.medium;
      const titleVariant = props.titleVariant || 'h5';
      const headerClassNames = cn(props.headerClasses, styles.panelHeader, 'flex-center-space-between');
      const bodyClassNames = cn(props.bodyClasses, styles.panelBody);
      const wrapperClassNames = cn(
            props.wrapperClasses,
            styles.panel,
            { [heightClass]: !props.disableHeight },
            { [styles.noPaddingBottom]: !!props.disablePaddingBottom },
      );
      const titleClasses = cn(props.titleClasses, 'ellipsis', 'capitalize');
      const titleCaptionClasses = cn(props.titleCaptionClassNames, styles.panelTitleCaption);
      const titleCaption = props.titleCaption || null;
      const disableElevation = props.disableElevation;

      return (
            <Paper
                  variant='elevation'
                  elevation={disableElevation ? 0 : 3}
                  className={wrapperClassNames}
                  data-testid='panel'
            >
                  <div data-testid='panel-header' className={headerClassNames}>
                        <div className={styles.headingWrapper}>
                              {wrapIf(
                                    !!titleCaption,
                                    <React.Fragment>
                                          <Typography
                                                variant={titleVariant}
                                                data-testid='title'
                                                className={titleClasses}
                                          >
                                                {props.title}
                                          </Typography>
                                          {titleCaption && <span className={titleCaptionClasses}>{titleCaption}</span>}
                                    </React.Fragment>,
                                    {
                                          className: styles.panelTitleContainer,
                                    },
                              )}
                              {props.filtersApplied && (
                                    <span className={styles.headingWrapperFilterIcon} >
                                          <Tooltip arrow disableFocusListener title='Filtered data' placement='bottom'>
                                                <InfoOutlinedIcon color='primary' />
                                          </Tooltip>
                                    </span>
                              )}
                        </div>

                        {!!props.renderHeaderActions && props.renderHeaderActions}
                  </div>
                  {!!props.children && (
                        <div data-testid='panel-body' className={bodyClassNames}>
                              {props.children}
                        </div>
                  )}
            </Paper>
      );
};

export default Panel;
