import React from 'react';
import { TableCellRenderer, TableCellProps } from 'react-virtualized';
import { formatter } from '@informa/pharma-common-react-components';
import { adobeAnalytics } from '../../services/analytics/adobe';
import { getNewsAndInsightsProductName, getReportsProductName } from '../analytics';

import styles from './tableCellRenderers.module.scss';

const createTrackLinkClickHandler = (productNameGetter, { title, link }) => () => adobeAnalytics.trackReportLinkClick({
    title,
    productName: productNameGetter(link),
    destination: link
});

interface ICellRendererProps extends TableCellProps {
    actionComponent?: any;
}

export const reportsCellRenderer: TableCellRenderer = (props: ICellRendererProps) => {
    const ActionComponent = props.actionComponent;

    const { rowData } = props;

    return (
        <div className={styles.dataTop}>
            <a
                onClick={createTrackLinkClickHandler(getReportsProductName, rowData)}
                href={rowData.link}
                target='_blank'
                rel='noopener noreferrer'
                className={styles.link}
            >
                <div className={styles.title}>{rowData.title}</div>

                <div className={styles.data}>
                    <span>{formatter('date', 'MMMM DD, YYYY', rowData.date)}</span>
                </div>
                <div className={styles.content}>
                    <span>{rowData.summary}{rowData.summary.length >= 250 && '...'}</span>
                </div>
            </a>
            {!!props.actionComponent && <div className={styles.actionCol}>
                <ActionComponent rowData={rowData} />
            </div>}
        </div>
    )
}

export const newsCellRenderer: TableCellRenderer = (props: ICellRendererProps) => {
    const ActionComponent = props.actionComponent;

    const { rowData } = props;

    return (
        <div className={styles.dataTop}>
            <a
                onClick={createTrackLinkClickHandler(getNewsAndInsightsProductName, {
                    ...rowData,
                    title: rowData.articletitle
                })}
                href={rowData.link}
                target='_blank'
                rel='noopener noreferrer'
                className={styles.link}
            >
                <div>
                    <span className={styles.date}>{formatter('date', 'DD MMM YYYY', rowData.articledate)} |</span>
                    <span className={styles.articletype}>{rowData.articletype}</span>
                </div>
                <div className={styles.product}>
                    <span>{rowData.product}</span>
                </div>

                <div className={styles.title}>
                    <span> {rowData.articletitle} </span>
                </div>
                <div className={styles.content}>
                    <span>{rowData.articlecontentshort}{rowData.articlecontentshort.length >= 250 && '...'}</span>
                </div>
            </a>
            {!!props.actionComponent && <div className={styles.actionCol}>
                <ActionComponent rowData={rowData} />
            </div>}
        </div>
    );
};
