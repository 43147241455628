import * as actions from './actionTypes/subscriptions.actionTypes';

export function getSubscriptionsRequest(): actions.IGetSubscriptions {
    return {
        type: actions.GET_SUBSCRIPTIONS,
    };
}

export function getSubscriptionsSuccess(subscriptions: Array<any>): actions.IGetSubscriptionsSuccess {
    return {
        type: actions.GET_SUBSCRIPTIONS_SUCCESS,
        subscriptions
    };
}

export function getSubscriptionsError(error: Error | string): actions.IGetSubscriptionsError {
    return {
        type: actions.GET_SUBSCRIPTIONS_ERROR,
        error
    };
}
