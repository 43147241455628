import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import styles from './ProgressBar.module.scss';

export interface IProgressBarProps {
    message?: string;
}

export default class ProgressBar extends React.Component<IProgressBarProps> {
    public render() {
        return (
            <div className={styles.initialise} data-testid="progress-bar">
                {this.props.message &&
                    <div className={styles.message} data-testid="progress-bar-message">{this.props.message}</div>
                }
                <LinearProgress color="primary" />
            </div>
        );
    }
}
