import React, { FunctionComponent, memo } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { PharmaCounterChart, COUNTER_VARIANTS_MAP } from '@informa/pharma-common-react-components';
import { COUNTER_CHART_COLOR } from '../../../constants/colors';
import { ChartContainer } from '../ChartContainer';
import { ChartCommonProps, ChartRendererProps } from '../ChartContainer/ChartContainer';
import { getCurrentYear } from '../../../core/helpers/dates';

const regionRevenueCommonProps = {
    variant: COUNTER_VARIANTS_MAP.MEDIUM,
    labelColor: COUNTER_CHART_COLOR,
    valueColor: COUNTER_CHART_COLOR
};

const RevenueDistributionChartRenderer: FunctionComponent<ChartRendererProps> = ({ data }) => {

    if (!(data && data.length > 0)) {
        return null;
    }

    return (
        <Grid
            container
            direction='column'
            sx={{justifyContent: 'space-around'}}
            data-testid='forecast-summary-chart'>
            <Box display='flex' alignItems='center' justifyContent='center'>
                <span>Revenue by Region, {getCurrentYear() - 1}</span>
            </Box>

            {data.map(({ label, percentage }, idx) => (
                <Grid item key={idx}>
                    <PharmaCounterChart
                        label={label}
                        value={percentage}
                        afterValue='%'
                        {...regionRevenueCommonProps}
                    />
                </Grid>
            ))
            }
        </Grid>
    );
};

export default memo((props: ChartCommonProps) => (
    <ChartContainer
        {...props}
        component={RevenueDistributionChartRenderer}
    />
));
