import * as actionTypes from '../actions/actionTypes/entity.actionTypes';
import { EntityAction } from '../actions/actionTypes/entity.actionTypes';
import { buildEntityPath } from '../helpers/common';
import { DASHBOARD_SECTION_TYPES } from '../../constants/common';

type EntityData =
    | {
          [key: string]: any;
      }
    | string
    | null;

export interface EntityEntry {
    isLoading: boolean;
    error: string | null;
    data: EntityData;
}

/**
 * entityPath should be a string constructed as follows:
 * `${search of SearchType}/${entity of EntityType}/${chart of ChartType}`.
 * It allows as to index every loaded chart at O(1) if it exists
 */
type EntitiesCollection = {
    [entityPath: string]: EntityEntry;
} | null;

export interface EntitiesState {
    data: EntitiesCollection;
    searchTerm: string;
}

const entitiesInitialState: EntitiesState = {
    data: null,
    searchTerm: '',
};

export default function entitiesReducer(state = entitiesInitialState, action: EntityAction): EntitiesState {
    switch (action.type) {
        case actionTypes.DATA_REQUEST: {
            const entityPath = buildEntityPath(action.payload);

            return {
                ...state,
                data: {
                    ...state.data,
                    [entityPath]: {
                        isLoading: true,
                        data: null,
                        error: null,
                    },
                },
            };
        }

        case actionTypes.DATA_READY: {
            const entityPath = buildEntityPath(action.payload);
            let searchTerm = state && state.searchTerm;

            if (action.payload.sectionType === DASHBOARD_SECTION_TYPES.NAME) {
                searchTerm = action.payload.data;
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    [entityPath]: {
                        isLoading: false,
                        error: null,
                        data: action.payload.data,
                    },
                },
                searchTerm,
            };
        }

        case actionTypes.DATA_ERROR: {
            const entityPath = buildEntityPath(action.payload);

            return {
                ...state,
                data: {
                    ...state.data,
                    [entityPath]: {
                        isLoading: false,
                        error: action.payload.error,
                        data: null,
                    },
                },
            };
        }

        default:
            return state;
    }
}
