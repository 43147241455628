import React, { useMemo, memo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CounterChart } from '../../Charts/CounterChart';
import { StackedBarChart } from '../../Charts/StackedBarChart';
import { TableChart } from '../../Charts/TableChart';
import { ChartsBatchRequestPath } from '../../../core/actions/actionTypes/charts.actionsTypes';
import { DEEP_DIVE_REDUX_KEY } from '../../../constants/deepDiveConfig';
import { CHART_TYPES } from '../../../constants/common';
import DeepDivePanel from '../Shared/DeepDivePanel/DeepDivePanel';
import { createChartClickHandlerFactory, createChartLabelClickHandlerFactory } from '../../../core/helpers/filter';
import { DeepDiveContentProps } from '../typings';
import { DoughnutChart } from '../../Charts/DoughnutChart';
import styles from '../DeepDiveContent.module.scss';
import { StackedColumnChart } from '../../Charts/StackedColumnChart';
import { NewsTimelineFormatter } from '../Shared/Common/ChartMonthLabelFormatter';
import AddRemoveProjectIcon from '../Shared/AddRemoveProjectIcon/AddRemoveProjectIcon';

const chartPaperProps = {
    className: styles.deepDiveChartsContainerItem,
    elevation: 0,
};

export default memo((props: DeepDiveContentProps) => {
    const chartPathProps = useMemo<ChartsBatchRequestPath>(
        () => ({
            ...props,
            subPath: DEEP_DIVE_REDUX_KEY,
        }),
        [props]
    );
    const createChartClickHandler = createChartClickHandlerFactory(props.onClick);
    const createChartLabelClickHandler = createChartLabelClickHandlerFactory(props.onClick);
    const Action = (rowProps) => <AddRemoveProjectIcon actionType="add" onClick={() => props.onOpenDialog(undefined, rowProps.rowData.id)} />

    return (
        <Grid container spacing={3}>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.COUNT,
                        }}
                        componentProps={{
                            label: 'Articles in Last Year',
                            inverted: false,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Article Content Type'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => props.onOpenDialog(CHART_TYPES.CONTENT_TYPE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.CONTENT_TYPE,
                    }}
                    filters={props.filters}>
                    <StackedBarChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.CONTENT_TYPE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.CONTENT_TYPE),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.CONTENT_TYPE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Article Source'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => props.onOpenDialog(CHART_TYPES.ARTICAL_SOURCE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.ARTICAL_SOURCE,
                    }}
                    filters={props.filters}>
                    <DoughnutChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.ARTICAL_SOURCE,
                        }}
                        componentProps={{
                            showLabels: true,
                            onClick: createChartClickHandler(CHART_TYPES.ARTICAL_SOURCE),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.ARTICAL_SOURCE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Article Publish Timeline'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => props.onOpenDialog(CHART_TYPES.ARTICAL_BY_TIME)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.ARTICAL_BY_TIME,
                    }}
                    filters={props.filters}>
                    <StackedColumnChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.ARTICAL_BY_TIME,
                        }}
                        componentProps={{
                            showLabels: true,
                            onClick: createChartClickHandler(CHART_TYPES.ARTICAL_BY_TIME),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.ARTICAL_BY_TIME),
                            options: NewsTimelineFormatter,
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>

            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Articles'
                    disableElevation
                    isDownloadAvailable
                    isImageDisabled
                    onOpenDialog={() => props.onOpenDialog(CHART_TYPES.TABLE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TABLE,
                    }}
                    filters={props.filters}>
                    <TableChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TABLE,
                        }}
                        componentProps={{
                            filters: props.filters,
                        }}
                        action={Action}
                    />
                </DeepDivePanel>
            </Grid>
        </Grid>
    );
});
