export const ENVIRONMENTS = {
    LOCAL: 'local',
    DEVELOPMENT: 'dev',
    PRODUCTION: 'prod',
    TEST: 'test'
};

/** The environment, can be 'dev' | 'test' | 'prod' */
export function environment() {
    if (!window || !window.location) return ENVIRONMENTS.DEVELOPMENT;
    const hostname = window.location.hostname;
    if (!hostname || hostname === 'localhost') return ENVIRONMENTS.LOCAL;
    if (hostname.indexOf('.dev.') > 0) return ENVIRONMENTS.DEVELOPMENT;
    if (hostname.indexOf('.test.') > 0) return ENVIRONMENTS.TEST;
    return ENVIRONMENTS.PRODUCTION;
}

/** The API to connect to. Can be 'local' | 'dev' | 'test' | 'prod' */
export function apiToUse() {
    const apiToUse = process.env.REACT_APP_API;

    if (apiToUse === ENVIRONMENTS.PRODUCTION || apiToUse === ENVIRONMENTS.TEST || apiToUse === ENVIRONMENTS.DEVELOPMENT || apiToUse === ENVIRONMENTS.LOCAL)
        return apiToUse;

    return environment();
}


export function getStage(currentStage) {
    if (currentStage === ENVIRONMENTS.PRODUCTION || currentStage === ENVIRONMENTS.TEST || currentStage === ENVIRONMENTS.DEVELOPMENT || currentStage === ENVIRONMENTS.LOCAL)
        return currentStage;

    return apiToUse();
}

/** The identity override to connect to. Can be 'local' | 'fake */
export function identityToUse() {
    const identityToUse = process.env.REACT_APP_IDENTITY_STAGE as string;

    if (identityToUse === ENVIRONMENTS.LOCAL)
        return identityToUse;

    const apiToUseOption = apiToUse();

    if (apiToUseOption === ENVIRONMENTS.LOCAL)
        return identityToUse;

    return apiToUseOption;
}