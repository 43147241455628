import React, { Suspense } from 'react';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import AppRouting from './AppRouting';
import RedirectToLogin from './components/AuthComponents/RedirectToLogin/RedirectToLogin';
import LoginCallback from './components/AuthComponents/LoginCallback/LoginCallback';
import Highcharts from 'highcharts';
import { LOG_OUT_CALLBACK_ROUTE, PROFILE_CALLBACK_ROUTE } from './constants/routes';
import LogOutCallback from './components/AuthComponents/Logout/LogoutCallback';
import ProfileCallback from './components/AuthComponents/ProfileCallback/ProfileCallback';


// Workaround to fix 'Highcharts is not defined' floating error
window.Highcharts = Highcharts;

class AppContainer extends React.PureComponent<RouteComponentProps> {
    public render() {
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <Route exact path="/login" component={RedirectToLogin} />
                    <Route exact path="/login/callback" component={LoginCallback} />
                    <Route exact path={PROFILE_CALLBACK_ROUTE} component={ProfileCallback}/>
                    <Route path={LOG_OUT_CALLBACK_ROUTE} component={LogOutCallback}/>
                    <Route component={AppRouting} />
                </Switch>
            </Suspense>
        );
    }
}

export default withRouter(AppContainer);
