import * as actions from '../actions/actionTypes/notifications.actionTypes';

export interface INotificationsState {
    message?: string
}

const initialState: INotificationsState = {
    message: undefined
};

export default function notifications(state: INotificationsState = initialState, action: actions.NotificationAction): INotificationsState {
    switch (action.type) {
        case actions.SET_NOTIFICATION:
            return {
                ...state,
                message: action.message
            };
        default:
            return state;
    }
}