import { EntityUrlParams, EntityUrlWithFiltersParams } from '../../typings';
import * as actions from './actionTypes/entityUrl.actionTypes';

export function getEntityUrlRequest(data: EntityUrlParams): actions.GetEntityUrlAction {
    return {
        type: actions.GET_ENTITY_URL_DATA,
        payload: data,
    };
}

export function getEntityUrlSuccess(path: EntityUrlParams, url: string): actions.GetEntityUrlSuccessAction {
    return {
        type: actions.GET_ENTITY_URL_SUCCESS,
        payload: {
            ...path,
            url,
        },
    };
}

export function getEntityUrlError(path: EntityUrlParams, error: string): actions.GetEntityUrlErrorAction {
    return {
        type: actions.GET_ENTITY_URL_ERROR,
        payload: {
            ...path,
            error,
        },
    };
}

export function getEntityUrlWithFiltersRequest(payload: EntityUrlWithFiltersParams): actions.GetEntityUrlWithFiltersAction {
    return {
        type: actions.GET_ENTITY_URL_WITH_FILTERS_DATA,
        payload
    }
}

export function getEntityUrlWithFiltersSuccess(path: EntityUrlWithFiltersParams, url: string): actions.GetEntityUrlWithFiltersSuccessAction {
    return {
        type: actions.GET_ENTITY_URL_WITH_FILTERS_SUCESS,
        payload: {
            ...path,
            url,
        },
    };
}

export function getEntityUrlWithFiltersWithFiltersError(path: EntityUrlWithFiltersParams, error: string): actions.GetEntityUrlWithFiltersErrorAction {
    return {
        type: actions.GET_ENTITY_URL_WITH_FILTERS_ERROR,
        payload: {
            ...path,
            error,
        },
    };
}
