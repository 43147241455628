export enum PROGRESS_STATUS {
    IN_PROGRESS = 'In Progress',
    COMPLETED = 'Completed',
    FAILED = 'Failed',
};

export interface DownloadStatusResponse {
    status: PROGRESS_STATUS;
    progress: number;
    url?: string; 
}