import format from 'date-fns/format';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import _isString from 'lodash-es/isString';

import { APP_DATE_FORMAT } from '../../constants/common';

export const formatAppDate = (date: Date | string | null, formatTemplate: string = APP_DATE_FORMAT) => {
    if (!date) {
        return 'Invalid date';
    }

    const dateToFormat = _isString(date) ? new Date(date) : date;

    return format(dateToFormat, formatTemplate);
};

export const parseDateRange = (dateString: string) => parse(
    dateString,
    APP_DATE_FORMAT,
    new Date()
);

export const parseISODate = (dateString: string) => {
    return parseISO(dateString);
};

export const getCurrentYear = () => new Date().getFullYear();