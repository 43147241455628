import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated, userHasAccessToPharma360 } from '../../../core/services/auth.service';
import ForbiddenPage from '../../ForbiddenPage/ForbiddenPage';
import GuardComponent from './GuardOAuthComponent';

export const PrivateRoute = ({ component, ...rest }) => {
    const renderFn = (Component) => (props) => {
        const isAuthDisabled = process.env.REACT_APP_DISABLE_AUTH === 'true';
        
        if(process.env.REACT_APP_IS_OATH === 'true') {
            return <GuardComponent component={component} props={props} />
        }

        if (isAuthDisabled) {
            return <Component {...props} />;
        }

        const userIsAuthenticated = isAuthenticated();
        if (!!Component && userIsAuthenticated) {
            if (userHasAccessToPharma360()) {
                return <Component {...props} />;
            } else {
                return <ForbiddenPage {...props} />;
            }
        } else {
            localStorage.setItem('redirectUrl', window.location.href);
            return <Redirect to='/login' />;
        }
    };

    return <Route {...rest} render={renderFn(component)} />;
};

export default PrivateRoute;
