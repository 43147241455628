import { AppAction } from '../../store/typings';
import { RawFilterValues, EntityType, SearchType } from '../../../typings';
export const GET_FILTERS = 'FILTERS/GET';
export const GET_FILTERS_SUCCESS = 'FILTERS/GET_SUCCESS';
export const GET_FILTERS_ERROR = 'FILTERS/GET_ERROR';
export const SET_FILTERS = 'FILTERS/SET';
export const RESET_FILTER_STATE = 'FILTERS/RESET_STATE';

export interface IGetFiltersSuccess {
    filtersOptions: RawFilterValues;
}
export interface IGetFiltersError {
    error: string | null;
}

export interface ISetFilters {
    filters: RawFilterValues;
}

export interface IGetFilters {
    entity: EntityType;
    search: SearchType;
}

export type SetFiltersAction = AppAction<typeof SET_FILTERS, ISetFilters>;
export type GetFiltersAction = AppAction<typeof GET_FILTERS, IGetFilters>;
export type GetFiltersSuccessAction = AppAction<typeof GET_FILTERS_SUCCESS, IGetFiltersSuccess>;
export type GetFiltersErrorAction = AppAction<typeof GET_FILTERS_ERROR, IGetFiltersError>;
export type ResetFilterStateAction = AppAction<typeof RESET_FILTER_STATE, any>;

export type FiltersAction =
    | SetFiltersAction
    | GetFiltersAction
    | GetFiltersSuccessAction
    | GetFiltersErrorAction
    | ResetFilterStateAction;
