import React, { memo } from 'react';
import { DatamonitorData } from '../../../typings';
import styles from '../../DeepDiveContent/DeepDiveContent.module.scss';

const LinkChart = (props: DatamonitorData) => {
    return (
        <div className={styles.dataMonitorDataLink}>
            <a href={props.url} target='_blank'>
                <span>
                    {props.type} Overview - {props.label}
                </span>
            </a>
        </div>
    );
};

export default memo(LinkChart);
