import { formatAppDate } from './dates';
import { getCounterValueFromDependencies } from './filter';
import { CHART_TYPES } from '../../constants/common';
import _isNumber from 'lodash-es/isNumber';

export const nearestCatalystDateValueBuilder = ({ data }) => {
    return data?.c ? formatAppDate(data?.c, 'MMM dd, yyyy') : 'N/A';
};

export const averageEventsPerDrugValueBuilder = ({ dependenciesData }) => {
    const totalEventsCount = getCounterValueFromDependencies(
        dependenciesData,
        CHART_TYPES.TOTAL_EVENTS_COUNT_DEEP_DIVE
    );
    const totalDrugsWithEventsCount = getCounterValueFromDependencies(
        dependenciesData,
        CHART_TYPES.TOTAL_DRUGS_WITH_EVENTS
    );

    if (_isNumber(totalDrugsWithEventsCount) && totalDrugsWithEventsCount !== 0 && _isNumber(totalEventsCount)) {
        return (totalEventsCount / totalDrugsWithEventsCount).toFixed(2);
    }

    return null;
};

export const averageEventsPerIndicationValueBuilder = ({ dependenciesData }) => {
    const totalEventsCount = getCounterValueFromDependencies(
        dependenciesData,
        CHART_TYPES.TOTAL_EVENTS_COUNT_DEEP_DIVE
    );
    const totalIndicationsCount = getCounterValueFromDependencies(
        dependenciesData,
        CHART_TYPES.INDICATIONS_OVER_PAST_5_YEARS
    );

    if (_isNumber(totalIndicationsCount) && totalIndicationsCount !== 0 && _isNumber(totalEventsCount)) {
        return (totalEventsCount / totalIndicationsCount).toFixed(2);
    }

    return null;
};
