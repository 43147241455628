import React, { FunctionComponent, memo } from 'react';
import Grid from '@mui/material/Grid';
import { DoughnutChart } from '../Charts/DoughnutChart';
import { DrugForecastSummaryChart } from '../Charts/DrugForecastSummaryChart';
import { LineChart } from '../Charts/LineChart';
import TileContentWrapper from './TileContentWrapper/TileContentWrapper';
import { CHART_TYPES } from '../../constants/common';
import { TileContentProps } from './typings';
import { EntityType } from '../../typings';
import { getCurrentYear } from '../../core/helpers/dates';

const DrugForecast: FunctionComponent<TileContentProps> = (props) => {
    const pathConfig = {
        ...props,
        entity: 'forecast' as EntityType,
    };

    return (
        <TileContentWrapper testId='epi-tile-content'>
            <Grid item xs={4}>
                <DoughnutChart
                    pathConfig={{
                        ...pathConfig,
                        chart: CHART_TYPES.DRUG_FORECAST_DOUGHNUT,
                    }}
                    componentProps={{
                        dataLabelFormat: '{point.name}<br> {point.y}B<br>({point.percentage:.2f}%)',
                        label: `Revenue by Region, ${getCurrentYear() - 1}`,
                    }}
                    isCurrency
                />
            </Grid>

            <Grid item xs={3}>
                <DrugForecastSummaryChart {...pathConfig} />
            </Grid>

            <Grid item xs={5}>
                <LineChart
                    pathConfig={{
                        ...pathConfig,
                        chart: CHART_TYPES.REVENUE_LINE_CHART,
                    }}
                />
            </Grid>
        </TileContentWrapper>
    );
};

export default memo(DrugForecast);
