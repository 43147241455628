import { Component, FunctionComponent, MemoExoticComponent } from 'react';
import { SortDirectionType } from 'react-virtualized';
import { Argument as ClassNameValueType } from 'classnames';
import { GridSize } from '@mui/material';

export enum FEATURE {
    DOWNLOADS = 'Downloads',
    DRUG_SUMMARY = 'Drug Summary',
    DEALS = 'Deals',
    EPIDEMIOLOGY = 'Epidemiology',
    FORECAST = 'Forecast',
    INSIGHTS = 'Insights',
    EVENTS_AND_CATALYSTS = 'Events & Catalysts',
    PIPELINE = 'Pipeline',
    REPORTS = 'Reports',
    TRIALS = 'Trials'
}

// Chart types
export type SearchType = 'disease' | 'drug' | 'company';
export type EntityType = 'trial' | 'pipeline' | 'deals' | 'epi' | 'report' | 'news' | 'forecast' | 'eventcatalyst';

export type ChartType =
    | 'topproducts'
    | 'countdeepdive'
    | 'count'
    | 'countforecast'
    | 'phase'
    | 'completedcount'
    | 'ongoingcount'
    | 'plannedcount'
    | 'terminatedcount'
    | 'topdrugs'
    | 'drughighestdiseasestatus'
    | 'drughigheststatusreached'
    | 'totalvalue'
    | 'topcompanies'
    | 'alliancecount'
    | 'acquisitioncount'
    | 'financingcount'
    | 'topdiseases'
    | 'trialtimeline'
    | 'drugcount'
    | 'diseasecount'
    | 'topdrugsdeepdive'
    | 'topdiseasesdeepdive'
    | 'topdrugdiseasesdeepdive'
    | 'topsponsorsdeepdive'
    | 'topsponsors'
    | 'sponsorcount'
    | 'table'
    | 'totaldealvalue'
    | 'genderdistribution'
    | 'genderdistributiondoughnut'
    | 'agegenderdistribution'
    | 'yearbycountrydistribution'
    | 'fullforecastperyear'
    | 'epigeomap'
    | 'approvedcount'
    | 'pipelinecount'
    | 'inactivecount'
    | 'avgpositiveloa'
    | 'productsaboveavg'
    | 'topdiseasesbysponsors'
    | 'toptargets'
    | 'dealtype'
    | 'totalvaluedeepdive'
    | 'dealstatus'
    | 'topcompaniesdeepdive'
    | 'developmentstatus'
    | 'approveddiseases'
    | 'latestagepipeline'
    | 'locationtype'
    | 'maxforecastyear'
    | 'calculatedmanually'
    | 'contenttype'
    | 'timeline'
    | 'tile'
    | 'articalsource'
    | 'articlebytime'
    | 'drugforecastdoughnut'
    | 'countbyregion'
    | 'revenuelinechart'
    | 'totalrevenueperyear'
    | 'totalrevenuecurrentyear'
    | 'totalrevenueforecastyear'
    | 'toptacurrentyear'
    | 'revenuepertaforallyears'
    | 'totalrevenuebydrug'
    | 'revenuepertacurrentyear'
    | 'cagr'
    | 'currentyearsales'
    | 'lastyearforecast'
    | 'patentexpirybyregion'
    | 'revenueforecastbyyear'
    | 'dependentevent'
    | 'independentevent'
    | 'totalsalescurrentyear'
    | 'totalsalesforecastyear'
    | 'totalrevenueperregioncurrentyear'
    | 'topproductscurrentandforecastyear'
    | 'pivot'
    | 'drugforecastdoughnutdeepdive'
    | 'diseaseforecastdoughnut'
    | 'revenueperta'
    | 'revenuebyregionall'
    | 'yearbycountrydistributiondeepdive'
    | 'totalEventsCount'
    | 'positiveLOACount'
    | 'negativeLOACount'
    | 'noImpactLOACount'
    | 'eventsTile'
    | 'upcomingCatalystCounts'
    | 'largeCatalystsCount'
    | 'otherCatalystsCount'
    | 'catalystsTile'
    | 'totalEventsCountDeepDive'
    | 'eventsTotalUniqueDrugs'
    | 'totalCatalystCountDeepDive'
    | 'catalystTotalUniqueDrugs'
    | 'catalystGroupType'
    | 'eventGroupDeepDive'
    | 'eventByQuarterDeepDive'
    | 'catalystByQuarter'
    | 'eventTable'
    | 'catalystTable'
    | 'totalEventsCountForAllTime'
    | 'totalCatalystAllTime'
    | 'catalystNextExpectedDate'
    | 'averageIndicationsDeepDive'
    | 'diseasescope';
// | 'catalystTable';

// Dashboard types
export type DashboardEntityType = SearchType;
export type DashboardSectionType =
    | 'othernames'
    | 'details'
    | 'summary'
    | 'name'
    | 'totalreportscount'
    | 'mostrecentpublished'
    | 'mostrecentpublishedarticles'
    | 'totalarticlescount'
    | 'datamonitordata';

interface TileConfigurationEntry {
    title: string;
    entity: EntityType;
    innerTitleBuilder?: Function;
    deepDiveKey: EntityType;
    provider: string;
    productUrl: string;
    enquireUrl: string;
    content?: FunctionComponent<any>;
    searchTypeConfig?: any;
    displayFor?: Array<DashboardEntityType>;
    feature: FEATURE;
}

export type TileConfiguration = Array<TileConfigurationEntry>;

export type ComponentClassName = ClassNameValueType[] | ClassNameValueType;

export type FilterType = 'MultiselectFilter' | 'DateFilter';

export interface Filter {
    label: string;
    filterType: FilterType;
    visible?: (visibilityConfig: { dependenciesData: any; chartPath: any }) => boolean;
    singleMode?: boolean;
    defaultValueSelector?: (options: any) => any;
}

export type FilterConfig = Record<string, Filter>;

// TODO change SupportedPanels when you extend filters
type SupportedPanelsSearch = Extract<SearchType, 'company' | 'disease' | 'drug'>;
type SupportedPanelsEntities = Extract<
    EntityType,
    'trial' | 'deals' | 'pipeline' | 'epi' | 'report' | 'news' | 'forecast' | 'eventcatalyst'
>;
export type FilterPanels = Record<
    SupportedPanelsSearch,
    Partial<Record<SupportedPanelsEntities, FilterConfig | FilterConfig[]>>
>;

export type FilterDependantCharts = Record<SearchType, Record<SupportedPanelsEntities, ChartType[]>>;

export type widthMuiType = false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;

export type RawFilterValue = { value: any } & Omit<Filter, 'label'>;

export type RawFilterValues = Record<string, RawFilterValue>;

export type PreparedFilterValue = { fieldName: string; value: any };

export type PreparedFilterValues = PreparedFilterValue[];

export interface MultiselectFilterOption {
    id: string | number;
    name: string;
    [key: string]: any;
}

export interface LegendItem {
    label: string;
    color: string;
}

export type LegendItems = LegendItem[];

export interface ILegendConfig {
    items: LegendItems | { [key: string]: LegendItems };
    columns?: number;
}

export type MultiselectFilterOptions = MultiselectFilterOption[];

export interface DateRangeFilterOptions {
    minDate: Date;
    maxDate: Date;
}

export type FilterOptions = MultiselectFilterOptions | DateRangeFilterOptions;

export type FiltersResponse = Record<string, FilterOptions>;

export type FilterName =
    | 'drug'
    | 'trialPhase'
    | 'trialStatus'
    | 'therapeuticArea'
    | 'disease'
    | 'trialStartDate'
    | 'trialPrimaryCompletionDate'
    | 'dealStatus'
    | 'dealType'
    | 'dealYear'
    | 'product'
    | 'sponsors'
    | 'company'
    | 'devStatus'
    | 'diseaseStatus'
    | 'target'
    | 'companyType'
    | 'contentType'
    | 'gender'
    | 'country'
    | 'region'
    | 'ageGroup'
    | 'forecastYear'
    | 'publishDate'
    | 'articleType'
    | 'sourceProduct'
    | 'articalDate'
    | 'salesyear'
    | 'reportPublishDate'
    | 'eventType'
    | 'eventImpact'
    | 'catalystGroupType'
    | 'catalystImpactType'
    | 'eventDate'
    | 'catalystDate'
    | 'rowId';

export type LocationType = 'country' | 'region';

export type GenderType = 'male' | 'female';

export interface DateRangeFilterValue {
    fromDate: Date;
    toDate: Date;
}

export interface IEntityDataRangeErrorType {
    min: {
        hasError: boolean;
        message: string;
    };
    max: {
        hasError: boolean;
        message: string;
    };
}

export interface DateRangeErrorType {
    fromDate: IEntityDataRangeErrorType;
    toDate: IEntityDataRangeErrorType;
    range: {
        hasError: boolean,
        message: string
    };
}

export interface ChartPath {
    search: SearchType;
    entity: EntityType;
    chart: ChartType;
    value: string;
    subPath?: string;
}

export interface ExistsParams {
    search: SearchType;
    entity: EntityType;
    value: string;
}

export interface EntityUrlParams {
    search: SearchType;
    entity: EntityType;
    value: string;
}

export interface EntityUrlFilter {
    fieldName: string,
    value: string[]
}

export interface EntityUrlWithFiltersParams extends EntityUrlParams {
    filters: EntityUrlFilter[]
}

export interface SectionPreference {
    searchType?: string;
    searchId?: string;
    entityType?: string;
    order: number;
    isCollapsed: boolean;
}
export interface SectionPreferences {
    id?: number;
    preferences: Array<SectionPreference>;
}

export type DatamonitorData = {
    url: string;
    label: string;
    preflabel?: string;
    type?: string;
};

interface TitleBuilderProps {
    isEveryDependencyReady?: boolean;
    dependenciesData?: any;
    filters?: any;
}

export interface IOrderBy {
    fieldName: string;
    direction: SortDirectionType;
}
export interface ProjectChartConfigEntry {
    component: Component | FunctionComponent | MemoExoticComponent<any>;
    size: GridSize;
    title: string | ((titleBuilderProps: TitleBuilderProps) => string);
    titleCaption?: string;
    componentProps?: {
        filters?: RawFilterValues;
        firstColumnTitle?: string;
        secondColumnTitle?: string;
        dependencies?: ChartType[];
        showLabels?: boolean;
        dataLabelFormat?: string;
        titleXAxis?: string;
        titleYAxis?: string;
        options?: any;
        defaultOrderByColumn?: IOrderBy;
    }
    isCurrency?: boolean;
}

export interface ComplexProjectChartConfigEntry {
    components: {
        [key: string]: ProjectChartConfigEntry;
    };
}

export interface ProjectChartConfig {
    [key: string]: ProjectChartConfigEntry | ComplexProjectChartConfigEntry;
}

export type AuthTokenType = {
    entls: {
      SF: {
        sfcid: string;
        sflid: string;
        cid: string;
        cnm: string;
        pcds: {
          p: string;
          aed: string;
          asd: string;
          atyp: 'Full' | 'Trial';
          ecodes: string[];
          lval?: number;
          subid: string;
          subnm: string;
        }[];
      };
    };
    udt: {
      fn: string;
      ln: string;
      cnt: string;
      jt: string;
      cmp: string;
      em: string;
    };
    iss: string;
    sub: string;
    uid: string;
    aud: string[];
    iat: number;
    exp: number;
    azp: string;
    scope: string;
};

export interface ClaimsType {
    __raw: string;
    user_metadata: {
      company: string;
      country_region: string;
      first_name: string;
      isFederated: boolean;
      job_title: string;
      last_name: string;
    };
    userId: string;
    registration_completed: boolean;
    email_verified?: boolean;
    ut: string;
    iss: string;
    aud: string;
    iat: number;
    exp: number;
    sub: string;
    sid: string;
    nonce: string;
  };

export type CastomAnalyticAttributType = 'contact_clientlocation'
 | 'contact_clientdepartment' | 'contact_clientjobrole' | 'contact_clientattribute1' | 'contact_clientattribute2'

export interface ITokenContent {
    salesForceId: string | undefined,
    firstName: string,
    lastName: string,
    email: string,
    roles: string,
    isTrialUser: boolean,
    parentServiceId: string | null,
    subscriptions: Array<string>,
    initials: string,
    organizationName: string,
    customAttributes?: Record<CastomAnalyticAttributType, string>,
    entitlementType: string,
    exp: number
}