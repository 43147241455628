import React, { memo } from "react";
import { Box, Typography, Button } from "@mui/material";

interface IChartErrorProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ChartError: React.FunctionComponent<IChartErrorProps> = memo((props) => {
    return (
        <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
        >
            <Typography>Something went wrong</Typography>
            <Button variant='contained' onClick={props.onClick}>Reload</Button>
        </Box>
    );
});

export default ChartError;