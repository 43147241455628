import { ITableColumn } from '@informa/pharma-common-react-components/build/components/Table/InfiniteTable.interfaces';
import { ENTITY_TYPES } from '../common';

export const TABLE_CHART_TITLE = {
    [ENTITY_TYPES.TRIAL]: 'Trials',
    [ENTITY_TYPES.DEALS]: 'Deals',
    [ENTITY_TYPES.PIPELINE]: 'Drugs',
    [ENTITY_TYPES.NEWS]: 'Articles',
    [ENTITY_TYPES.REPORTS]: 'PDF Report Library',
    [ENTITY_TYPES.FORECAST]: 'Forecasts',
};

export const TRIAL_TABLE_COLUMNS: ITableColumn[] = [
    {
        width: 50,
        label: 'Trial ID',
        dataKey: 'trialid',
    },
    {
        width: 50,
        label: 'Trial Status',
        dataKey: 'trialstatus',
    },
    {
        width: 50,
        label: 'Trial Phase',
        dataKey: 'trialphase',
    },
    {
        width: 300,
        label: 'Primary Drugs',
        dataKey: 'drugname',
    },
    {
        width: 200,
        label: 'Sponsors',
        dataKey: 'sponsorsname',
    },
    {
        width: 50,
        label: 'Start Date',
        dataKey: 'trialstartdate',
        type: 'date',
    },
    {
        width: 150,
        label: 'Primary Completion Date',
        dataKey: 'trialprimarycompletiondate',
        type: 'date',
    },
    {
        width: 50,
        label: '',
        dataKey: 'link',
        disableSort: true,
        isLink: true,
        linkText: 'Profile',
    },
];

export const DEALS_TABLE_COLUMNS: ITableColumn[] = [
    {
        width: 720,
        label: 'Deal Headline',
        dataKey: 'headline',
    },
    {
        width: 50,
        label: 'Deal Type',
        dataKey: 'dealtypename',
    },
    {
        width: 50,
        label: 'Deal Date',
        dataKey: 'dealyear',
        type: 'date',
    },
    {
        width: 70,
        label: 'Potential Deal Value',
        dataKey: 'potentialdealvalue',
        align: 'left',
        type: 'moneyOrNA',
    },
    {
        width: 50,
        label: 'Deal Status',
        dataKey: 'dealstatusname',
    },
    {
        width: 50,
        label: '',
        dataKey: 'link',
        disableSort: true,
        isLink: true,
        linkText: 'Profile',
    },
];

export const PIPELINE_TABLE_COLUMNS: ITableColumn[] = [
    {
        width: 40,
        label: 'Citeline Drug ID',
        dataKey: 'drugid',
    },
    {
        width: 80,
        label: 'Generic Drug Name',
        dataKey: 'genericname',
    },
    {
        width: 250,
        label: 'Drug Names',
        dataKey: 'drugnames',
        disableSort: true,
    },
    {
        width: 250,
        label: 'Targets',
        dataKey: 'targets',
    },
    {
        width: 100,
        label: 'Company Name',
        dataKey: 'company',
    },
    {
        width: 50,
        label: 'Originator / Licensee ',
        dataKey: 'companytype',
    },
    {
        width: 50,
        label: 'Development Status',
        dataKey: 'diseasestatus',
    },
    {
        width: 50,
        label: '',
        dataKey: 'link',
        disableSort: true,
        isLink: true,
        linkText: 'Profile',
    },
];

export const DISEASE_PIPELINE_TABLE_COLUMNS: ITableColumn[] = [
    {
        width: 40,
        label: 'Citeline Drug ID',
        dataKey: 'drugid',
    },
    {
        width: 80,
        label: 'Generic Drug Name',
        dataKey: 'genericname',
    },
    {
        width: 250,
        label: 'Drug Names',
        dataKey: 'drugnames',
        disableSort: true,
    },
    {
        width: 250,
        label: 'Targets',
        dataKey: 'targets',
    },
    {
        width: 100,
        label: 'Company Name',
        dataKey: 'company',
    },
    {
        width: 50,
        label: 'Originator / Licensee ',
        dataKey: 'companytype',
    },
    {
        width: 50,
        label: 'Development Status',
        dataKey: 'devstatus',
    },
    {
        width: 50,
        label: '',
        dataKey: 'link',
        disableSort: true,
        isLink: true,
        linkText: 'Profile',
    },
];

export const REPORTS_TABLE_COLUMNS: ITableColumn[] = [
    {
        width: 250,
        dataKey: 'value',
    },
];

export const NEWS_TABLE_COLUMNS: ITableColumn[] = [
    {
        width: 250,
        dataKey: 'value',
    },
];

export const DRUG_FORECAST_TABLE_COLUMNS: ITableColumn[] = [
    {
        width: 50,
        label: 'Company',
        dataKey: 'company',
    },
    {
        width: 50,
        label: 'Region',
        dataKey: 'region',
    },
    {
        width: 50,
        label: 'Adjustment',
        dataKey: 'eventadjustment',
    },
    {
        width: 50,
        label: 'Product',
        dataKey: 'product',
    },
    {
        width: 50,
        label: 'Event',
        dataKey: 'description',
    },
    {
        width: 50,
        label: 'Sales Year',
        dataKey: 'salesyear',
    },
    {
        width: 50,
        label: 'Dependency',
        dataKey: 'dependency',
    },
];
