import React from 'react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import MenuPopper from '../MenuPopper/MenuPopper';
import { adobeAnalytics } from '../../core/services/analytics/adobe';

import styles from './HelpMenu.module.scss';

export interface IHeaderMenuState {
    isOpen: boolean;
}

export class HelpMenu extends React.Component<{}, IHeaderMenuState> {
    private anchorRef: React.RefObject<HTMLButtonElement>;

    constructor(props: {}) {
        super(props);
        this.state = {
            isOpen: false
        };

        this.anchorRef = React.createRef();
    }

    handleClose = (event: MouseEvent | TouchEvent): void => {
        this.setState({ isOpen: false });
    };

    handleToggle = (): void => {
        if (!this.state.isOpen) {
            adobeAnalytics.trackClickHelp();
        }

        this.setState({ isOpen: !this.state.isOpen });
    };

    public render() {
        return (
            <div>
                <button
                    className={styles.menuButton}
                    data-testid="help-menu-button"
                    ref={this.anchorRef}
                    aria-controls={this.state.isOpen ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                >
                    <Typography variant="body1">Help</Typography>
                </button>
                <MenuPopper isOpen={this.state.isOpen}
                    anchorRef={this.anchorRef}
                    onClickAway={this.handleClose}
                    position="center top"
                >
                    <MenuList autoFocusItem={false}
                        id="menu-list-grow"
                        data-testid="help-menu"
                        disablePadding={true}
                        className={styles.menuListItems}>
                        <MenuItem
                            component='a'
                            href="mailto:clientservices@citeline.com?subject=%5BPharma360%5D%20-%20Assistance%20needed"
                            >
                           Contact Client Services 
                        </MenuItem>
                        {/* <MenuItem component={Link} to="/help">Help Center</MenuItem> */}
                    </MenuList>
                </MenuPopper>
            </div>
        );
    }
}

export default HelpMenu;
