import { SEARCH_TYPES } from './common';

const DASHBOARD_PREFIX = '/dashboard';
export const HOME_ROUTE = '/';
export const DASHBOARD_ROUTE = `${DASHBOARD_PREFIX}/:search/:id`;
export const DEEP_DIVE_ROUTE = `${DASHBOARD_ROUTE}/:tile`;
export const MY_360_ROUTE = '/projectfolders';
export const MY_360_PROJECT_VIEW_ROUTE = '/project/:projectId';
export const FORBIDDEN_ROUTE = '/forbidden';
export const LOG_OUT_CALLBACK_ROUTE = '/logoutCallback';
export const PROFILE_CALLBACK_ROUTE = '/profile_callback';

export const ENTITY_ROUTES = {
    [SEARCH_TYPES.COMPANY]: `${DASHBOARD_PREFIX}/company`,
    [SEARCH_TYPES.DRUG]: `${DASHBOARD_PREFIX}/drug`,
    [SEARCH_TYPES.DISEASE]: `${DASHBOARD_PREFIX}/disease`,
};