import _find from 'lodash-es/find';
import _capitalize from 'lodash-es/capitalize';
import { TILE_CONFIG } from '../../constants/tileConfig';
import { EntityType } from '../../typings';

export const getProviderByEntity = (entity?: EntityType) => _find(TILE_CONFIG, ['entity', entity])?.provider;

export const getReportsProductName = (url: string) => {
    if (url.length) {
        return url.includes('biomedtracker') ? 'Biomedtracker' : 'Datamonitor Healthcare';
    }

    return null;
};

export const getNewsAndInsightsProductName = (url: string) => _capitalize(
    url.replace('https://', '').split('.')[0]
);