import React, { FunctionComponent, SyntheticEvent, useEffect } from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { makeStyles } from '@mui/styles';
import { CORE_COLORS } from '../../constants/colors';
import { useSelector, useDispatch } from 'react-redux';
import { getNotificationMessage } from '../../core/selectors/notifications.selectors';
import { clearNotification } from '../../core/actions/notifications.actions';

const useStyles = makeStyles(theme => ({
    snackbar: {
        backgroundColor: CORE_COLORS.PRIMARY,
        color: 'white'
    }
}));

const SnackBarNotification: FunctionComponent = () => {
    const styles = useStyles();

    const notificationMessage: string | undefined = useSelector(getNotificationMessage);
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState<boolean>(false);

    useEffect(() => {
        setOpen(!!notificationMessage)
    }, [notificationMessage])

    const handleClose = (event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        dispatch(clearNotification());
        setOpen(false);
    };

    return <Snackbar
        ContentProps={{
            classes: {
                root: styles.snackbar
            }

        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2500}
        open={open}
        message={notificationMessage}
        onClose={handleClose}
        data-testid="snackbar-notification"
    />;
};

export default SnackBarNotification;
