import React, { useMemo, memo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { COUNTER_VARIANTS_MAP } from '@informa/pharma-common-react-components';
import { CounterChart } from '../../Charts/CounterChart';
import { SummaryChart } from '../../Charts/SummaryChart';
import { DoughnutChart } from '../../Charts/DoughnutChart';
import { StackedColumnChart } from '../../Charts/StackedColumnChart';
import { StackedBarChart } from '../../Charts/StackedBarChart';
import { TableChart } from '../../Charts/TableChart';
import { ChartsBatchRequestPath } from '../../../core/actions/actionTypes/charts.actionsTypes';
import { DEEP_DIVE_REDUX_KEY } from '../../../constants/deepDiveConfig';
import { CHART_TYPES } from '../../../constants/common';
import DeepDivePanel from '../Shared/DeepDivePanel/DeepDivePanel';
import { createChartClickHandlerFactory, createChartLabelClickHandlerFactory } from '../../../core/helpers/filter';
import { DeepDiveContentProps } from '../typings';

import styles from '../DeepDiveContent.module.scss';

const chartPaperProps = {
    className: styles.deepDiveChartsContainerItem,
    elevation: 0,
};

export default memo((props: DeepDiveContentProps) => {
    const chartPathProps = useMemo<ChartsBatchRequestPath>(
        () => ({
            ...props,
            subPath: DEEP_DIVE_REDUX_KEY,
        }),
        [props]
    );
    const createChartClickHandler = createChartClickHandlerFactory(props.onClick);
    const createChartLabelClickHandler = createChartLabelClickHandlerFactory(props.onClick);

    const onOpenDialog = (chartType) => props.onOpenDialog(chartType);

    return (
        <Grid container spacing={3}>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.COUNT,
                        }}
                        componentProps={{
                            label: 'Total Number of Trials',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <SummaryChart
                        {...chartPathProps}
                        direction='row'
                        size={COUNTER_VARIANTS_MAP.LARGE}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Trial Phase'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.PHASE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.PHASE,
                    }}
                    filters={props.filters}>
                    <DoughnutChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.PHASE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.PHASE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Trial Timeline'
                    titleCaption='(Start Date)'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TRIAL_TIMELINE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TRIAL_TIMELINE,
                    }}
                    filters={props.filters}>
                    <StackedColumnChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TRIAL_TIMELINE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.TRIAL_TIMELINE),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.TRIAL_TIMELINE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={6} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.DISEASE_COUNT,
                        }}
                        componentProps={{
                            label: 'Number Of Diseases',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={6} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.SPONSOR_COUNT,
                        }}
                        componentProps={{
                            label: 'Number of Sponsors',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>

            <Grid item xs={6} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Top 10 Diseases'
                    disableElevation
                    heightVariant='large'
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TOP_DISEASES_DEEP_DIVE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TOP_DISEASES_DEEP_DIVE,
                    }}
                    filters={props.filters}>
                    <StackedBarChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TOP_DISEASES_DEEP_DIVE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.TOP_DISEASES_DEEP_DIVE),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.TOP_DISEASES_DEEP_DIVE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={6} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Top 10 Sponsors'
                    disableElevation
                    heightVariant='large'
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TOP_SPONSORS_DEEP_DIVE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TOP_SPONSORS_DEEP_DIVE,
                    }}
                    filters={props.filters}>
                    <StackedBarChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TOP_SPONSORS_DEEP_DIVE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.TOP_SPONSORS_DEEP_DIVE),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.TOP_SPONSORS_DEEP_DIVE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title=''
                    disableElevation
                    isDownloadAvailable
                    isImageDisabled
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TABLE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TABLE,
                    }}
                    filters={props.filters}>
                    <TableChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TABLE,
                        }}
                        componentProps={{
                            filters: props.filters,
                        }}
                    />
                </DeepDivePanel>
            </Grid>
        </Grid>
    );
});
