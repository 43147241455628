import React from 'react';
import Grid from '@mui/material/Grid';
import { COUNTER_VARIANTS_MAP } from '@informa/pharma-common-react-components';
import _isNumber from 'lodash-es/isNumber';
import { CounterChart } from '../CounterChart';
import { TileContentProps } from '../../TileContent/typings';
import { CHART_TYPES, SALES_FORECAST_REGIONS } from '../../../constants/common';
import { EntityType, RawFilterValues } from '../../../typings';
import { getCurrentYear } from '../../../core/helpers/dates';
import { formatCurrency } from '../../../core/helpers/number';

import styles from './DrugForecastSummaryChart.module.scss';

interface SummaryChartProps extends Omit<TileContentProps, 'chart'> {
    entity: EntityType;
    filters?: RawFilterValues;
}

const prevForecastYear = getCurrentYear() - 1;

const drugForecastLabelBuilder = ({ data }) => (!data?.data?.length ? '' : `Patent Exp: ${data.data[0].expirydate}`);
const createDrugForecastFiltersBuilder = (region: string) => () => ({ regionForTile: region });
const valueBuilder = ({ data }) => (_isNumber(data?.total) ? formatCurrency(data?.total) : 'No data');

export default (props: SummaryChartProps) => (
    <Grid
     container
    direction='column'
    sx={{justifyContent: 'space-between'}}
    data-testid='drug-forecast-summary-chart'>
        <Grid
            item
            classes={{
                root: styles.forecastSummaryTitle,
            }}>
            Revenue by Region, {prevForecastYear}
        </Grid>
        {Object.values(SALES_FORECAST_REGIONS).map((region, index) => (
            <Grid
                item
                classes={{
                    root: styles.forecastSummaryFigure,
                }}
                key={index}>
                <CounterChart
                    key={region}
                    pathConfig={{ ...props, chart: CHART_TYPES.COUNT_BY_REGION, subPath: region }}
                    componentProps={{
                        size: COUNTER_VARIANTS_MAP.SMALL,
                        inverted: false,
                        beforeValue: `${region}: `,
                    }}
                    labelBuilder={drugForecastLabelBuilder}
                    valueBuilder={valueBuilder}
                    filtersBuilder={createDrugForecastFiltersBuilder(region)}
                />
            </Grid>
        ))}
    </Grid>
);
