import { combineReducers } from 'redux';

import userReducer from './user.reducer';
import subscriptionsReducer from './subscriptions.reducer';
import chartsReducer from './charts.reducer';
import entitiesReducer from './entities.reducer';
import filtersReducer from './filters.reducer';
import searchReducer from './search.reducer';
import projectsReducer from './projects.reducer';
import entityUrlReducer from './entityUrl.reducer';
import notifications from './notifications.reducer';

const rootReducer = combineReducers({
    subscriptionsData: subscriptionsReducer,
    userData: userReducer,
    charts: chartsReducer,
    entities: entitiesReducer,
    filtersData: filtersReducer,
    searchData: searchReducer,
    projectsData: projectsReducer,
    entityUrls: entityUrlReducer,
    notificationsData: notifications
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
