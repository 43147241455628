import { put, call, all, fork, select, cancel, take } from 'redux-saga/effects';
import { loadChartData } from '../services/charts.service';
import * as actions from '../actions/charts.actions';
import * as actionTypes from '../actions/actionTypes/charts.actionsTypes';
import { ChartsRequestAction } from '../actions/actionTypes/charts.actionsTypes';
import { createChartDataSelector } from '../selectors/charts.selectors';
import { CHART_TYPES } from '../../constants/common';

export function* onLoadChartData({ payload }: ChartsRequestAction): Generator<any, any, any> {
	try {
		// TODO Use it as a local stub for counter values instead of making real request
		// yield delay(1000);
		// const data = { value: 500 };
		if (payload.pathConfig.chart === CHART_TYPES.CALCULATED_MANUALLY) {
			yield put(actions.chartDataLoaded(payload.pathConfig, {}));
		}

		const chartData = yield select(createChartDataSelector(payload.pathConfig));

		if (chartData && chartData.isLoading) {
			return;
		}

		yield put(actions.chartDataLoading(payload.pathConfig));

		const data = yield call(loadChartData, payload.pathConfig, payload.filters);

		yield put(actions.chartDataLoaded(payload.pathConfig, data));
	} catch (error) {
		yield put(actions.chartDataError(payload.pathConfig, error));
	}
}

const takeLatestByChart = (patternOrChannel, saga, ...args) => fork(function*() {
	const lastTasks = {}
	while (true) {
	  const action = yield take(patternOrChannel)
      const pathConfig = action.payload.pathConfig
	  const chartType: string = Object.values(pathConfig).join('/')
	  if (lastTasks[chartType]) {
		yield cancel(lastTasks[chartType])
	  }
	  lastTasks[chartType] = yield fork(saga, ...args.concat(action))
	}
  }
)

function* watchOnLoadChartData() {
	yield takeLatestByChart(actionTypes.DATA_REQUEST, onLoadChartData);
}

export default function* chartsSaga() {
	yield all([
		fork(watchOnLoadChartData)
	]);
}
