import { AppAction } from '../../store/typings';
import { Action } from 'redux';

export const GET_PROJECTS = 'PROJECTS/GET_ALL';
export const GET_PROJECTS_SUCCESS = 'PROJECTS/GET_ALL_SUCCESS';
export const GET_PROJECTS_ERROR = 'PROJECTS/GET_ALL_ERROR';
export const GET_PROJECTS_PAGED = 'PROJECTS/GET_PAGED';
export const GET_PROJECTS_PAGED_SUCCESS = 'PROJECTS/GET_PAGED_SUCCESS';
export const GET_PROJECTS_PAGED_ERROR = 'PROJECTS/GET_PAGED_ERROR';
export const ADD_PROJECTS = 'PROJECTS/ADD';
export const CLEAR_PROJECTS = 'PROJECTS/CLEAR';

export interface IGetProjectsPagedSuccess {
    pagedProjects: any[];
    count: number;
}

export interface IGetProjectsSuccess {
    projects: any[];
}

export interface IGetProjectsPaged {
    page: number;
    pageSize: number;
    order: string
}

export interface SavedSearch {
    id: number;
    entityType: string;
    searchType: string;
    searchSubSection?: null;
    searchTerm: string;
    searchId: string;
    filters?: any;
    createdAt: string;
}

export interface Project {
    id?: number;
    name: string;
    description: string;
    notes: string | null;
    createdAt: string;
    updatedAt: string;
    savedSearches: SavedSearch[];
    isPinned?: boolean;
    savedRows?: any;
}

export type GetProjectsAction = Action<typeof GET_PROJECTS>
export type GetProjectsSuccessAction = AppAction<typeof GET_PROJECTS_SUCCESS, IGetProjectsSuccess>;
export type GetProjectsErrorAction = AppAction<typeof GET_PROJECTS_ERROR, any>;


export type GetProjectsPagedAction = AppAction<typeof GET_PROJECTS_PAGED, IGetProjectsPaged>;
export type GetProjectsPagedSuccessAction = AppAction<typeof GET_PROJECTS_PAGED_SUCCESS, IGetProjectsPagedSuccess>;
export type GetProjectsPagedErrorAction = AppAction<typeof GET_PROJECTS_PAGED_ERROR, any>;
export type SaveProjectsAction = AppAction<typeof ADD_PROJECTS, Array<Project>>;
export type ClearProjectsAction = Action<typeof CLEAR_PROJECTS>

export type ProjectsAction =
    | GetProjectsAction
    | GetProjectsSuccessAction
    | GetProjectsErrorAction
    | GetProjectsPagedAction
    | GetProjectsPagedSuccessAction
    | GetProjectsPagedErrorAction
    | SaveProjectsAction
    | ClearProjectsAction;
