import { api } from '../api';

export async function startDownload(params: any) {
      return await api.downloads.excel(params);
}

export async function checkStatus(requestId: string) {
      return await api.downloads.checkStatus(requestId);
}

export async function downloadExcel(url: string) {
      return await api.downloads.downloadExcel(url)
}
