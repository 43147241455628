import React, { FunctionComponent, useState } from 'react';
import Typography from '@mui/material/Typography';
import FeedbackDialog from './FeedbackDialog/FeedbackDialog';

import styles from './FeedbackMenu.module.scss';

export const FeedbackMenu: FunctionComponent = () => {
    const [ modalVisible, setModalVisible ] = useState(false);

    const toggleModal = () => {
        setModalVisible(!modalVisible);
    }
    return (
        <>
            <FeedbackDialog
                visible={modalVisible}
                onClose={toggleModal}
            />
            <div>
                <button
                    className={styles.menuButton}
                    data-testid="feedback-menu-button"
                    onClick={toggleModal}
                >
                    <Typography variant="body1">Feedback</Typography>
                </button>
            </div>
        </>
    )
}