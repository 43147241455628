import React, { CSSProperties, memo } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { CounterVariant } from '@informa/pharma-common-react-components';
import { PointerEventObject } from 'highcharts';
import usePharmaChart from '../../../hooks/usePharmaChart';
import { ChartPath, ChartType, IOrderBy, RawFilterValues } from '../../../typings';
import ChartError from '../ChartError/ChartError';
import { CHART_TYPES } from '../../../constants/common';

export interface ChartRendererComponentProps {
    label?: string;
    size?: CounterVariant | string;
    inverted?: boolean;
    labelColor?: string;
    valueColor?: string;
    beforeValue?: string;
    afterValue?: string;
    firstColumnTitle?: string;
    firstColumnWidth?: string;
    secondColumnTitle?: string;
    secondColumnWidth?: string;
    showLabels?: boolean;
    onClick?: (chartType: string, event: PointerEventObject) => void;
    onLabelClick?: (chartType: string, axisType: string, event: { value: any }) => void;
    filters?: RawFilterValues;
    additionalData?: any;
    options?: any;
    titleXAxis?: string;
    titleYAxis?: string;
    dataLabelFormat?: string;
    barDataAxisName?: string;
    lineDataAxisName?: string;
    lineUnit?: string;
    barUnit?: string;
    labelPosition?: 'top' | 'bottom';
    labelAlignment?: CSSProperties['textAlign'];
    valueFormatter?: (value: any) => number | string;
    defaultOrderByColumn?: IOrderBy;
    isProjectFolder?: boolean;
}

export type ValueLabelBuilder = (valueLabelBuilderConfig: any) => string | null;

export type FiltersBuilder = (filterBuilderConfig: any) => any;

export interface ChartCommonProps {
    pathConfig: ChartPath;
    componentProps?: ChartRendererComponentProps;
    filters?: RawFilterValues;
    filtersBuilder?: FiltersBuilder;
    dependencies?: ChartType[];
    labelBuilder?: ValueLabelBuilder;
    valueBuilder?: ValueLabelBuilder;
    useFiltersForDependencies?: boolean;
    isStatic?: boolean; // when true charts loads once and its not affected by filters
    isCurrency?: boolean;
}

export interface ChartRendererProps<DataType = any> {
    data: DataType;
    dependenciesData: any;
    pathConfig: ChartPath;
    componentProps?: ChartRendererComponentProps;
    labelBuilder?: ValueLabelBuilder;
    valueBuilder?: ValueLabelBuilder;
    isCurrency?: boolean;
}

interface ChartContainerCommonProps extends ChartCommonProps {
    component: React.FunctionComponent<any>;
}

export default memo(({
    pathConfig,
    filters,
    filtersBuilder,
    component,
    dependencies,
    componentProps,
    labelBuilder,
    valueBuilder,
    useFiltersForDependencies,
    isStatic,
    isCurrency
}: ChartContainerCommonProps) => {
    const { isLoading, data, isEveryDependencyReady, dependenciesData, error, loadData } = usePharmaChart(
        pathConfig,
        dependencies,
        filters,
        filtersBuilder,
        useFiltersForDependencies,
        isStatic
    );

    if (error) {
        return <ChartError onClick={loadData} />;
    }

    const isDataReady = pathConfig.chart === CHART_TYPES.CALCULATED_MANUALLY ? !error : !!data;

    return isEveryDependencyReady && isDataReady && !isLoading ? (
        React.createElement<ChartRendererProps<typeof data>>(component, {
            data,
            dependenciesData,
            pathConfig,
            componentProps,
            labelBuilder,
            valueBuilder,
            isCurrency
        })
    ) : (
            <Box display='flex' alignItems='center' justifyContent='center'>
                <CircularProgress data-testid='chart-loader' />
            </Box>
        );
});
