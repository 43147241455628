import React, { useMemo, memo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CounterChart } from '../../Charts/CounterChart';
import { DoughnutChart } from '../../Charts/DoughnutChart';
import { StackedBarChart } from '../../Charts/StackedBarChart';
import { TableChart } from '../../Charts/TableChart';
import { ChartsBatchRequestPath } from '../../../core/actions/actionTypes/charts.actionsTypes';
import { DEEP_DIVE_REDUX_KEY } from '../../../constants/deepDiveConfig';
import { CHART_TYPES } from '../../../constants/common';
import DeepDivePanel from '../Shared/DeepDivePanel/DeepDivePanel';
import { createChartClickHandlerFactory, createChartLabelClickHandlerFactory } from '../../../core/helpers/filter';
import { DeepDiveContentProps } from '../typings';
import { ColumnChartWithLine } from '../../Charts/ColumnChartWithLine';
import { avgDealValueBuilder } from '../Disease/Deals';

import styles from '../DeepDiveContent.module.scss';

const chartPaperProps = {
    className: styles.deepDiveChartsContainerItem,
    elevation: 0,
};

export default memo((props: DeepDiveContentProps) => {
    const chartPathProps = useMemo<ChartsBatchRequestPath>(
        () => ({
            ...props,
            subPath: DEEP_DIVE_REDUX_KEY,
        }),
        [props]
    );
    const createChartClickHandler = createChartClickHandlerFactory(props.onClick);
    const createChartLabelClickHandler = createChartLabelClickHandlerFactory(props.onClick);
    const onOpenDialog = (chartType) => props.onOpenDialog(chartType);

    return (
        <Grid container spacing={3}>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.COUNT_DEEP_DIVE,
                        }}
                        componentProps={{
                            label: 'Total Number of Deals',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TOTAL_DEAL_VALUE,
                        }}
                        componentProps={{
                            label: 'Total Potential Deal Value',
                            inverted: true,
                        }}
                        filters={props.filters}
                        isCurrency
                    />
                </Paper>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.CALCULATED_MANUALLY,
                        }}
                        componentProps={{
                            label: 'Average Deal Value',
                            inverted: true,
                        }}
                        dependencies={[CHART_TYPES.COUNT_DEEP_DIVE, CHART_TYPES.TOTAL_DEAL_VALUE]}
                        valueBuilder={avgDealValueBuilder}
                        useFiltersForDependencies
                        isCurrency
                    />
                </Paper>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Deal Status'
                    titleCaption='(Number of Deals)'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.DEAL_STATUS)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.DEAL_STATUS,
                    }}
                    filters={props.filters}>
                    <DoughnutChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.DEAL_STATUS,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.DEAL_STATUS),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Top Companies'
                    titleCaption='(Number of Deals)'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TOP_COMPANIES_DEEP_DIVE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TOP_COMPANIES_DEEP_DIVE,
                    }}
                    filters={props.filters}>
                    <StackedBarChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TOP_COMPANIES_DEEP_DIVE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.TOP_COMPANIES_DEEP_DIVE),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.TOP_COMPANIES_DEEP_DIVE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Deal Year'
                    titleCaption='(Number of Deals and Potential Deal Value)'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TOTAL_VALUE_DEEP_DIVE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TOTAL_VALUE_DEEP_DIVE,
                    }}
                    filters={props.filters}>
                    <ColumnChartWithLine
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TOTAL_VALUE_DEEP_DIVE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.TOTAL_VALUE_DEEP_DIVE),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.TOTAL_VALUE_DEEP_DIVE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title=''
                    disableElevation
                    isDownloadAvailable
                    isImageDisabled
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TABLE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TABLE,
                    }}
                    filters={props.filters}>
                    <TableChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TABLE,
                        }}
                        componentProps={{
                            filters: props.filters,
                        }}
                    />
                </DeepDivePanel>
            </Grid>
        </Grid>
    );
});
