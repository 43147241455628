import React, { FunctionComponent, memo } from 'react';
import Grid from '@mui/material/Grid';
import { DoughnutChart } from '../Charts/DoughnutChart';
import { TileContentProps } from './typings';
import { EntityType } from '../../typings';
import TileContentWrapper from './TileContentWrapper/TileContentWrapper';
import { EpiSummaryChart } from '../Charts/EpiSummaryChart';
import { CHART_TYPES } from '../../constants/common';
import { GenderDistributionChart } from '../Charts/GenderDistributionChart';
import { getCurrentYear } from '../../core/helpers/dates';
import InnerTitle from '../Charts/InnerTitle/InnerTitle';

const forecastYearFiltersBuilder = () => ({
	forecastYear: getCurrentYear()
});

const Epi: FunctionComponent<TileContentProps> = (props) => {
	const pathConfig = {
		...props,
		entity: 'epi' as EntityType
	};

	return (
		<TileContentWrapper testId='epi-tile-content'>
			<Grid item xs={12}>
				<InnerTitle
					componentProps={{
						label: "Epidemiology disease scope:"
					}}
					pathConfig={{
						...pathConfig,
						chart: CHART_TYPES.DISEASE_SCOPE
					}} />
			</Grid>
			<Grid item xs={3}>
				<EpiSummaryChart {...pathConfig} />
			</Grid>
			<Grid
				item
				container
				xs={5}
				direction='row'
				sx={{
					justifyContent: 'space-around',
					alignItems:'center'
				}}
			>
				<GenderDistributionChart
					pathConfig={{
						...pathConfig,
						chart: CHART_TYPES.GENDER_DISTRIBUTION
					}}
					filtersBuilder={forecastYearFiltersBuilder}
				/>
			</Grid>
			<Grid item xs={4}>
				<DoughnutChart
					pathConfig={{
						...pathConfig,
						chart: CHART_TYPES.YEAR_BY_COUNTRY_DISTRIBUTION
					}}
					filtersBuilder={forecastYearFiltersBuilder}
				/>
			</Grid>
		</TileContentWrapper>
	);
};

export default memo(Epi);