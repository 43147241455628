import React, { useMemo, memo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CounterChart } from '../../Charts/CounterChart';
import { DoughnutChart } from '../../Charts/DoughnutChart';
import { StackedBarChart } from '../../Charts/StackedBarChart';
import { TableChart } from '../../Charts/TableChart';
import { ChartsBatchRequestPath } from '../../../core/actions/actionTypes/charts.actionsTypes';
import { DEEP_DIVE_REDUX_KEY } from '../../../constants/deepDiveConfig';
import { CHART_TYPES } from '../../../constants/common';
import DeepDivePanel from '../Shared/DeepDivePanel/DeepDivePanel';
import { DeepDiveContentProps } from '../typings';
import { COUNTER_VARIANTS_MAP } from '@informa/pharma-common-react-components';
import styles from '../DeepDiveContent.module.scss';
import { SunburstChart } from '../../Charts/SunburstChart';
import { GroupedBarChart } from '../../Charts/GroupedBarChart';
import { PipelineSummaryChart } from '../../Charts/SummaryChart';
import { createChartClickHandlerFactory, createChartLabelClickHandlerFactory } from '../../../core/helpers/filter';

const chartPaperProps = {
    className: styles.deepDiveChartsContainerItem,
    elevation: 0,
};

export default memo((props: DeepDiveContentProps) => {
    const chartPathProps = useMemo<ChartsBatchRequestPath>(
        () => ({
            ...props,
            subPath: DEEP_DIVE_REDUX_KEY,
        }),
        [props]
    );

    const createChartClickHandler = createChartClickHandlerFactory(props.onClick);
    const createChartLabelClickHandler = createChartLabelClickHandlerFactory(props.onClick);
    const onOpenDialog = (chartType) => props.onOpenDialog(chartType);

    return (
        <Grid container spacing={3}>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.COUNT,
                        }}
                        componentProps={{
                            label: 'Number of Drugs',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <PipelineSummaryChart
                        {...chartPathProps}
                        direction='row'
                        size={COUNTER_VARIANTS_MAP.LARGE}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            {/* <Grid item xs={2} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.AVGPOSITIVELOA,
                        }}
                        componentProps={{
                            label: "Average Positive LoA",
                            afterValue: "%",
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={2} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.PRODUCTSABOVEAVG,
                        }}
                        componentProps={{
                            label: "Products w/ LoA above Avg.",
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid> */}
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Development Status Group'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.HIGHEST_DISEASE_STATUS)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.HIGHEST_DISEASE_STATUS,
                    }}
                    filters={props.filters}>
                    <DoughnutChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.HIGHEST_DISEASE_STATUS,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.HIGHEST_DISEASE_STATUS),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Development Status'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED,
                    }}
                    filters={props.filters}>
                    <GroupedBarChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={6} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Top 10 Targets'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TOP_DISEASES)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TOP_DISEASES,
                    }}
                    filters={props.filters}>
                    <StackedBarChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TOP_DISEASES,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.TOP_DISEASES),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.TOP_DISEASES),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={6} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Top 10 Diseases'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TOP_DISEASES_BY_SPONSORS)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TOP_DISEASES_BY_SPONSORS,
                    }}
                    filters={props.filters}>
                    <StackedBarChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TOP_DISEASES_BY_SPONSORS,
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title=''
                    isDownloadAvailable
                    isImageDisabled
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TABLE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TABLE,
                    }}
                    filters={props.filters}>
                    <TableChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TABLE,
                        }}
                        componentProps={{
                            filters: props.filters,
                        }}
                    />
                </DeepDivePanel>
            </Grid>
        </Grid>
    );
});
