import React, { useMemo, memo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CounterChart } from '../../Charts/CounterChart';
import { DoughnutChart } from '../../Charts/DoughnutChart';
import { StackedBarChart } from '../../Charts/StackedBarChart';
import { TableChart } from '../../Charts/TableChart';
import { ChartsBatchRequestPath } from '../../../core/actions/actionTypes/charts.actionsTypes';
import { DEEP_DIVE_REDUX_KEY } from '../../../constants/deepDiveConfig';
import { CHART_TYPES } from '../../../constants/common';
import DeepDivePanel from '../Shared/DeepDivePanel/DeepDivePanel';
import { createChartClickHandlerFactory, createChartLabelClickHandlerFactory } from '../../../core/helpers/filter';
import { DeepDiveContentProps } from '../typings';
import { SplitStackedBarChart } from '../../Charts/SplitStackedBarChart';
import { GroupedBarChart } from '../../Charts/GroupedBarChart';

import styles from '../DeepDiveContent.module.scss';

const chartPaperProps = {
    className: styles.deepDiveChartsContainerItem,
    elevation: 0,
};

export default memo((props: DeepDiveContentProps) => {
    const chartPathProps = useMemo<ChartsBatchRequestPath>(
        () => ({
            ...props,
            subPath: DEEP_DIVE_REDUX_KEY,
        }),
        [props]
    );
    const createChartClickHandler = createChartClickHandlerFactory(props.onClick);
    const createChartLabelClickHandler = createChartLabelClickHandlerFactory(props.onClick);
    const onOpenDialog = (chartType) => props.onOpenDialog(chartType);

    return (
        <Grid container spacing={3}>
            <Grid item xs={3} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.COUNT,
                        }}
                        componentProps={{
                            label: 'Number of Drugs',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={3} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.APPROVED_COUNT,
                        }}
                        componentProps={{
                            label: 'Approved',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={3} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.PIPELINE_COUNT,
                        }}
                        componentProps={{
                            label: 'Pipeline',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={3} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.INACTIVE_COUNT,
                        }}
                        componentProps={{
                            label: 'Inactive',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            {/* <Grid item xs={2} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.AVGPOSITIVELOA,
                        }}
                        componentProps={{
                            label: "Average Positive LoA",
                            afterValue: "%",
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={2} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.PRODUCTSABOVEAVG,
                        }}
                        componentProps={{
                            label: "Products w/ LoA above Avg.",
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid> */}
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Development Status Group'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.HIGHEST_DISEASE_STATUS)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.HIGHEST_DISEASE_STATUS,
                    }}
                    filters={props.filters}>
                    <DoughnutChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.HIGHEST_DISEASE_STATUS,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.HIGHEST_DISEASE_STATUS),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Development Status'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED,
                    }}
                    filters={props.filters}>
                    <GroupedBarChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Top 10 Targets'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TOP_TARGETS)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TOP_TARGETS,
                    }}
                    filters={props.filters}>
                    <StackedBarChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TOP_TARGETS,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.TOP_TARGETS),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.TOP_TARGETS),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Top 10 Companies'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TOP_DISEASES_BY_SPONSORS)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TOP_DISEASES_BY_SPONSORS,
                    }}
                    filters={props.filters}>
                    <SplitStackedBarChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TOP_DISEASES_BY_SPONSORS,
                        }}
                        componentProps={{
                            firstColumnTitle: 'Originator',
                            secondColumnTitle: 'Licensee',
                            onClick: createChartClickHandler(CHART_TYPES.TOP_DISEASES_BY_SPONSORS),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.TOP_DISEASES_BY_SPONSORS),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title=''
                    disableElevation
                    isDownloadAvailable
                    isImageDisabled
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TABLE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TABLE,
                    }}
                    filters={props.filters}>
                    <TableChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TABLE,
                        }}
                        componentProps={{
                            filters: props.filters,
                        }}
                    />
                </DeepDivePanel>
            </Grid>
        </Grid>
    );
});
