import { RefObject, useLayoutEffect, useState } from 'react';

const checkOverflow = (container: HTMLElement | null) => {
    if (container) {
        const { scrollHeight, clientHeight } = container;

        return scrollHeight > clientHeight;
    }

    return false;
};

export default ({ current }: RefObject<HTMLElement>) => {
	const [isOverflowed, setIsOverflowed] = useState(false);
	const clientHeight = current?.clientHeight;
	const scrollHeight = current?.scrollHeight;

	useLayoutEffect(() => {
        const result = checkOverflow(current);

        if (result !== isOverflowed) {
            setIsOverflowed(result);
        }
	}, [scrollHeight, clientHeight, current, isOverflowed]);
	
	return isOverflowed;
};