import { AppState } from '../reducers/root.reducer';
import { buildChartPath } from '../helpers/common';
import { EntityEntry } from '../reducers/entities.reducer';
import { ChartPath, ChartType } from '../../typings';

export const EMPTY_SELECTOR_RESULT = {
	isLoading: false,
	data: null,
	error: null
};

export const createChartDataSelector = (pathConfig: ChartPath) => (state: AppState) => {
	const path = buildChartPath(pathConfig);
	const chartsState = state.charts;

	if (chartsState && chartsState.data && chartsState.data.hasOwnProperty(path)) {
		const { data, isLoading, error }: EntityEntry = chartsState.data[path];

		return {
			data,
			isLoading,
			error
		};
	}

	return EMPTY_SELECTOR_RESULT;
};

export const createChartDependenciesSelector = (pathConfig: Omit<ChartPath, 'chart'>, dependencies: ChartType[]) =>
	(state: AppState) => dependencies.reduce((accumulator, chart) => {
		accumulator[chart] = createChartDataSelector({
			...pathConfig,
			chart
		})(state);

		return accumulator;
	}, {} as any);