import { put, call, takeEvery, all, fork } from 'redux-saga/effects';
import * as actions from '../actions/search.actions';
import * as actionTypes from '../actions/actionTypes/search.actionTypes';
import { getSearchData } from '../services/search.service';

export function* onGetSearch({ payload }: any): Generator<any, any, any> {
    try {
        const data = yield call(getSearchData, payload);
        yield put(actions.getSearchSuccess(data));
    } catch (error) {
        yield put(actions.getSearchError(error));
    }
}

function* watchOnGetSearch() {
    yield takeEvery(actionTypes.GET_SEARCH_DATA, onGetSearch);
}

export default function* searchSaga() {
    yield all([fork(watchOnGetSearch)]);
}
