import { EntityUrlParams, SearchType, EntityType, EntityUrlWithFiltersParams } from '../../../typings';
import { AppAction } from '../../store/typings';
export const GET_ENTITY_URL_DATA = 'ENTITY_URL/GET';
export const GET_ENTITY_URL_SUCCESS = 'ENTITY_URL/GET_SUCCESS';
export const GET_ENTITY_URL_ERROR = 'ENTITY_URL/GET_ERROR';

export const GET_ENTITY_URL_WITH_FILTERS_DATA = 'ENTITY_URL_FILTERS/GET';
export const GET_ENTITY_URL_WITH_FILTERS_SUCESS = 'ENTITY_URL_FILTERS/GET_SUCCESS';
export const GET_ENTITY_URL_WITH_FILTERS_ERROR = 'ENTITY_URL_FILTERS/GET_ERROR';

export interface EntityUrlActionPayload {
    search: SearchType;
    entity: EntityType;
    value: string;
}

interface IEntityUrlActionReadyPayload extends EntityUrlActionPayload {
    url: string;
}

export interface IEntityUrlActionError extends EntityUrlActionPayload {
    error: string | null;
}

export type GetEntityUrlAction = AppAction<typeof GET_ENTITY_URL_DATA, EntityUrlParams>;
export type GetEntityUrlSuccessAction = AppAction<typeof GET_ENTITY_URL_SUCCESS, IEntityUrlActionReadyPayload>;
export type GetEntityUrlErrorAction = AppAction<typeof GET_ENTITY_URL_ERROR, IEntityUrlActionError>;

export type GetEntityUrlWithFiltersAction = AppAction<typeof GET_ENTITY_URL_WITH_FILTERS_DATA, EntityUrlWithFiltersParams>;
export type GetEntityUrlWithFiltersSuccessAction = AppAction<typeof GET_ENTITY_URL_WITH_FILTERS_SUCESS, IEntityUrlActionReadyPayload>;
export type GetEntityUrlWithFiltersErrorAction = AppAction<typeof GET_ENTITY_URL_WITH_FILTERS_ERROR, IEntityUrlActionError>;

export type EntityUrlWithFiltersAction =
| GetEntityUrlWithFiltersAction
| GetEntityUrlWithFiltersSuccessAction
| GetEntityUrlWithFiltersErrorAction;

export type EntityUrlAction = GetEntityUrlAction | GetEntityUrlSuccessAction | GetEntityUrlErrorAction | EntityUrlWithFiltersAction;

