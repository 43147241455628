import React, { useCallback, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LinkProps } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import _isUndefined from 'lodash-es/isUndefined';
import Panel, { IPanelProps } from '../../../Panel/Panel';
import TileHeaderActions, { DownloadIcon } from '../../../TileHeaderActions/TileHeaderActions';
import { TileContentProps } from '../../../TileContent/typings';
import { adobeAnalytics } from '../../../../core/services/analytics/adobe';
import { getProviderByEntity } from '../../../../core/helpers/analytics';
import { isFeatureEnabled } from '../../../../core/selectors/user.selectors';
import { FEATURE } from '../../../../typings';
import AddRemoveProjectIcon from '../AddRemoveProjectIcon/AddRemoveProjectIcon';

import styles from './DeepDivePanel.module.scss';
import commonStyles from '../../../../scss/common.module.scss';


interface DeepDivePanelProps extends IPanelProps {
    onOpenDialog?: () => void;
    isMultiAction?: boolean;
    seeMoreURLBuilder?: LinkProps['to'];
    onSeeMoreClick?: () => any;
    tileProps?: TileContentProps;
    filters?: any;
    filtersApplied?: boolean;
    isDownloadAvailable?: boolean;
    disableAddToProject?: boolean;
    isImageDisabled?: boolean;
    isExcelDisabled?: boolean;
}

const DeepDivePanel = (props: DeepDivePanelProps) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const toggleIsCollapsed = useCallback(() => setIsCollapsed(!isCollapsed), [setIsCollapsed, isCollapsed]);
    const element = useRef(null);

    const analyticsData = useMemo(
        () => ({
            title: props.title,
            provider: getProviderByEntity(props.tileProps?.entity) || props.tileProps?.entity || '',
        }),
        [props.title, props.tileProps]
    );

    const handleAddToProjectClick = useCallback(() => {
        adobeAnalytics.trackAddToProject({
            componentName: analyticsData.title,
            productName: analyticsData.provider,
        });
        if (typeof props.onOpenDialog !== 'undefined') {
            props.onOpenDialog();
        }
    }, [analyticsData, props.onOpenDialog]);

    const isDownloadsFeatureEnabled = useSelector((state: any) => isFeatureEnabled(state, FEATURE.DOWNLOADS));

    const headerActions = props.isMultiAction ? (
        <TileHeaderActions
            isDataAvailable
            isRemoveAvailable
            isDownloadAvailable={(_isUndefined(props.isDownloadAvailable) || props.isDownloadAvailable) && !isCollapsed}
            deepDiveKey=''
            isCollapsed={isCollapsed}
            onCollapseClick={toggleIsCollapsed}
            onOpenDialog={props.onOpenDialog}
            seeMoreURLBuilder={props.seeMoreURLBuilder}
            onClick={props.onSeeMoreClick}
            tileProps={props.tileProps}
            filters={props.filters}
            exportingElement={element}
            filtersApplied={props.filtersApplied}
            analyticsData={analyticsData}
            isImageDisabled={props.isImageDisabled}
            isExcelDisabled={props.isExcelDisabled}
        />
    ) : (
            <div className={styles.rightIconGroup}>
                {!props.disableAddToProject && (
                    <AddRemoveProjectIcon onClick={handleAddToProjectClick} actionType="add" />
                )}
                {props.tileProps && isDownloadsFeatureEnabled && (_isUndefined(props.isDownloadAvailable) || props.isDownloadAvailable) && (
                    <span className='flex-center'>
                        <DownloadIcon
                            tileProps={props.tileProps}
                            filters={props.filters}
                            exportingElement={element}
                            analyticsData={analyticsData}
                            isImageDisabled={props.isImageDisabled}
                            isExcelDisabled={props.isExcelDisabled}
                        />
                    </span>
                )}
            </div>
        );

    return (
        <Panel {...props} renderHeaderActions={headerActions} disablePaddingBottom={isCollapsed}>
            <Collapse
                in={!isCollapsed}
                classes={{
                    entered: commonStyles.collapseEntered,
                    wrapper: commonStyles.collapseEnteredWrapper,
                }}>
                <div ref={element}>{props.children}</div>
            </Collapse>
        </Panel>
    );
};

export default DeepDivePanel;
