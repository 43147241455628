import { put, call, takeEvery, all, fork, takeLatest } from 'redux-saga/effects';
import { getSubscriptions } from '../services/subscriptions.service';
import * as actions from '../actions/subscriptions.actions';
import * as actionTypes from '../actions/actionTypes/subscriptions.actionTypes';

export function* onGetSubscriptions(): Generator<any, any, any> {
    try {
        const token = localStorage.getItem('token') as string;
        const { data } = yield call(getSubscriptions, token);
        yield put(actions.getSubscriptionsSuccess(data));
    } catch (error) {
        yield put(actions.getSubscriptionsError(error));
    }
}

function* watchOnGetSubscriptions() {
    yield takeLatest(actionTypes.GET_SUBSCRIPTIONS, onGetSubscriptions);
}

export default function* usersSaga() {
    yield all([
        fork(watchOnGetSubscriptions)
    ]);
}
