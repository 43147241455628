import {
	ChartsErrorAction,
	ChartsLoadingAction,
	ChartsLoadedAction,
	ChartsRequestAction,
	DATA_ERROR,
	DATA_READY,
	DATA_REQUEST,
	DATA_LOADING
} from './actionTypes/charts.actionsTypes';
import { ChartPath, PreparedFilterValues } from '../../typings';

export function chartDataRequest(
	pathConfig: ChartPath,
	filters: PreparedFilterValues = []
): ChartsRequestAction {
	return {
		type: DATA_REQUEST,
		payload: {
			pathConfig,
			filters
		}
	};
}

export function chartDataLoading(
	pathConfig: ChartPath
): ChartsLoadingAction {
	return {
		type: DATA_LOADING,
		payload: {
			pathConfig
		}
	};
}

export function chartDataLoaded(pathConfig: ChartPath, data: any): ChartsLoadedAction {
	return {
		type: DATA_READY,
		payload: {
			...pathConfig,
			data
		}
	};
}

export function chartDataError(pathConfig: ChartPath, error: Error | string): ChartsErrorAction {
	return {
		type: DATA_ERROR,
		payload: {
			...pathConfig,
			error
		}
	};
}