import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { PrivateRoute } from './components/AuthComponents/PrivateRoute/PrivateRoute';
import {
	DASHBOARD_ROUTE,
	DEEP_DIVE_ROUTE,
	FORBIDDEN_ROUTE,
	HOME_ROUTE,
	MY_360_PROJECT_VIEW_ROUTE,
	MY_360_ROUTE
} from './constants/routes';
import { appcuesService } from './core/services/analytics/appcues';
import ForbiddenPage from './components/ForbiddenPage/ForbiddenPage';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const Home = lazy(() => import('./components/Home/Home'));
const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const DeepDive = lazy(() => import('./components/DeepDive/DeepDive'));
const ProjectsDashboard = lazy(() => import('./components/ProjectsDashboard/ProjectsDashboard'));
const ProjectView = lazy(() => import('./components/ProjectView/ProjectView'));

export const App = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		appcuesService.restart();
	}, [pathname]);

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Switch>
				<PrivateRoute exact path={HOME_ROUTE} component={Home} />
				<PrivateRoute exact path={MY_360_ROUTE} component={ProjectsDashboard} />
				<PrivateRoute exact path={MY_360_PROJECT_VIEW_ROUTE} component={ProjectView} />
				<PrivateRoute exact path={DASHBOARD_ROUTE} component={Dashboard} />
				<PrivateRoute exact path={DEEP_DIVE_ROUTE} component={DeepDive} />
				<Route path={FORBIDDEN_ROUTE} component={ForbiddenPage}/>
			</Switch>
		</Suspense>
	);
};

export default withAuthenticationRequired(App);
