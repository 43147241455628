import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import { ChartRendererComponentProps } from '../ChartContainer/ChartContainer';

export default ({ componentProps, children }: PropsWithChildren<{ componentProps?: ChartRendererComponentProps }>) => (
    <Box
        display='flex'
        flexDirection={componentProps?.labelPosition === 'bottom' ? 'column-reverse' : 'column'}
    >
        {componentProps && <h4 style={{ textAlign: componentProps?.labelAlignment || 'left' }}>{componentProps.label}</h4>}
        {children}
    </Box>
);