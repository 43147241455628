import {
  identityToUse,
  ENVIRONMENTS,
  apiToUse,
  getStage,
  environment,
} from "../services/enviroment";
import { EntityType } from "../../typings";

const isSSOAuthentificator = process.env.REACT_APP_IS_OATH === "true";
const versionOfPoint = isSSOAuthentificator ? "v2" : "v1";

export const SSO_AUTH_PARAMS = {
  [ENVIRONMENTS.LOCAL]: {
    domain: "https://auth.uat.nxp.norstella-labs.com",
    clientId: "r3f2SCSRSSwfP7QYSudNGvWTqY4t1YCC",
    profileURI: "https://sso.uat.nxp.norstella-labs.com",
  },
  [ENVIRONMENTS.DEVELOPMENT]: {
    domain: "https://auth.uat.nxp.norstella-labs.com",
    clientId: "r3f2SCSRSSwfP7QYSudNGvWTqY4t1YCC",
    profileURI: "https://sso.uat.nxp.norstella-labs.com",
  },
  [ENVIRONMENTS.TEST]: {
    domain: "https://auth.uat.nxp.norstella-labs.com",
    clientId: "r3f2SCSRSSwfP7QYSudNGvWTqY4t1YCC",
    profileURI: "https://sso.uat.nxp.norstella-labs.com",
  },
  [ENVIRONMENTS.PRODUCTION]: {
    domain: "https://auth.nxp.norstella.com",
    clientId: "YVk3rDjCirwqcYXuMvrYcxYq76e1xyZG",
    profileURI: "https://sso.nxp.norstella.com",
  },
}[environment()];

const AUTHORIZER_URLS = {
  [ENVIRONMENTS.LOCAL]: "http://localhost:5000/v1/auth",
  [ENVIRONMENTS.DEVELOPMENT]:
    "https://pharma-authorize-service.dev.p360.pharma.informa-labs.com/v1/auth",
  [ENVIRONMENTS.TEST]:
    "https://pharma-authorize-service.test.p360.pharma.informa-labs.com/v1/auth",
  [ENVIRONMENTS.PRODUCTION]:
    "https://pharma-authorize-service.pharma.intelligence.informa.com/v1/auth",
};

const IDENTITY_URLS = {
  [ENVIRONMENTS.LOCAL]:
    "https://uat2-informabi.cs126.force.com/pharma/services/apexrest/identity/pharma360/",
  [ENVIRONMENTS.DEVELOPMENT]:
    "https://informabi--uat.sandbox.my.site.com/pharma/services/apexrest/identity/pharma360/",
  [ENVIRONMENTS.TEST]:
    "https://uat-informabi.cs110.force.com/pharma/services/apexrest/identity/pharma360/",
  [ENVIRONMENTS.PRODUCTION]:
    "https://pharma.id.informa.com/services/apexrest/identity/pharma360/",
};

const USER_API_URLS = {
  [ENVIRONMENTS.LOCAL]: "http://localhost:5005/dev",
  [ENVIRONMENTS.DEVELOPMENT]:
    "https://user-api-service.dev.pi.citeline-labs.com",
  [ENVIRONMENTS.TEST]: "https://user-api-service.test.pi.citeline-labs.com",
  [ENVIRONMENTS.PRODUCTION]: "https://user-api-service.pi.citeline-labs.com",
};
const SEMANTIC_LAYER_API_URLS = {
  [ENVIRONMENTS.LOCAL]: "http://localhost:5002/dev",
  [ENVIRONMENTS.DEVELOPMENT]:
    "https://semanticdatalayer.dev.p360.citeline-labs.com",
  [ENVIRONMENTS.TEST]: "https://semanticdatalayer.test.p360.citeline-labs.com",
  [ENVIRONMENTS.PRODUCTION]: "https://semanticdatalayer.p360.citeline-labs.com",
};

const PHARMA360_API_URLS = {
  [ENVIRONMENTS.LOCAL]: "http://localhost:4001/dev/v1/api",
  [ENVIRONMENTS.DEVELOPMENT]:
    "https://pharma360-api.dev.p360.citeline-labs.com/v1/api",
  [ENVIRONMENTS.TEST]:
    "https://pharma360-api.test.p360.citeline-labs.com/v1/api",
  [ENVIRONMENTS.PRODUCTION]:
    "https://pharma360-api.p360.citeline-labs.com/v1/api",
};

const PHARMA_USER_PREFERENCES_API_URLS = {
  [ENVIRONMENTS.LOCAL]: `http://localhost:4001/dev/${versionOfPoint}/api`,
  [ENVIRONMENTS.DEVELOPMENT]:
    `https://preferences-api.dev.pi.citeline-labs.com/${versionOfPoint}/api`,
  [ENVIRONMENTS.TEST]:
    `https://preferences-api.test.pi.citeline-labs.com/${versionOfPoint}/api`,
  [ENVIRONMENTS.PRODUCTION]:
    `https://preferences-api.pi.citeline-labs.com/${versionOfPoint}/api`,
};

const clientId = process.env.REACT_APP_PROJECT_KEY || "p360d";
const identityStage = identityToUse();
export const apiStage = apiToUse();
const preferencesStage = getStage(process.env.REACT_APP_API_PREFERENCES_STAGE);
const pharma360ApiStage = getStage(process.env.REACT_APP_PHARMA_360_STAGE);
const isProdEnv: boolean = apiStage === "prod";

export const PRODUCT_URLS = {
  citeline: isProdEnv
    ? "https://clinicalintelligence.citeline.com"
    : `https://citeline.${apiStage}.citeline-fos.informa-labs.com`,
  datamonitorhealthcare: isProdEnv
    ? "https://service.datamonitorhealthcare.com"
    : "https://stage.datamonitorhealthcare.com",
  biomedtracker: isProdEnv
    ? "https://www.biomedtracker.com"
    : `https://${apiStage}.biomedtracker.com`,
};

function getRootWebEndpoint(): string {
  const env = process.env.NODE_ENV;

  if (env === ENVIRONMENTS.DEVELOPMENT || env === ENVIRONMENTS.TEST) {
    return "http://localhost:3000";
  }

  return window.location.origin;
}

function getAuthorizerEndpoint(): string {
  return AUTHORIZER_URLS[identityStage];
}

function getLogOutEndpoint() {
  if (identityStage === ENVIRONMENTS.PRODUCTION) {
    return IDENTITY_URLS[ENVIRONMENTS.PRODUCTION];
  }

  if (
    identityStage === ENVIRONMENTS.LOCAL ||
    identityStage === ENVIRONMENTS.DEVELOPMENT ||
    identityStage === ENVIRONMENTS.TEST
  ) {
    return IDENTITY_URLS[ENVIRONMENTS.DEVELOPMENT];
  }

  return IDENTITY_URLS[ENVIRONMENTS.DEVELOPMENT];
}

function getUserApiEndPoint(): string {
  return USER_API_URLS[apiStage];
}

function getSemanticDataLayerEndpoint(): string {
  return SEMANTIC_LAYER_API_URLS[apiStage];
}

const getPharma360ApiEndpoint = () => {
  return PHARMA360_API_URLS[pharma360ApiStage];
};

const getPharmaUserPreferencesApiEndpoint = () => {
  return PHARMA_USER_PREFERENCES_API_URLS[preferencesStage];
};

export const endpoints = {
  semanticDataLayer() {
    return `${getSemanticDataLayerEndpoint()}/api/v1`;
  },
  identity() {
    return `${getAuthorizerEndpoint()}/${clientId}`;
  },
  getSubscriptions() {
    return `${getUserApiEndPoint()}/${versionOfPoint}/api/entitlements/project/${clientId}`;
  },
  getUserFeatures(parentServiceId: string, salesForceId: string) {
    return `${getUserApiEndPoint()}/v1/api/projectCode/${clientId}/company/${parentServiceId}/user/${salesForceId}/features`;
  },
  createUser(parentServiceId: string) {
    return `${getUserApiEndPoint()}/v1/api/projectCode/${clientId}/parentServiceId/${parentServiceId}/user`;
  },
  logout() {
    return `${getLogOutEndpoint()}${clientId}/logout?referralurl=${getRootWebEndpoint()}`;
  },
  projectsPaged() {
    return `${getPharma360ApiEndpoint()}/projects`;
  },
  projects() {
    return `${getPharma360ApiEndpoint()}/projects`;
  },
  project(projectId?: string | number) {
    if (projectId) {
      return `${getPharma360ApiEndpoint()}/project/${projectId}`;
    }

    return `${getPharma360ApiEndpoint()}/project`;
  },
  saveSearch() {
    return `${getPharma360ApiEndpoint()}/save-search/`;
  },
  removeSearches() {
    return `${getPharma360ApiEndpoint()}/remove-searches/`;
  },
  savePreferences() {
    return `${getPharmaUserPreferencesApiEndpoint()}/save-preferences`;
  },
  getOrRemovePreferences() {
    return `${getPharmaUserPreferencesApiEndpoint()}/preferences`;
  },
  feedbacks() {
    return `${getUserApiEndPoint()}/${versionOfPoint}/api/feedbacks/project/${clientId}`;
  },
  pinUnpinProject(projectId, isPinned) {
    return `${getPharma360ApiEndpoint()}/project/${projectId}/pin/${isPinned}`;
  },
  getPinnedProjects() {
    return `${getPharma360ApiEndpoint()}/projects/pinned`;
  },
  saveRowToProject(projectId: string) {
    return `${getPharma360ApiEndpoint()}/project/${projectId}/row`;
  },
  deleteTableRowFromProject(projectId: string, rowId: string) {
    return `${getPharma360ApiEndpoint()}/project/${projectId}/row/${rowId}`;
  },
  getSavedTableRows(projectId: string, entityType?: EntityType) {
    const url = `${getPharma360ApiEndpoint()}/project/${projectId}/rows`;

    if (!entityType) {
      return url;
    }

    return `${url}/${entityType}`;
  },
};

export default endpoints;
