import React, { memo } from 'react';
import { ChartCommonProps, ChartRendererProps } from '../ChartContainer/ChartContainer';
import { PharmaLineChart } from '@informa/pharma-common-react-components';
import { ChartContainer } from '../ChartContainer';
import { formatCurrency } from '../../../core/helpers/number';

const LineChartRenderer = ({ data, componentProps }: ChartRendererProps) => {
    const getTooltip = (point) => {
        const value = formatCurrency(point?.options?.y);
        return `<b>${point?.series?.name}</b><br/>${point?.category?.name}: ${value}<br/>`;
    };

    const yAxisLabelFormatter = (value) => {
        return formatCurrency(value);
    };

    const titleXAxis = componentProps?.titleXAxis || '';
    const titleYAxis = componentProps?.titleYAxis || '';

    return (
        <PharmaLineChart
            data={data}
            toolTipFormatter={getTooltip}
            titleXAxis={titleXAxis}
            titleYAxis={titleYAxis}
            yAxisLabelFormatter={yAxisLabelFormatter}
            onClick={componentProps?.onClick}
            onLabelClick={componentProps?.onLabelClick}
        />
    );
};

const LineChart = (props: ChartCommonProps) => <ChartContainer {...props} component={LineChartRenderer} />;

export default memo(LineChart);
