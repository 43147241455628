import React, { useMemo, memo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CounterChart } from '../../Charts/CounterChart';
import { StackedBarChart } from '../../Charts/StackedBarChart';
import { TableChart } from '../../Charts/TableChart';
import { ChartsBatchRequestPath } from '../../../core/actions/actionTypes/charts.actionsTypes';
import { DEEP_DIVE_REDUX_KEY } from '../../../constants/deepDiveConfig';
import { CHART_TYPES } from '../../../constants/common';
import DeepDivePanel from '../Shared/DeepDivePanel/DeepDivePanel';
import { createChartClickHandlerFactory, createChartLabelClickHandlerFactory } from '../../../core/helpers/filter';
import { DeepDiveContentProps } from '../typings';
import { StackedColumnChart } from '../../Charts/StackedColumnChart';
import { LinkChart } from '../../Charts/LinkChart';
import { GetDatamonitorData } from '../Shared/ReportDeepDive/ReportDeepDiveHelper';
import AddRemoveProjectIcon from '../Shared/AddRemoveProjectIcon/AddRemoveProjectIcon';

import styles from '../DeepDiveContent.module.scss';

const chartPaperProps = {
    className: styles.deepDiveChartsContainerItem,
    elevation: 0,
};

export default memo((props: DeepDiveContentProps) => {
    const chartPathProps = useMemo<ChartsBatchRequestPath>(
        () => ({
            ...props,
            subPath: DEEP_DIVE_REDUX_KEY,
        }),
        [props]
    );
    const createChartClickHandler = createChartClickHandlerFactory(props.onClick);
    const createChartLabelClickHandler = createChartLabelClickHandlerFactory(props.onClick);
    const dataMonitorData: any = GetDatamonitorData(props);

    const Action = (rowProps) => <AddRemoveProjectIcon actionType="add" onClick={() => props.onOpenDialog(undefined, rowProps.rowData.id)} />

    return (
        <Grid container spacing={3}>
            <Grid container item xs={4} className={styles.deepDiveReportBlock}>
                <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                    <Paper {...chartPaperProps}>
                        <CounterChart
                            pathConfig={{
                                ...chartPathProps,
                                chart: CHART_TYPES.COUNT,
                            }}
                            componentProps={{
                                label: 'Total Reports',
                                inverted: true,
                            }}
                            filters={props.filters}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <DeepDivePanel
                        {...chartPaperProps}
                        title='Datamonitor Healthcare Analysis'
                        wrapperClasses={styles.dataMonitorData}
                        titleVariant='h6'
                        disableElevation
                        disableAddToProject={true}
                        isMultiAction={false}>
                        {dataMonitorData.data?.url && <LinkChart {...dataMonitorData.data} />}
                    </DeepDivePanel>
                </Grid>
            </Grid>
            <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='PDF Content Type'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => props.onOpenDialog(CHART_TYPES.CONTENT_TYPE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.CONTENT_TYPE,
                    }}
                    filters={props.filters}>
                    <StackedBarChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.CONTENT_TYPE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.CONTENT_TYPE),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.CONTENT_TYPE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Reports Timeline'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => props.onOpenDialog(CHART_TYPES.TIMELINE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TIMELINE,
                    }}
                    filters={props.filters}>
                    <StackedColumnChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TIMELINE,
                        }}
                        componentProps={{
                            showLabels: true,
                            onClick: createChartClickHandler(CHART_TYPES.TIMELINE),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.TIMELINE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='PDF Report Library'
                    isDownloadAvailable
                    isImageDisabled
                    disableElevation
                    onOpenDialog={() => props.onOpenDialog(CHART_TYPES.TABLE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TABLE,
                    }}
                    filters={props.filters}>
                    <TableChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TABLE,
                        }}
                        componentProps={{
                            filters: props.filters,
                        }}
                        action={Action}
                    />
                </DeepDivePanel>
            </Grid>
        </Grid>
    );
});
