import React, { FunctionComponent, useState, useEffect } from 'react';
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import { createStyles, makeStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs';
import { downloadExcel, checkStatus, startDownload } from '../../core/services/download.service';
import { TileContentProps } from '../TileContent/typings';
import { AppDialog } from '../AppDialog';
import ErrorText from '../ErrorText/ErrorText'
import { debounce } from 'throttle-debounce';
import { PROGRESS_STATUS } from '../../core/api/responses.interface';
import { setNotification } from '../../core/actions/notifications.actions';
import { useDispatch } from 'react-redux';
import { Theme } from '@mui/material/styles/createTheme';

export interface IDownloadOptionsProps {
    showDialog: boolean;
    handleClose: any;
    handleDownloadStart: () => void;
    handleDownloadFinish: () => void;
    tileProps?: TileContentProps;
    filters?: any;
    element: any;
    searches?: any;
    imageDisabled?: boolean,
    excelDisabled?: boolean
}

const DOWNLOAD_FILE_ERROR = 'Failed to download file';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: '8px',
            '&:first-of-type': {
                marginLeft: 0
            }
        }
    })
);

const DownloadOptionsDialog: FunctionComponent<IDownloadOptionsProps> = (
    props
) => {
    const [showError, setShowError] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [state, setState] = useState({
        pngType: false,
        excelType: false,
    });

    const [downloadState, setDownloadState] = useState({
        isDownloading: false,
        png: false,
        excel: false,
    });

    useEffect(() => {
        const { isDownloading, png, excel } = downloadState;
        if (isDownloading && !png && !excel) {
            props.handleDownloadFinish();
            setDownloadState((prevState) => ({
                ...prevState,
                isDownloading: false
            }))
        }
    }, [downloadState])

    const classes = useStyles();
    const dispatch = useDispatch();

    const checkDownloadStatus = debounce(2000, async (requestId: string) => {
        try {
            const response = await checkStatus(requestId);
            const { status, url } = response.data;
            switch (status) {
                case PROGRESS_STATUS.IN_PROGRESS: {
                    checkDownloadStatus(requestId);
                    break;
                }
                case PROGRESS_STATUS.FAILED: {
                    setDownloadState((prevState) => ({
                        ...prevState,
                        excel: false
                    }));
                    dispatch(setNotification(DOWNLOAD_FILE_ERROR))
                    break;
                }
                case PROGRESS_STATUS.COMPLETED: {
                    setDownloadState((prevState) => ({
                        ...prevState,
                        excel: false
                    }));
                    if (url) {
                        downloadFile(url, requestId);
                    }
                    break;
                }
            }
        } catch (error) {
            dispatch(setNotification(DOWNLOAD_FILE_ERROR))
        }
    });

    const downloadFile = (url: string, requestId: string) => {
        downloadExcel(url)
            .then((data) => {
                const excelFileName = requestId || 'file';
                download(new Blob([data]), `${excelFileName}.xlsx`, 'application/xlsx');
            })
            .catch((error) => {
                dispatch(setNotification(DOWNLOAD_FILE_ERROR))
            })
    }

    const handleClose = () => {
        setShowError(false);
        setState({ pngType: false, excelType: false });
        props.handleClose();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        setShowError(false)
    };

    const fileName = () => {
        if (props?.searches) {
            return `project ${props?.searches[0].searchType}-${props?.searches[0].entityType}-${props?.searches[0].searchId}`;
        }
        if (props && props.tileProps) {
            return `${props.tileProps.search}-${props.tileProps.entity}-${props?.tileProps.value}-${props.tileProps.chart}`;
        }
        return 'file';
    };

    const filterTables = (domNode) => {
        return !(domNode.attributes?.getNamedItem('custom-attribute') && domNode.attributes?.getNamedItem('custom-attribute').value === 'table');
    }

    const handleDownload = () => {
        if (!state.pngType && !state.excelType) {
            setShowError(true);
            return;
        }
        setShowError(false);
        props.handleDownloadStart();
        setDisabled(true);
        setDownloadState({
            isDownloading: true,
            png: state.pngType,
            excel: state.excelType
        });
        if (state.pngType) {
            let element: HTMLElement = props.element.current;

            if (props.tileProps?.chart !== 'tile') {
                element = element.parentElement as HTMLElement;
            }

            htmlToImage
                .toPng(element, {
                    backgroundColor: 'white',
                    filter: filterTables
                })
                .then((data) => {
                    download(data, `${fileName()}.png`, 'image/png');
                    handleClose();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setDisabled(false);
                    setDownloadState((prevState) => ({
                        ...prevState,
                        png: false
                    }));
                });
        }

        if (state.excelType) {
            startDownload({
                ...props.tileProps,
                entity: props.tileProps?.entity,
                filters: props.filters,
                searches: props.searches
            })
                .then((response) => {
                    checkDownloadStatus(response.data.requestId);
                    handleClose();
                })
                .catch((error) => {
                    dispatch(setNotification(DOWNLOAD_FILE_ERROR));
                    setDownloadState((prevState) => ({
                        ...prevState,
                        excel: false
                    }));
                })
                .finally(() => {
                    setDisabled(false);
                })
        }
    };

    return (
        <div>
            <AppDialog
                visible={props.showDialog}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                title="Download Options"
                fullWidth={true}
                maxWidth={'xs'}
            >
                {showError && <ErrorText text="Select a file format" />}
                {!props.imageDisabled && <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.pngType}
                                onChange={handleChange}
                                name="pngType"
                                color="primary"
                            />
                        }
                        label="Image (.png)"
                    />
                </div>}
                {!props.excelDisabled && <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.excelType}
                                onChange={handleChange}
                                name="excelType"
                                color="primary"
                            />
                        }
                        label="Excel (.xlsx)"
                    />
                </div>}
                <Button
                    disabled={disabled}
                    variant="contained"
                    color="primary"
                    onClick={handleDownload}
                    className={classes.button}
                    startIcon={<VerticalAlignBottomOutlinedIcon />}
                >
                    Download
                </Button>
                <Button
                    className={classes.button}
                    onClick={handleClose}
                    color="primary"
                >
                    Cancel
                </Button>
            </AppDialog>
        </div>
    );
};

export default DownloadOptionsDialog;
