import React, { FunctionComponent, memo } from 'react';
import Grid from '@mui/material/Grid';
import TileContentWrapper from './TileContentWrapper/TileContentWrapper';
import { TileContentProps } from './typings';
import { EntityType } from '../../typings';
import { DoughnutChart } from '../Charts/DoughnutChart';
import { CHART_TYPES } from '../../constants/common';
import { CounterChart } from '../Charts/CounterChart';
import BarChart from '../Charts/BarChart/BarChart';
import { getCurrentYear } from '../../core/helpers/dates';
import InnerTitle from '../Charts/InnerTitle/InnerTitle';

const currentYear = getCurrentYear();
const salesForecastLabel = ({ data }) => data.value === null ? '' : `${data.forecastyear} sales`;

const diseaseScopeLabel = ({ data }) => {
    let source: string | undefined = undefined;
    const label = 'Forecast disease scope:';

    if (data && data.length) {
        source = data[0]?.source?.toLowerCase() === 'pbf' ? 'Patient Based' : undefined;
    }

    const finalLabel = [source, label].filter(Boolean).join(' ');

    return finalLabel;
}

const Forecast: FunctionComponent<TileContentProps> = (props) => {
    const pathConfig = {
        ...props,
        entity: 'forecast' as EntityType
    }
    return (
        <TileContentWrapper testId='forecast-tile-content'>
            <Grid item xs={12}>
                <InnerTitle
                    pathConfig={{
                        ...pathConfig,
                        chart: CHART_TYPES.DISEASE_SCOPE
                    }}
                    labelBuilder={diseaseScopeLabel} />
            </Grid>
            <Grid item xs={12} md={2}>
                <Grid
                    container
                    direction='column'
                    sx={{justifyContent: 'space-between'}}
                    classes={{
                        container: 'summaryGridContainer'
                    }}>
                    <div>
                        <CounterChart
                            pathConfig={{
                                ...pathConfig,
                                chart: CHART_TYPES.TOTAL_SALES_CURRENT_YEAR,
                            }}
                            componentProps={{
                                label: `${currentYear} sales`,
                            }}
                            isCurrency
                        />
                    </div>
                    <div>
                        <CounterChart
                            pathConfig={{
                                ...pathConfig,
                                chart: CHART_TYPES.TOTAL_SALES_FORECAST_YEAR,
                            }}
                            labelBuilder={salesForecastLabel}
                            isCurrency
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
                <DoughnutChart
                    pathConfig={{
                        ...pathConfig,
                        chart: CHART_TYPES.TOTAL_REVENUE_PER_REGION_CURRENT_YEAR,
                    }}
                    componentProps={{
                        label: `Revenue by Region, ${currentYear}`,
                        options: {
                            chartOptions: {
                                title: {
                                    align: "center",
                                },
                            }
                        }
                    }}
                    isCurrency
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <BarChart
                    pathConfig={{
                        ...pathConfig,
                        chart: CHART_TYPES.TOP_PRODUCTS_CURRENT_AND_FORECAST_YEAR,
                    }}
                    componentProps={{
                        label: 'Top 5 products, by revenue',
                    }}
                />
            </Grid>
        </TileContentWrapper>
    )
};

export default memo(Forecast);