import { api } from '../api';
import { ChartPath, PreparedFilterValues, EntityType } from '../../typings';

export async function loadChartData(pathParams: ChartPath, filters: PreparedFilterValues = [], body: any = null) {
	return await api.charts.fetchChartData(pathParams, filters, body);
}

export async function loadTableData(pathParams: ChartPath | Partial<ChartPath>, filters: PreparedFilterValues = [], body: any = null, isProjectFolder?: boolean) {
	if (!isProjectFolder) {
		return await api.charts.fetchChartData(pathParams as ChartPath, filters, body);
	}

	return await api.projectTable.getProjectTableData(pathParams.entity as EntityType, filters, body);
}