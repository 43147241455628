import React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { CookiePolicyBar } from '@informa/pharma-common-react-components';
import SnackBarNotification from '../SnackBarNotification/SnackBarNotification';
import { useSSOAuthenticator } from '../../hooks/useSSOAuthenticator';
import ProgressBar from '../ProgressBar/ProgressBar';

export interface IBaseLayoutProps {
    children: React.ReactNode;
    location: any;
}

export default function BaseLayout(props: IBaseLayoutProps) {
        const showSearchBox = props.location?.pathname !== '/';
        const { isLoading } = useSSOAuthenticator();

        return (
            <React.Fragment>
                <AppBar position='fixed' elevation={0}>
                    <CookiePolicyBar />
                    <Header showSearchBox={showSearchBox} />
                </AppBar>
                <Grid
                    container
                    direction='row'
                    className='content'
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    data-testid='main-container'
                >
                    {isLoading ? <ProgressBar/> : props.children}
                </Grid>
                <SnackBarNotification />
                <Footer />
            </React.Fragment>
        );
    }
