import * as actionTypes from '../actions/actionTypes/user.actionTypes';

export interface IUserState {
    user?: any;
    error?: Error | string;
}

const initialState: IUserState = { user: {}, error: undefined };

export default function user(state: IUserState = initialState, action: actionTypes.UserAction): IUserState {
    switch (action.type) {
        case actionTypes.USER_SET:
            return {
                ...state,
                user: action.payload
            };
        default:
            return state;
    }
};
