import * as actionTypes from '../actions/actionTypes/charts.actionsTypes';
import { ChartsAction } from '../actions/actionTypes/charts.actionsTypes';
import { buildChartPath } from '../helpers/common';
import { EMPTY_SELECTOR_RESULT } from '../selectors/charts.selectors';

interface ChartData {
	[key: string]: any;
}

export interface ChartEntry {
	isLoading: boolean;
	error: string | null;
	data: ChartData | null;
}

/**
 * chartPath should be a string constructed as follows:
 * `${search of SearchType}/${entity of EntityType}/${chart of ChartType}`.
 * It allows as to index every loaded chart at O(1) if it exists
 */
type ChartsCollection = {
	[chartPath: string]: ChartEntry
} | null;

export interface ChartsState {
	data: ChartsCollection;
}

const chartsInitialState: ChartsState = {
	data: null
};

export default function charts(state = chartsInitialState, action: ChartsAction): ChartsState {
	switch (action.type) {
		case actionTypes.DATA_REQUEST: {
			const chartPath = buildChartPath(action.payload.pathConfig);

			return {
				data: {
					...state.data,
					[chartPath]: {...EMPTY_SELECTOR_RESULT}
				}
			}
		}

		case actionTypes.DATA_LOADING: {
			const chartPath = buildChartPath(action.payload.pathConfig);

			return {
				data: {
					...state.data,
					[chartPath]: {
						isLoading: true,
						data: null,
						error: null
					}
				}
			}
		}

		case actionTypes.DATA_READY: {
			const chartPath = buildChartPath(action.payload);

			return {
				data: {
					...state.data,
					[chartPath]: {
						isLoading: false,
						error: null,
						data: action.payload.data
					}
				}
			}
		}

		case actionTypes.DATA_ERROR: {
			const chartPath = buildChartPath(action.payload);

			return {
				data: {
					...state.data,
					[chartPath]: {
						isLoading: false,
						error: action.payload.error,
						data: null
					}
				}
			}
		}

		default:
			return state;
	}
}