import _difference from 'lodash-es/difference';
import { getUser } from '../auth.service';
import { environment, ENVIRONMENTS } from '../enviroment';
import { ADOBE_ANALYTICS_CONFIGURATION, UNSPECIFIED } from '../../../constants/analytics';

declare global {
    interface Window {
        s_gi: (...args: any) => any;
        Visitor: {
            getInstance: (id: string) => any;
        } | undefined;
    }
}

const variablesToTrackIndexes = [1, 7, 8, 13, 18, 20, 21, 22, 30, 34, 40, 41, 45, 46, 47, 48, 49, 53, 61, 64];
const significantVariables = [8, 13, 64, 20, 40, 41, 45, 46, 47, 48, 49];
const eventsToTrackIndexes = [1, 2, 3, 5, 6, 17, 21, 31, 39, 40, 41, 43];

const instantiateAppMeasurement = () => {
    const { s_gi, Visitor } = window;
    const { ACCOUNT, TRACKING_SERVER, TRACKING_SERVER_SECURE, VISITOR_NAMESPACE, VISITOR_INSTANCE_ID } =
        ADOBE_ANALYTICS_CONFIGURATION[environment()];

    if (s_gi && Visitor) {
        const s = s_gi(ACCOUNT);
        s.trackingServer = TRACKING_SERVER;
        s.trackingServerSecure = TRACKING_SERVER_SECURE;
        s.visitorNamespace = VISITOR_NAMESPACE;
        s.visitor = Visitor.getInstance(VISITOR_INSTANCE_ID);
        s.trackDownloadLinks = true;
        s.trackExternalLinks = false;
        s.trackInlineStats = true;
        s.linkLeaveQueryString = false;
        s.linkTrackVars = variablesToTrackIndexes.map((idx) => `eVar${idx}`).concat('events').join(',');
        s.linkTrackEvents = eventsToTrackIndexes.map((idx) => `event${idx}`).join(',');
        s.pageName = document.title;
        s.eVar8 = document.title;
        s.events = 'event4';
        s.eVar30 = undefined;

        return s;
    }
}

const makeEvent = (trackingFn: Function) => (customParameters?: any) => {
    const user = getUser() || {};
    const { 
        organizationName, salesForceId, email, customAttributes,
         ideServiceId, entitlementType, parentServiceId
        } = user;
    const appMeasurementInstance = instantiateAppMeasurement();
    appMeasurementInstance.eVar13 = organizationName;
    appMeasurementInstance.eVar64 = email;
    appMeasurementInstance.eVar20 = entitlementType;
    appMeasurementInstance.eVar40 = ideServiceId ?? parentServiceId;
    appMeasurementInstance.eVar41 = salesForceId;

    if (environment() === ENVIRONMENTS.PRODUCTION) {
        appMeasurementInstance.eVar45 = customAttributes?.contact_clientlocation;
        appMeasurementInstance.eVar46 = customAttributes?.contact_clientdepartment;
        appMeasurementInstance.eVar47 = customAttributes?.contact_clientjobrole;
        appMeasurementInstance.eVar48 = customAttributes?.contact_clientattribute1;
        appMeasurementInstance.eVar49 = customAttributes?.contact_clientattribute2;
    }

    _difference(variablesToTrackIndexes, significantVariables).forEach((variableIndex) => {
        delete appMeasurementInstance[`eVar${variableIndex}`];
    });

    const customInstanceParams = trackingFn(customParameters);

    Object.keys(customInstanceParams.variables || {}).forEach((paramKey) => {
        const paramValue = customInstanceParams.variables[paramKey];
        appMeasurementInstance[paramKey] = paramValue && paramValue.length ?
            paramValue :
            UNSPECIFIED;
    });

    appMeasurementInstance.events = customInstanceParams.event || undefined;
    appMeasurementInstance.t();
};

const trackPageView = makeEvent(() => ({}));

const trackTriggerSearchTerm = makeEvent(({ searchTerm }) => ({
    event: 'event1',
    variables: {
        eVar7: searchTerm
    }
}));

const trackViewMoreLinkClick = makeEvent(({ productName, componentName }) => ({
    event: 'event2',
    variables: {
        eVar61: productName,
        eVar1: componentName
    }
}));

const trackApplyFilters = makeEvent(({ serializedFilters }) => ({
    event: 'event3',
    variables: {
        eVar30: serializedFilters
    }
}));

const trackAddToProject = makeEvent(({ productName, componentName }) => ({
    event: 'event5',
    variables: {
        eVar61: productName,
        eVar1: componentName
    }
}));

const trackRemoveFromProject = makeEvent(({ productName, componentName }) => ({
    event: 'event6',
    variables: {
        eVar1: componentName,
        eVar61: productName
    }
}));

const trackClickHelp = makeEvent(() => ({
    event: 'event17'
}));

const trackClickSidebar = makeEvent(() => ({
    event: 'event21'
}));

const trackDownloadChart = makeEvent(({ componentName, productName }) => ({
    event: 'event31',
    variables: {
        eVar1: componentName,
        eVar61: productName
    }
}));

const trackClickProductSite = makeEvent(({ productName }) => ({
    event: 'event39',
    variables: {
        eVar34: productName
    }
}));

const trackClickProductLogo = makeEvent(({ productName }) => ({
    event: 'event40',
    variables: {
        eVar34: productName
    }
}));

// Still no Ask the analyst form, so not applying it anywhere
const trackAskTheAnalyst = makeEvent(() => ({
    event: 'event41'
}));

const trackReportLinkClick = makeEvent(({ title, productName, destination }) => ({
    event: 'event43',
    variables: {
        eVar18: title,
        eVar21: productName,
        eVar22: destination
    }
}));

export const adobeAnalytics = {
    instantiateAppMeasurement,
    trackTriggerSearchTerm,
    trackViewMoreLinkClick,
    trackReportLinkClick,
    trackApplyFilters,
    trackAddToProject,
    trackRemoveFromProject,
    trackDownloadChart,
    trackClickHelp,
    trackClickSidebar,
    trackClickProductSite,
    trackClickProductLogo,
    trackAskTheAnalyst,
    trackPageView
};
