import React, { FunctionComponent, memo } from 'react';
import Grid from '@mui/material/Grid';
import { CounterChart } from '../../Charts/CounterChart';
import { DealsSummaryCharts } from '../../Charts/DealsSummaryCharts';
import { ColumnChartWithLine } from '../../Charts/ColumnChartWithLine';
import { StackedBarChart } from '../../Charts/StackedBarChart';
import { TileContentProps } from '../typings';
import { EntityType } from '../../../typings';
import TileContentWrapper from '../TileContentWrapper/TileContentWrapper';

interface IDealOptions {
    size: number;
    chart: string;
    label: string;
}

const Deals: FunctionComponent<TileContentProps> = (props) => {
    const pathConfig = {
        ...props,
        entity: 'deals' as EntityType,
    };

    const options: any = new Map<string, IDealOptions>();
    options.set('company', { size: 4, chart: 'topdiseases', label: 'Alliances: top 5 diseases' });
    options.set('drug', { size: 5, chart: 'topcompanies', label: 'Top 5 companies involved in deals' });
    options.set('disease', { size: 5, chart: 'topcompanies', label: 'Top 5 companies involved in deals' });
    const option: any = options.get(props.search);
    return (
        <TileContentWrapper testId='deals-tile-content'>
            <Grid item xs={12} md={2}>
                <CounterChart
                    pathConfig={{
                        ...pathConfig,
                        chart: 'count',
                    }}
                    componentProps={{
                        label: 'Total deals',
                    }}
                />
            </Grid>

            <Grid item xs={12} md={option.size}>
                <ColumnChartWithLine
                    pathConfig={{
                        ...pathConfig,
                        chart: 'totalvalue',
                    }}
                />
            </Grid>

            {props.search === 'company' && (
                <Grid item xs={12} md={2}>
                    <DealsSummaryCharts {...pathConfig} />
                </Grid>
            )}

            <Grid item xs={12} md={option.size}>
                <StackedBarChart
                    pathConfig={{
                        ...pathConfig,
                        chart: option.chart,
                    }}
                    componentProps={{
                        label: option.label,
                        options: {
                            chartOptions: {
                                // fixes issue with datalabel overlapping the horizontal bar
                                chart: {
                                    spacing: [0, 40, 0, 0]
                                },
                                yAxis: {
                                    stackLabels: {
                                        crop: false,
                                        overflow: 'allow',
                                    }
                                }
                            }
                        }
                    }}
                    isCurrency={props.search === 'company'}
                />
            </Grid>
        </TileContentWrapper>
    );
};

export default memo(Deals);
