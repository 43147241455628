import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createChartDataSelector } from '../core/selectors/charts.selectors';
import { chartDataRequest } from '../core/actions/charts.actions';
import { ChartPath, ChartType, RawFilterValues } from '../typings';
import { prepareFilters } from '../core/helpers/filter';
import { FiltersBuilder } from '../components/Charts/ChartContainer/ChartContainer';
import _isFunction from 'lodash-es/isFunction';
import useChartDependencies from './useChartDependencies';
import { CHART_TYPES } from '../constants/common';

interface UsePharmaChart {
	isLoading: boolean;
	isEveryDependencyReady: boolean;
	dependenciesData: any;
	data: any;
	error: any;
	loadData: () => void;
}

export default (
	pathConfig: ChartPath,
	dependencies: ChartType[] = [],
	filters?: RawFilterValues,
	filtersBuilder?: FiltersBuilder,
	useFiltersForDependencies?: boolean,
	isStatic?: boolean
): UsePharmaChart => {
	const dispatch = useDispatch();
	const { dependenciesData, isEveryDependencyReady } = useChartDependencies(
		pathConfig,
		dependencies,
		useFiltersForDependencies,
		filters
	);

	const preparedFilters = prepareFilters({
		...filters,
		...(_isFunction(filtersBuilder) ? filtersBuilder({ dependenciesData }) : {})
	});

	const { isLoading, data, error } = useSelector(createChartDataSelector(pathConfig));

	const loadData = useCallback((filters = []) => {
		if (!isEveryDependencyReady) {
			return;
		}

		// do not reload the chart based on filters if its static
		const load = isStatic ? data == null : true;

		if (load) {
			dispatch(chartDataRequest(
				pathConfig,
				filters
			));
		}
	}, [dispatch, pathConfig, isEveryDependencyReady]);

	useEffect(() => {
		if (pathConfig.chart !== CHART_TYPES.TABLE && pathConfig.chart !== CHART_TYPES.CALCULATED_MANUALLY) {
			loadData(preparedFilters);
		}
	}, [pathConfig, loadData, isEveryDependencyReady]);

	return {
		isLoading,
		isEveryDependencyReady,
		dependenciesData,
		data,
		error,
		loadData: () => loadData(filters)
	};
};