import { put, call, takeEvery, all, fork, CallEffect, PutEffect } from 'redux-saga/effects';
import * as actions from '../actions/entityUrl.actions';
import * as actionTypes from '../actions/actionTypes/entityUrl.actionTypes';
import { getEntityUrl, getEntityUrlWithFilters } from '../services/entityUrl.service';
import { GetEntityUrlWithFiltersAction } from '../actions/actionTypes/entityUrl.actionTypes';

export function* onGetEntityUrl({ payload }: any): Generator<any, any, any> {
    try {
        const data = yield call(getEntityUrl, payload);
        yield put(actions.getEntityUrlSuccess(payload, data));
    } catch (error) {
        yield put(actions.getEntityUrlError(payload, error));
    }
}

export function* onGetEntityUrlWithFilters({ payload }: GetEntityUrlWithFiltersAction): Generator<any, void, string> {
    try {
        const data = yield call(getEntityUrlWithFilters, payload);
        yield put(actions.getEntityUrlWithFiltersSuccess(payload, data));
    } catch (error) {
        yield put(actions.getEntityUrlWithFiltersWithFiltersError(payload, error));
    }
}

function* watchOnGetEntityUrlWithFilters() {
    yield takeEvery(actionTypes.GET_ENTITY_URL_WITH_FILTERS_DATA, onGetEntityUrlWithFilters)
}

function* watchOnGetEntityUrl() {
    yield takeEvery(actionTypes.GET_ENTITY_URL_DATA, onGetEntityUrl);
}

export default function* entityUrlSaga() {
    yield all([
        fork(watchOnGetEntityUrl),
        fork(watchOnGetEntityUrlWithFilters)
    ]);
}
