import React, { FunctionComponent, ReactNode, SyntheticEvent } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import MuiDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import { DialogActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { widthMuiType } from '../../typings';

interface AppDialogCommonProps {
    onClose: () => void;
}

interface AppDialogProps extends AppDialogCommonProps {
    title: string;
    visible: boolean;
    labelledBy?: string;
    titleId?: string;
    actionsRenderer?: ReactNode;
    disableBackdropClick?: boolean;
    fullWidth?: boolean;
    maxWidth?: widthMuiType;
    classNames?: string
}

type AppDialogTitleProps = DialogTitleProps & AppDialogCommonProps;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />;
});


const DialogTitle = (props: AppDialogTitleProps) => {
    const { children, onClose, ...other } = props;
    return (
        <MuiDialogTitle {...other}>
            <div>
                <Typography variant='h6'>{children}</Typography>
            </div>
            {onClose && (
                <IconButton
                aria-label='close'
                sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px'
                 }}
                onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            )}
        </MuiDialogTitle>
    );
};

const AppDialog: FunctionComponent<AppDialogProps> = (props) => {
    const onBackdropClickHandler = (event: SyntheticEvent<{}, Event>) => {
        if (props.disableBackdropClick) return
        props.onClose()
    }
    return (
            <Dialog
                onClose={props.onClose}
                aria-labelledby={props.labelledBy}
                open={props.visible}
                TransitionComponent={Transition}
                onBackdropClick={onBackdropClickHandler}
                fullWidth={props.fullWidth}
                maxWidth={props.maxWidth as widthMuiType}
                className={props.classNames}
         >
        <DialogTitle id={props.titleId} onClose={props.onClose}>
            {props.title}
        </DialogTitle>
        <DialogContent dividers>{props.children}</DialogContent>
        {props.actionsRenderer && <DialogActions>{props.actionsRenderer}</DialogActions>}
    </Dialog>
    )
}

export default AppDialog;
