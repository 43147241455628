import { put, call, takeEvery, all, fork } from 'redux-saga/effects';
import { loadEntityData } from '../services/entities.service';
import * as actions from '../actions/entity.actions';
import * as actionTypes from '../actions/actionTypes/entity.actionTypes';
import { DashboardHeaderAction, EntityRequestAction, EntityActionPayload } from '../actions/actionTypes/entity.actionTypes';

export function* onLoadEntityData({ payload }: EntityRequestAction): Generator<any, any, any> {
    try {
        const { data } = yield call(loadEntityData, payload);
        yield put(actions.entityDataLoaded(payload, data));
    } catch (error) {
        yield put(actions.entityDataError(payload, error));
    }
}

export function* onLoadDashboardHeaderData({ payload }: DashboardHeaderAction): Generator<any, any, any> {
    const payloadConfig = payload.config as EntityActionPayload;

    const sectionToRequestConfigs = (payload.sections || [])
        .map((sectionType) => ({
            ...payloadConfig,
            sectionType,
        }));

    yield all(sectionToRequestConfigs.map((config) => put(actions.entityDataRequest(config))));
}

function* watchOnLoadDashboardHeaderData() {
    yield takeEvery(actionTypes.DASHBOARD_HEADER_DATA_REQUEST, onLoadDashboardHeaderData);
}

function* watchOnLoadEntityData() {
    yield takeEvery(actionTypes.DATA_REQUEST, onLoadEntityData);
}

export default function* entitiesSaga() {
    yield all([fork(watchOnLoadEntityData), fork(watchOnLoadDashboardHeaderData)]);
}
