import * as actions from './actionTypes/filters.actionTypes';
import { getWorkaroundFiltersOptions } from '../helpers/filter';

export function getFiltersRequest(data: actions.IGetFilters): actions.GetFiltersAction {
    return {
        type: actions.GET_FILTERS,
        payload: data
    };
}

export function getFiltersSuccess(data: actions.IGetFiltersSuccess): actions.GetFiltersSuccessAction {
    const payload = getWorkaroundFiltersOptions(data);

    return {
        type: actions.GET_FILTERS_SUCCESS,
        payload
    };
}

export function getFiltersError(data: actions.IGetFiltersError): actions.GetFiltersErrorAction {
    return {
        type: actions.GET_FILTERS_ERROR,
        payload: data
    };
}

export function setFilters(data: actions.ISetFilters): actions.SetFiltersAction {
    return {
        type: actions.SET_FILTERS,
        payload: data
    };
}

export function resetFilterState(): actions.ResetFilterStateAction {
    return {
        type: actions.RESET_FILTER_STATE,
        payload: null
    };
}
