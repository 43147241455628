import { getToken } from './../services/auth.service';
import { prepareFilters } from './../helpers/filter';
import { EntityUrlParams, ExistsParams, EntityType, EntityUrlWithFiltersParams } from './../../typings';
import axios, { AxiosRequestConfig } from 'axios';
import _mapValues from 'lodash-es/mapValues';
import _isNumber from 'lodash-es/isNumber';
import _isString from 'lodash-es/isString';
import { endpoints } from './endpoints';
import { ChartsBatchRequestPath } from '../actions/actionTypes/charts.actionsTypes';
import { EntityActionPayload } from '../actions/actionTypes/entity.actionTypes';
import { ChartPath, FiltersResponse, PreparedFilterValues } from '../../typings';
import { parseDateRange } from '../helpers/dates';
import {
    DecoratedUserPreferencesPayload,
    UserPreferencesPayload,
    SaveUserPreferencesPayload,
    GetUserPreferencesPayload,
    SendFeedbackPayload,
    TableRowPayload,
} from './requestPayload.interface';
import { DownloadStatusResponse } from './responses.interface';
import { LOG_OUT_CALLBACK_ROUTE } from '../../constants/routes';

axios.interceptors.response.use(undefined, function (error) {
    if (error?.response?.status === 401) {
        window.location.assign(LOG_OUT_CALLBACK_ROUTE)
    }
    return Promise.reject(error);
});

// TODO Adding filters may currently break our SDL endpoints, so use this temporary flag to avoid it
const SHOULD_USE_CHARTS_FILTERS = true;

const ALLOWED_SEMANTIC_DATA_LAYER_METHODS = {
    GET: 'get',
    POST: 'post',
};

const getHeaders = () => {
    return {
        Authorization: `Bearer ${getToken()}`,
    };
};

const querySemanticDataLayer = async (
    apiPath: string,
    data: any = null,
    method = ALLOWED_SEMANTIC_DATA_LAYER_METHODS.POST,
    params?: any
) => {
    const url = `${endpoints.semanticDataLayer()}/${apiPath}`;
    const headerParams = {
        headers: getHeaders(),
        params,
    };

    if (method === ALLOWED_SEMANTIC_DATA_LAYER_METHODS.GET) {
        return await axios.get(url, headerParams);
    }

    return await axios.post(url, data, headerParams);
};

const charts = {
    async fetchChartData(params: ChartPath, filters: PreparedFilterValues = [], body: any = null) {
        const { search, entity, chart, value } = params;
        const requestParameters: any = {
            value,
        };
        // TODO here we add filters to charts data request
        const requestData = filters && SHOULD_USE_CHARTS_FILTERS ? { filters } : null;

        const { data } = await querySemanticDataLayer(
            `getData/${search}/${entity}/${chart}`,
            { ...requestData, ...body },
            ALLOWED_SEMANTIC_DATA_LAYER_METHODS.POST,
            requestParameters
        );

        return data;
    },
};

const projectTable = {
    async getProjectTableData(entityType: EntityType, filters: PreparedFilterValues = [], body: any = null) {
        const { data } = await querySemanticDataLayer(
            `project/table/${entityType}`,
            { filters, ...body },
            ALLOWED_SEMANTIC_DATA_LAYER_METHODS.POST
        );

        return data;
    },
};

const filters = {
    async fetchFilters(params: ChartsBatchRequestPath): Promise<FiltersResponse | Error> {
        // TODO Change it to some real filters request
        const { search, entity, value } = params;

        const { data } = await querySemanticDataLayer(
            `getFilters/${search}/${entity}/${value}`,
            null,
            ALLOWED_SEMANTIC_DATA_LAYER_METHODS.GET
        );

        // Temporary fix to pass only unique values to multiselect filters
        return _mapValues(data, (values: any, key: string) => {
            if (Array.isArray(values) || ['legend', 'legends'].includes(key)) {
                return values;
            }

            return {
                minDate: parseDateRange(values.min),
                maxDate: parseDateRange(values.max),
            };
        });
    },
};

const entities = {
    async fetchEntityData(params: EntityActionPayload) {
        const { entityType, sectionType, entityId } = params;

        const { data } = await querySemanticDataLayer(`getHeaderData/${entityType}/${sectionType}/${entityId}`, null);

        return data;
    },
};

const singleSearch = {
    async fetchSingleSearch(value: string) {
        const body = { value };
        const { data } = await querySemanticDataLayer(`getSearchData`, body, ALLOWED_SEMANTIC_DATA_LAYER_METHODS.POST);

        return data;
    },
};

const existsData = {
    async fetchExists(params: ExistsParams) {
        const { search, entity, value } = params;
        const requestParameters: any = {
            value,
        };
        const { data } = await querySemanticDataLayer(
            `getDataExists/${search}/${entity}`,
            null,
            ALLOWED_SEMANTIC_DATA_LAYER_METHODS.GET,
            requestParameters
        );
        return data;
    },
};

const projects = {
    getProject(projectId: string, token?: string) {
        return axios.get(endpoints.project(projectId), {
            headers: {
                Authorization: `Bearer ${token ?? getToken()}`,
            }
        });
    },
    getProjects() {
        return axios.get(endpoints.projects(), {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    },
    getProjectsPaged(body: any) {
        return axios.post(endpoints.projectsPaged(), body, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    },
    editProject(body: any) {
        return axios.put(endpoints.project(body.id), body, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    },
    saveProject(body: any) {
        return axios.post(endpoints.project(), body, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    },
    deleteProject(projectId: number) {
        return axios.delete(endpoints.project(projectId), {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    },
    saveSearchToProject(body: any) {
        return axios.post(endpoints.saveSearch(), body, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    },
    removeSearches(body: any) {
        return axios.post(endpoints.removeSearches(), { searchIds: body }, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    },
    pinUnpinProject(projectId, isPinned) {
        return axios.post(endpoints.pinUnpinProject(projectId, isPinned), undefined, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    },
    getPinnedProjects(token: string) {
        return axios.get(endpoints.getPinnedProjects(), {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    },
    saveTableRowToProject(body: TableRowPayload) {
        return axios.put(endpoints.saveRowToProject(body.projectId), body, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    },
    deleteTableRowFromProject(projectId: string, rowId: string) {
        return axios.delete(endpoints.deleteTableRowFromProject(projectId, rowId), {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    },
    getSavedTableRows(projectId: string, entityType?: EntityType) {
        return axios.get(endpoints.getSavedTableRows(projectId, entityType), {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },
};

const entityUrls = {
    async getUrl(params: EntityUrlParams) {
        const { search, entity, value } = params;
        const { data } = await querySemanticDataLayer(
            `getEntityUrl/${search}/${entity}/${value}`,
            null,
            ALLOWED_SEMANTIC_DATA_LAYER_METHODS.GET,
            null
        );
        return data.url;
    },
    async getUrlWithFilters(params: EntityUrlWithFiltersParams) {
        const { search, entity, value, filters } = params;
        const { data } = await querySemanticDataLayer(
            `getEntityUrl/${search}/${entity}/${value}`,
            filters
        );
        return data.url;
    }
};

const downloads = {
    async excel(params: any) {
        const { search, entity, chart, value, filters, searches } = params;
        const url = `${endpoints.semanticDataLayer()}/downloadData`;
        let requestParameters: any;
        if (searches) {
            requestParameters = [];
            searches.forEach((element) => {
                requestParameters.push({
                    ...element,
                    filters: prepareFilters(element.filters),
                    searchSubSection: element.searchSubSection || 'tile',
                });
            });
        } else {
            requestParameters = [
                {
                    filters: prepareFilters(filters),
                    searchType: search,
                    entityType: entity,
                    searchId: value,
                    searchSubSection: chart,
                },
            ];
        }
        return await axios.post(url, requestParameters, { headers: getHeaders() });
    },
    async checkStatus(requestId: string) {
        return await axios.get<DownloadStatusResponse>(`${endpoints.semanticDataLayer()}/generateFile/${requestId}`, { headers: getHeaders() });
    },
    async downloadExcel(url: string) {
        const config: AxiosRequestConfig = {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/xlsx',
            },
        }
        const response = await axios.get(url, config);
        return response.data;
    }
};

const getDecoratedUserPreferencesPayload = (
    payload: UserPreferencesPayload
): DecoratedUserPreferencesPayload<UserPreferencesPayload> => ({
    ...payload,
    productName: 'pharma360',
});

const userPreferences = {
    save(payload: SaveUserPreferencesPayload) {
        const decoratedPayload = getDecoratedUserPreferencesPayload(payload);

        if (payload.version) {
            const { version } = payload;
            decoratedPayload.version = _isNumber(version)
                ? version + 1
                : _isString(version)
                ? parseInt(version, 10) + 1
                : 1;
        } else {
            decoratedPayload.version = 1;
        }

        return axios.post(endpoints.savePreferences(), decoratedPayload, {
            headers: getHeaders(),
        });
    },
    get(payload: GetUserPreferencesPayload) {
        const decoratedPayload = getDecoratedUserPreferencesPayload(payload);

        return axios.post(endpoints.getOrRemovePreferences(), decoratedPayload, {
            headers: getHeaders(),
        });
    },
    remove(preferenceId: number) {
        return axios.delete(`${endpoints.getOrRemovePreferences()}/${preferenceId}`, { headers: getHeaders() });
    },
};

const subscriptions = {
    getSubscriptions: (token: string): Promise<Array<any> | Error> =>
        axios.get(endpoints.getSubscriptions(), { headers: { Authorization: `Bearer ${token}` } }),
    getUserFeatures: (parentServiceId: string, salesForceId: string, token: string) => {
        return axios.get(endpoints.getUserFeatures(parentServiceId, salesForceId), { headers: { Authorization: `Bearer ${token}` } })
    },
    createUser: (parentServiceId: string, token: string) => {
        return axios.post(endpoints.createUser(parentServiceId),
         undefined,
        { headers: { Authorization: `Bearer ${token}` } }
       )}

};

const feedbacks = {
    sendFeedback(payload: SendFeedbackPayload) {
        return axios.post(endpoints.feedbacks(), payload, { headers: getHeaders() });
    },
};

export const api = {
    subscriptions,
    downloads,
    charts,
    entities,
    filters,
    projects,
    singleSearch,
    existsData,
    userPreferences,
    entityUrls,
    feedbacks,
    projectTable,
};
