import _noop from 'lodash-es/noop';
import { environment, ENVIRONMENTS } from '../enviroment';

// @ts-ignore
const appcues = window.Appcues;

export function isAppcuesDefined() {
    return window.hasOwnProperty('Appcues');
}

function isAppcuesEnabled() {
    return isAppcuesDefined() && environment() === ENVIRONMENTS.PRODUCTION;
}

function buildAppcuesProductionExecutable(closure: Function): Function {
    if (!isAppcuesEnabled()) {
        return _noop;
    }

    return closure;
}

export const appcuesService = {
    identify: buildAppcuesProductionExecutable((id: string, email: string) => {
        appcues.identify(id, {
            email
        });
    }),
    anonymous: buildAppcuesProductionExecutable(appcues?.anonymous),
    restart: buildAppcuesProductionExecutable(appcues?.start)
}