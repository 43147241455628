import { all, fork } from 'redux-saga/effects';
import subscriptionsSaga from './subscriptions.saga';
import chartsSaga from './charts.saga';
import entitiesSaga from './entities.saga';
import filtersSaga from './filters.saga';
import searchSaga from './search.saga';
import projectsSaga from './projects.saga';
import entityUrlSaga from './entityUrl.saga';

export default function* rootSaga() {
    yield all([
        fork(subscriptionsSaga),
        fork(chartsSaga),
        fork(entitiesSaga),
        fork(filtersSaga),
        fork(searchSaga),
        fork(projectsSaga),
        fork(entityUrlSaga),
    ]);
}
