import jwt_decode from 'jwt-decode';
import endpoints from '../api/endpoints';
import { appcuesService } from './analytics/appcues';
import { AuthTokenType, FEATURE, ITokenContent } from '../../typings';

const authDisabled = process.env.REACT_APP_DISABLE_AUTH === 'true';
const isSSOAuthentification = process.env.REACT_APP_IS_OATH === 'true';

export const loginSettings = {
    loginUrl: endpoints.identity(),
    logOutUrl: endpoints.logout(),
};

export const login = (): void => {
    window.location.assign(loginSettings.loginUrl);
};

export const logout = (): void => {
    localStorage.removeItem('token');
    appcuesService.anonymous();
    window.location.assign(loginSettings.logOutUrl);
};

export const getOAuth0UserContent = (token: string): ITokenContent => {
    const { entls, udt, exp } = jwt_decode<AuthTokenType>(token) || {};
    const firstNameInitial = udt?.fn?.[0];
    const lastNameInitial = udt?.ln?.[0];

    const parentService = entls?.SF?.pcds?.find(x => x.p === 'Pharma360');

    return {
        salesForceId: entls?.SF?.sfcid,
        firstName: udt?.fn,
        lastName: udt?.ln,
        email: udt?.em,
        roles: udt?.jt,
        isTrialUser: parentService?.atyp === 'Trial',
        parentServiceId: parentService?.subid,
        subscriptions: entls?.SF?.pcds ? entls.SF.pcds.flatMap(x => x.ecodes) : [],
        initials: firstNameInitial + lastNameInitial || '',
        organizationName: udt?.cmp,
        customAttributes: {
            contact_clientattribute2: udt.clat2,
            contact_clientattribute1: udt.clat1,
            contact_clientjobrole: udt.clrle,
            contact_clientdepartment: udt.cldpt,
            contact_clientlocation: udt.clloc
        },
        entitlementType: parentService?.atyp,
        exp
    }
  }

export const isAuthenticated = (): boolean => isSSOAuthentification ? true : !hasTokenExpired();

export const getTokenContent = (): any => {
    const token = getToken();

    if (!token) {
        return null;
    }

    return process.env.REACT_APP_IS_OATH === 'true' ? getOAuth0UserContent(token) : jwt_decode(token);
};

export const getUser = (): any => {
    const content = authDisabled ? mockUser : getTokenContent();

    if (!content) {
        return null;
    };

    if (isSSOAuthentification) {
        return content;
    }
    

    const firstNameInitial = getFirstLetter(content.firstName);
    const lastNameInitial = getFirstLetter(content.lastName);

    return {
        id: content.uId,
        salesForceId: content.salesForceId,
        firstName: content.firstName,
        lastName: content.lastName,
        email: content.email,
        roles: content.roles,
        features: content.features,
        subscriptions: content.subsc,
        initials: firstNameInitial + lastNameInitial,
        organizationName: content.organizationName,
        customAttributes: content.customAttributes,
        entitlementType: content.entitlementType,
        ideServiceId: content.ideServiceId,
    };
};

export const loginCallback = (): void => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token') || '';

    localStorage.setItem('token', token);

    if (token && token.length) {
        const { salesForceId, email } = getUser();
        appcuesService.identify(salesForceId, email);
    }

    const redirectUrl = localStorage.getItem('redirectUrl') || '/';
    window.location.assign(redirectUrl);
};

export const getToken = (): string | null => localStorage.getItem('token');

export const hasTokenExpired = (): boolean => {
    const token = getTokenContent();

    if (!token) {
        return true;
    }

    const today = new Date().getTime();

    return token.exp * 1000 < today;
};

export const isTokenExpired = (validateDate: number) => {
    const today = new Date().getTime();

    return validateDate * 1000 < today;
} 

const getFirstLetter = (word: string): string => word?.substring(0, 1).toUpperCase();

export const userHasAccessToPharma360 = (): boolean => authDisabled ? true : isSubscribedTo('Pharma360');

export const isSubscribedTo = (code: string): boolean => {
    const token = getTokenContent();

    if (!token) {
        return false;
    }

    return (token.subsc || token.subscriptions)?.includes(code) ?? false;
};

const mockUser = {
    firstName: 'John',
    lastName: 'Doe',
    features: Object.values(FEATURE),
    initials: 'JD'
};
