import React, { memo } from 'react';
import merge from 'lodash-es/merge';
import { PharmaStackedColumnChart } from '@informa/pharma-common-react-components';
import { ChartContainer } from '../ChartContainer';
import { ChartCommonProps, ChartRendererProps } from '../ChartContainer/ChartContainer';
import { ChartWithLabelWrapper } from '../ChartWithLabelWrapper';

const StackedColumnChartRenderer = ({ data, componentProps }: ChartRendererProps) => {
    const options = merge({ chartOptions: { yAxis: { min: 0 } } }, componentProps?.options);
    return (
        <ChartWithLabelWrapper componentProps={componentProps}>
            <PharmaStackedColumnChart
                categories={data.categories}
                series={data.series}
                showLabels={componentProps?.showLabels}
                onClick={componentProps?.onClick}
                onLabelClick={componentProps?.onLabelClick}
                options={options}
            />
        </ChartWithLabelWrapper>
    );
};

export default memo((props: ChartCommonProps) => <ChartContainer {...props} component={StackedColumnChartRenderer} />);
