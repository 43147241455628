import React, { memo } from 'react';
import _merge from 'lodash-es/merge';
import { PharmaDoughnutChart } from '@informa/pharma-common-react-components';
import { ChartCommonProps, ChartRendererProps } from '../ChartContainer/ChartContainer';
import { ChartContainer } from '../ChartContainer';
import { formatCurrency } from '../../../core/helpers/number';

const DoughnutChartRenderer = ({ data, componentProps, isCurrency }: ChartRendererProps) => {
    let options = componentProps?.options || {};

    options = _merge(options, {
        chartOptions: {
            tooltip: {
                enabled: true,
            },
        },
    });

    if (isCurrency) {
        options = _merge(options, {
            chartOptions: {
                tooltip: {
                    formatter: function () {
                        //@ts-ignore
                        const value = formatCurrency(this.point?.y);
                        //@ts-ignore
                        return `<b>${this.point?.name}</b>: ${value}<br/>`;
                    },
                },
                series: [
                    {
                        dataLabels: {
                            format: null,
                            formatter: function () {
                                //@ts-ignore
                                const value = formatCurrency(this.point?.y);
                                //@ts-ignore
                                return `${this.point.name}<br>${value}`;
                            },
                        },
                    },
                ],
            },
        });
    }

    return (
        <PharmaDoughnutChart
            radius='80%'
            size='75%'
            data={data}
            title={componentProps?.label}
            onClick={componentProps?.onClick}
            dataLabelFormat={componentProps?.dataLabelFormat}
            options={options}
        />
    );
};

export default memo((props: ChartCommonProps) => <ChartContainer {...props} component={DoughnutChartRenderer} />);
