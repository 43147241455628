import { AppAction } from '../../store/typings';
import { ChartPath, PreparedFilterValues } from '../../../typings';

export const DATA_REQUEST = `CHARTS/DATA_REQUEST`;
export const DATA_LOADING = 'CHARTS/DATA_LOADING';
export const DATA_READY = `CHARTS/DATA_READY`;
export const DATA_ERROR = `CHARTS/DATA_ERROR`;

export interface ChartsActionPayload {
      pathConfig: ChartPath;
      filters?: PreparedFilterValues;
}

export interface ChartsLoadingActionPayload {
      pathConfig: ChartPath;
}

export type ChartsBatchRequestPath = ChartPath;

interface ChartsActionReadyPayload extends ChartPath {
      data: any;
}

interface ChartsActionErrorPayload extends ChartPath {
      error: any;
}

export type ChartsRequestAction = AppAction<typeof DATA_REQUEST, ChartsActionPayload>;
export type ChartsLoadingAction = AppAction<typeof DATA_LOADING, ChartsLoadingActionPayload>;
export type ChartsLoadedAction = AppAction<typeof DATA_READY, ChartsActionReadyPayload>;
export type ChartsErrorAction = AppAction<typeof DATA_ERROR, ChartsActionErrorPayload>;

export type ChartsAction = ChartsRequestAction | ChartsLoadingAction | ChartsLoadedAction | ChartsErrorAction;
