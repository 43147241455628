import { AppAction } from '../../store/typings';
import { AutocompleteOptions } from '../../../components/Home/SearchBox/typings';
export const GET_SEARCH_DATA: string = 'SEARCH/GET';
export const GET_SEARCH_SUCCESS: string = 'SEARCH/GET_SUCCESS';
export const GET_SEARCH_ERROR: string = 'SEARCH/GET_ERROR';

export interface IGetSearchSuccess {
    searchData: AutocompleteOptions;
}
export interface IGetSearchError {
    error: string | null;
}

export type GetSearchAction = AppAction<typeof GET_SEARCH_DATA, string>;
export type GetSearchSuccessAction = AppAction<typeof GET_SEARCH_SUCCESS, IGetSearchSuccess>;
export type GetSearchErrorAction = AppAction<typeof GET_SEARCH_ERROR, IGetSearchError>;

export type SearchAction = GetSearchAction | GetSearchSuccessAction | GetSearchErrorAction;
