import { api } from '../api';
import { EntityType } from '../../typings';
import { TableRowPayload } from '../api/requestPayload.interface';

export function getProject(projectId: string, token?: string) {
    return api.projects.getProject(projectId, token);
}

export function getProjects() {
    return api.projects.getProjects();
}

export function getProjectsPaged(body: any) {
    return api.projects.getProjectsPaged(body);
}

export function editProject(body: any) {
    return api.projects.editProject(body);
}

export function saveProject(body: any) {
    return api.projects.saveProject(body);
}

export function deleteProject(projectId: number) {
    return api.projects.deleteProject(projectId);
}

export function pinUnpinProject(projectId: number, isPinned: boolean) {
    return api.projects.pinUnpinProject(projectId, isPinned);
}

export function getPinnedProjects(token: string) {
    return api.projects.getPinnedProjects(token);
}

export function saveSearchToProject(body: any) {
    return api.projects.saveSearchToProject(body);
}

export function removeSearches(body: any) {
    return api.projects.removeSearches(body);
}

export function saveTableRowToProject(body: TableRowPayload) {
    return api.projects.saveTableRowToProject(body);
}

export function deleteTableRowFromProject(projectId: string, rowId: string) {
    return api.projects.deleteTableRowFromProject(projectId, rowId);
}

export function getSavedTableRows(projectId: string, entityType?: EntityType) {
    return api.projects.getSavedTableRows(projectId, entityType);
}
