import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import _isNumber from 'lodash-es/isNumber';
import { ChartsBatchRequestPath } from '../../../core/actions/actionTypes/charts.actionsTypes';
import { DEEP_DIVE_REDUX_KEY } from '../../../constants/deepDiveConfig';
import { DeepDiveContentProps } from '../typings';
import Paper from '@mui/material/Paper';
import { CounterChart } from '../../Charts/CounterChart';
import { CHART_TYPES, LOCATION_LABELS, LOCATION_TYPES } from '../../../constants/common';
import { forecastChartFunctionalProps } from '../../Charts/EpiSummaryChart/EpiSummaryChart';
import {
    createChartClickHandlerFactory,
    createChartLabelClickHandlerFactory,
    getCounterValueFromDependencies,
    getForecastYearFromDependencies,
    getLocationTypeFromDependencies,
} from '../../../core/helpers/filter';
import DeepDivePanel from '../Shared/DeepDivePanel/DeepDivePanel';
import { DoughnutChart } from '../../Charts/DoughnutChart';
import { StackedColumnChart } from '../../Charts/StackedColumnChart';
import MapChart from '../../Charts/MapChart/MapChart';
import { RawFilterValues } from '../../../typings';
import { EpiStackedColumnChartOptions } from '../../../constants/EpiChartOptions';

import styles from '../DeepDiveContent.module.scss';

const chartPaperProps = {
    className: styles.deepDiveChartsContainerItem,
    elevation: 0,
};

const getForecastYearFromFilters = (filters?: RawFilterValues) => {
    const valueContainer = filters?.forecastYear?.value;

    return Array.isArray(valueContainer) && valueContainer.length ? valueContainer[0]?.name : valueContainer?.name;
};

const forecastChartLabelBuilder = ({ dependenciesData }) => {
    const forecastYear = getForecastYearFromDependencies(dependenciesData);

    return `${forecastYear} Cases`;
};

const absoluteChangeValueBuilder = ({ dependenciesData }) => {
    const count = getCounterValueFromDependencies(dependenciesData, CHART_TYPES.COUNT);
    const forecastYearCount = getCounterValueFromDependencies(dependenciesData, CHART_TYPES.COUNT_FORECAST);

    if (_isNumber(count) && count !== 0 && _isNumber(forecastYearCount)) {
        return (Math.abs((forecastYearCount - count) / count) * 100).toFixed(1);
    }

    return null;
};

export default (props: DeepDiveContentProps) => {
    const chartPathProps = useMemo<ChartsBatchRequestPath>(
        () => ({
            ...props,
            subPath: DEEP_DIVE_REDUX_KEY,
        }),
        [props]
    );
    const currentLocationType = useMemo(() => getLocationTypeFromDependencies(props.dependenciesData),
        [props.dependenciesData.locationtype.data]
    );
    const createChartClickHandler = createChartClickHandlerFactory(props.onClick);
    const createChartLabelClickHandler = createChartLabelClickHandlerFactory(props.onClick);
    const onOpenDialog = (chartType) => props.onOpenDialog(chartType);

    return (
        <Grid container spacing={3}>
            <Grid item xs={5} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.COUNT,
                        }}
                        componentProps={{
                            label: `${getForecastYearFromFilters(props.filters)} Cases`,
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={2} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.CALCULATED_MANUALLY,
                        }}
                        componentProps={{
                            label: 'Absolute Change',
                            inverted: true,
                            afterValue: '%',
                        }}
                        dependencies={[CHART_TYPES.COUNT, CHART_TYPES.COUNT_FORECAST]}
                        valueBuilder={absoluteChangeValueBuilder}
                        useFiltersForDependencies
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={5} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.COUNT_FORECAST,
                        }}
                        componentProps={{
                            inverted: true,
                        }}
                        filters={props.filters}
                        dependencies={[CHART_TYPES.MAX_FORECAST_YEAR]}
                        filtersBuilder={forecastChartFunctionalProps.filtersBuilder}
                        labelBuilder={forecastChartLabelBuilder}
                    />
                </Paper>
            </Grid>
            {currentLocationType === LOCATION_TYPES.COUNTRY && (
                <React.Fragment>
                    <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                        <DeepDivePanel
                            title='Gender Distribution'
                            heightVariant='large'
                            disableElevation
                            onOpenDialog={() => onOpenDialog(CHART_TYPES.GENDER_DISTRIBUTION_DOUGHNUT)}
                            tileProps={{
                                ...chartPathProps,
                                chart: CHART_TYPES.GENDER_DISTRIBUTION_DOUGHNUT,
                            }}
                            filters={props.filters}>
                            <DoughnutChart
                                pathConfig={{
                                    ...chartPathProps,
                                    chart: CHART_TYPES.GENDER_DISTRIBUTION_DOUGHNUT,
                                }}
                                componentProps={{
                                    onClick: createChartClickHandler(CHART_TYPES.GENDER_DISTRIBUTION_DOUGHNUT),
                                }}
                                filters={props.filters}
                            />
                        </DeepDivePanel>
                    </Grid>
                    <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                        <DeepDivePanel
                            title='Age Gender Distribution'
                            heightVariant='large'
                            disableElevation
                            onOpenDialog={() => onOpenDialog(CHART_TYPES.AGE_GENDER_DISTRIBUTION)}
                            tileProps={{
                                ...chartPathProps,
                                chart: CHART_TYPES.AGE_GENDER_DISTRIBUTION,
                            }}
                            filters={props.filters}>
                            <StackedColumnChart
                                pathConfig={{
                                    ...chartPathProps,
                                    chart: CHART_TYPES.AGE_GENDER_DISTRIBUTION,
                                }}
                                componentProps={{
                                    showLabels: true,
                                    onClick: createChartClickHandler(CHART_TYPES.AGE_GENDER_DISTRIBUTION),
                                    onLabelClick: createChartLabelClickHandler(CHART_TYPES.AGE_GENDER_DISTRIBUTION),
                                    options: {
                                        chartOptions: EpiStackedColumnChartOptions
                                    }
                                }}
                                filters={props.filters}
                            />
                        </DeepDivePanel>
                    </Grid>
                </React.Fragment>
            )}
            <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Geo Map'
                    heightVariant='large'
                    disableElevation
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.EPI_GEO_MAP,
                    }}
                    filters={props.filters}
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.EPI_GEO_MAP)}>
                    <MapChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.EPI_GEO_MAP,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.EPI_GEO_MAP, (event) => {
                                event.point = {
                                    ...event.point,
                                    isCountryLevel: currentLocationType === LOCATION_TYPES.COUNTRY
                                };

                                return event;
                            }),
                            additionalData: props.dependenciesData,
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title={`Cases By ${LOCATION_LABELS[currentLocationType]}`}
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.YEAR_BY_COUNTRY_DISTRIBUTION_DEEP_DIVE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.YEAR_BY_COUNTRY_DISTRIBUTION_DEEP_DIVE,
                    }}
                    filters={props.filters}>
                    <DoughnutChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.YEAR_BY_COUNTRY_DISTRIBUTION_DEEP_DIVE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.YEAR_BY_COUNTRY_DISTRIBUTION_DEEP_DIVE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Full Forecast Per Year'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.FULL_FORECAST_PER_YEAR)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.FULL_FORECAST_PER_YEAR,
                    }}
                    filters={props.filters}>
                    <StackedColumnChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.FULL_FORECAST_PER_YEAR,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.FULL_FORECAST_PER_YEAR),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.FULL_FORECAST_PER_YEAR),
                            options: {
                                chartOptions: EpiStackedColumnChartOptions
                            }
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
        </Grid>
    );
};
