export type Currency = 'USD' | undefined;
export type NumberStyle = 'currency' | undefined;

export const formatCurrency = (value: number, minimumFractionDigits: number = 2, maximumFractionDigits: number = 2) => {
    return format(value, 'currency', 'USD', minimumFractionDigits, maximumFractionDigits);
};

export const formatNumber = (value: number, minimumFractionDigits: number = 2, maximumFractionDigits: number = 2) => {
    return format(value, undefined, undefined, minimumFractionDigits, maximumFractionDigits);
};

const format = (value: number, style: NumberStyle, currency: Currency, minimumFractionDigits: number = 2, maximumFractionDigits: number = 2) => {
    return new Intl.NumberFormat('en-US', {
        style,
        currency,
        minimumFractionDigits,
        maximumFractionDigits,
        //@ts-ignore
        notation: 'compact',
    }).format(value);
};

export const isPluralLike = (value: number) => value !== 1;