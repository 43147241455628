import React, { useMemo } from 'react';
import Grid, { GridDirection } from '@mui/material/Grid';
import { CounterChart } from '../CounterChart';
import { COUNTER_VARIANTS_MAP } from '@informa/pharma-common-react-components';
import { TileContentProps } from '../../TileContent/typings';
import { CHART_TYPES } from '../../../constants/common';
import { CounterVariant } from '@informa/pharma-common-react-components/build';
import { ChartPath, EntityType, RawFilterValues } from '../../../typings';

interface SummaryChartProps extends TileContentProps {
      entity: EntityType;
      direction?: GridDirection;
      size?: CounterVariant;
      filters?: RawFilterValues;
}

export default (props: SummaryChartProps) => {
      const SUMMARY_CHART_CONFIG = useMemo(
            () => [
                  {
                        label: 'Approved',
                        chart: CHART_TYPES.APPROVED_COUNT,
                  },
                  {
                        label: 'Pipeline',
                        chart: CHART_TYPES.PIPELINE_COUNT,
                  },
                  {
                        label: 'Inactive',
                        chart: CHART_TYPES.INACTIVE_COUNT,
                  },
            ],
            [],
      );

      return (
            <Grid
                container
                direction={props.direction || 'column'}
                sx={{justifyContent: 'space-around'}}
                data-testid='summary-chart'
            >
                  {SUMMARY_CHART_CONFIG.map(({ chart, label }, idx) => (
                        <Grid item key={idx}>
                              <CounterChart
                                    pathConfig={{ ...props, chart } as ChartPath}
                                    componentProps={{
                                          label,
                                          size: props.size || COUNTER_VARIANTS_MAP.MEDIUM,
                                          inverted: true,
                                    }}
                                    filters={props.filters}
                              />
                        </Grid>
                  ))}
            </Grid>
      );
};
