import React, { FunctionComponent, memo } from 'react';
import Grid from '@mui/material/Grid';
import { GroupedBarChart } from '../../Charts/GroupedBarChart';
import { EntityType } from '../../../typings';
import TileContentWrapper from '../TileContentWrapper/TileContentWrapper';
import { TileContentProps } from '../typings';
import CounterChart from '../../Charts/CounterChart/CounterChart';
import DoughnutChart from '../../Charts/DoughnutChart/DoughnutChart';
import { SEARCH_TYPES } from '../../../constants/common';

const Pipeline: FunctionComponent<TileContentProps> = (props) => {
      const pathConfig = {
            ...props,
            entity: 'pipeline' as EntityType,
      };

      return (
            <TileContentWrapper testId='pipeline-tile-content'>
                  <Grid item xs={12} md={2}>
                        <CounterChart
                              pathConfig={{
                                    ...pathConfig,
                                    chart: 'count',
                              }}
                              componentProps={{
                                    label: props.search === SEARCH_TYPES.DRUG ? 'Disease(s)' : 'Drug(s)',
                              }}
                        />
                  </Grid>
                  <Grid item xs={12} md={4}>
                        <DoughnutChart
                              pathConfig={{
                                    ...pathConfig,
                                    chart: 'drughighestdiseasestatus',
                              }}
                        />
                  </Grid>
                  <Grid item xs={12} md={6}>
                        <GroupedBarChart
                              pathConfig={{
                                    ...pathConfig,
                                    chart: 'drughigheststatusreached',
                              }}
                              componentProps={{
                                    label: 'Development Status',
                              }}
                        />
                  </Grid>
            </TileContentWrapper>
      );
};

export default memo(Pipeline);
