import * as actions from '../actions/actionTypes/subscriptions.actionTypes';

export interface ISubscriptionsState {
    subscriptions?: any;
    error?: Error | string;
}

const initialState: ISubscriptionsState = { subscriptions: [], error: undefined };

export default function roles(state: ISubscriptionsState = initialState, action: actions.SubscriptionAction): ISubscriptionsState {
    switch (action.type) {
        case actions.GET_SUBSCRIPTIONS:
            return state;
        case actions.GET_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                subscriptions: action.subscriptions
            };
        case actions.GET_SUBSCRIPTIONS_ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}
