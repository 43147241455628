import { AppAction } from '../../store/typings';
import { DashboardEntityType, DashboardSectionType } from '../../../typings';

export const DATA_REQUEST = 'ENTITY/DATA_REQUEST';
export const DATA_READY = 'ENTITY/DATA_READY';
export const DATA_ERROR = 'ENTITY/DATA_ERROR';

export const DASHBOARD_HEADER_DATA_REQUEST = 'ENTITY/DASHBOARD_HEADER_DATA_REQUEST';

export interface EntityActionPayload {
    entityType: DashboardEntityType;
    sectionType: DashboardSectionType;
    entityId: string;
}

export type PartialEntityActionPayload = Omit<EntityActionPayload, 'sectionType'>;

export interface DashboardHeaderActionPayload {
    sections: DashboardSectionType[];
    config: EntityActionPayload | PartialEntityActionPayload;
}

interface EntityActionReadyPayload extends EntityActionPayload {
    data: any;
}

interface EntityActionErrorPayload extends EntityActionPayload {
    error: any;
}

export type EntityRequestAction = AppAction<typeof DATA_REQUEST, EntityActionPayload>;
export type EntityLoadedAction = AppAction<typeof DATA_READY, EntityActionReadyPayload>;
export type EntityErrorAction = AppAction<typeof DATA_ERROR, EntityActionErrorPayload>;

export type DashboardHeaderAction = AppAction<typeof DASHBOARD_HEADER_DATA_REQUEST, DashboardHeaderActionPayload>;

export type EntityAction = EntityRequestAction | EntityLoadedAction | EntityErrorAction;
