import React, { FunctionComponent, memo } from 'react';
import Typography from '@mui/material/Typography';
import styles from './AtAMenu.module.scss';

export interface IAtAMenuProps {
    email: string;
}

const AtAMenu: FunctionComponent<IAtAMenuProps> = (props) => {

    return (
        <div className={styles.item}>
            <a href={`mailto:${props.email}?subject=%5BPharma360%5D%20-%20Ask%20The%20Analyst`} data-testid="ata-mailto">
                <Typography variant="body1" component="span">Ask The Analyst</Typography>
            </a>
        </div>
    );

}

export default memo(AtAMenu);
