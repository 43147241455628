import { createSelector } from 'reselect';

import { AppState } from '../reducers/root.reducer';
import { getUser } from './user.selectors';
import { Subscription } from '../../components/HeaderNavigation/Subscription.interface';

const emptySubscriptionsList: Array<any> = [];

export const getSubscriptions = (state: AppState): Array<any> => (state.subscriptionsData || {}).subscriptions || emptySubscriptionsList;


export const getUserSubscriptions = createSelector(getSubscriptions, getUser,
    (subscriptions, user): Subscription[] => {
        return subscriptions.map((sub) => {
            const claims = sub.claims.split(';').map((x) => {
                return {
                    name: x.replace('.*', ''),
                    allowAll: x.indexOf('.*') > -1
                };
            });

            const isActive = user?.subscriptions?.some(x => claims.some(z => z.name === x) ||
                (claims || [])?.some((y) => y.allowAll && x.includes(y.name)));

            return {
                name: sub.name,
                url: sub.url,
                isActive,
                analystEmail: sub.analystEmail
            };
        });
    });
