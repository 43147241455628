import { AppState } from '../reducers/root.reducer';
import { buildEntityPath } from '../helpers/common';
import { EntityEntry } from '../reducers/entities.reducer';
import { EntityActionPayload } from '../actions/actionTypes/entity.actionTypes';

export const EMPTY_SELECTOR_RESULT = {
      isLoading: false,
      data: null,
      error: null
};

export const createEntityDataSelector = (pathConfig: EntityActionPayload) => (state: AppState) => {
      const path = buildEntityPath(pathConfig);
      const entityState = state.entities;

      if (entityState && entityState.data && entityState.data.hasOwnProperty(path)) {
            const { data, isLoading, error }: EntityEntry = entityState.data[path];

            return {
                  data,
                  isLoading,
                  error,
            };
      }

      return EMPTY_SELECTOR_RESULT;
};


export const getSearchTerm = (state: AppState) => {
      const entityState = state.entities;
      return entityState && entityState.searchTerm;
};