import React from 'react';
import Grid from '@mui/material/Grid';

import style from './TileContentWrapper.module.scss';

const NO_TILE_CONTENT_MESSAGE = 'No tile content provided';

interface TileContentWrapperProps {
      testId: string;
      children: React.ReactNode;
}

export default (props: TileContentWrapperProps) => (
      <Grid container alignItems='center' className={style.tileContent} data-testid={props.testId}>
            {props.children || NO_TILE_CONTENT_MESSAGE}
      </Grid>
);
