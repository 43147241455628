import { formatNumber } from "../core/helpers/number";

export const EpiStackedColumnChartOptions = {
    yAxis: {
        title: {
            text: 'Cases'
        },
        stackLabels: {
            enabled: true,
            format: null,
            formatter: function () {
                return formatNumber((this as any).total, 0, 2);
            }
        },
        labels: {
            enabled: true,
            formatter: function () {
                return formatNumber((this as any).value, 0, 2);
            },
        },
    },
    legend: {
        enabled: true,
    }
};