import React, { memo } from 'react';
import { PharmaCounterChart, COUNTER_VARIANTS_MAP, CounterVariant } from '@informa/pharma-common-react-components';
import _isFunction from 'lodash-es/isFunction';
import { ChartCommonProps, ChartRendererProps } from '../ChartContainer/ChartContainer';
import { ChartContainer } from '../ChartContainer';
import { COUNTER_CHART_COLOR } from '../../../constants/colors';
import { formatCurrency } from '../../../core/helpers/number';

export const CounterChartRenderer = ({
    data,
    componentProps,
    labelBuilder,
    valueBuilder,
    dependenciesData,
    isCurrency,
}: ChartRendererProps) => {
    const variant = (componentProps?.size as CounterVariant) || COUNTER_VARIANTS_MAP.LARGE;
    const label =
        labelBuilder && _isFunction(labelBuilder)
            ? labelBuilder({ data, dependenciesData })
            : componentProps?.label || '';
    const value =
        valueBuilder && _isFunction(valueBuilder)
            ? valueBuilder({ data, dependenciesData })
            : isCurrency
                ? formatCurrency(data?.value)
                : data?.value || 0;

    return (
        <PharmaCounterChart
            label={label || ''}
            value={value}
            variant={variant}
            inverted={componentProps?.inverted || false}
            labelColor={componentProps?.labelColor || COUNTER_CHART_COLOR}
            valueColor={componentProps?.valueColor || COUNTER_CHART_COLOR}
            beforeValue={componentProps?.beforeValue || ''}
            afterValue={componentProps?.afterValue || ''}
            valueFormatter={componentProps?.valueFormatter}
            labelAlignment={componentProps?.labelAlignment}
        />
    );
};

export default memo((props: ChartCommonProps) => <ChartContainer {...props} component={CounterChartRenderer} />);
