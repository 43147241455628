import React, { Component } from 'react';
import ProgressBar from '../../ProgressBar/ProgressBar';
import { login, isAuthenticated } from '../../../core/services/auth.service';

export default class RedirectToLogin extends Component {
    componentDidMount() {
        const isUserAuthenticated = isAuthenticated();
        if (!isUserAuthenticated) {
            login();
        } else {
            window.location.assign('/');
        }
    }

    render() {
        return <ProgressBar message="Redirecting you to registration/login…" />
    }
}
