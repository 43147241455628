import {
    ChartType,
    DashboardSectionType,
    EntityType,
    FilterName,
    FilterType,
    GenderType,
    LocationType,
    SearchType,
} from '../typings';
import { createTypedObjectBuilder } from '../core/helpers/type';
import maleIcon from '../images/male.svg';
import femaleIcon from '../images/female.svg';

export const SEARCH_TYPES = createTypedObjectBuilder<SearchType>()({
    DISEASE: 'disease',
    DRUG: 'drug',
    COMPANY: 'company',
});

export const SEARCH_LABELS = {
    [SEARCH_TYPES.DISEASE]: 'Disease',
    [SEARCH_TYPES.DRUG]: 'Drug',
    [SEARCH_TYPES.COMPANY]: 'Company',
};

export const SEARCH_LABELS_PLURAL = {
    [SEARCH_TYPES.DISEASE]: 'Diseases',
    [SEARCH_TYPES.DRUG]: 'Drugs',
    [SEARCH_TYPES.COMPANY]: 'Companies',
};

export const ENTITY_DESCRIPTION_LABELS = {
    [SEARCH_TYPES.DRUG]: 'Other Names',
    [SEARCH_TYPES.COMPANY]: 'Other Names / Subsidiaries',
    [SEARCH_TYPES.DISEASE]: 'Synonyms'
};

export const CHART_TYPES = createTypedObjectBuilder<ChartType>()({
    COUNT_DEEP_DIVE: 'countdeepdive',
    COUNT: 'count',
    COUNT_FORECAST: 'countforecast',
    PHASE: 'phase',
    COMPLETED_COUNT: 'completedcount',
    ONGOING_COUNT: 'ongoingcount',
    PLANNED_COUNT: 'plannedcount',
    TERMINATED_COUNT: 'terminatedcount',
    TOP_DRUGS: 'topdrugs',
    HIGHEST_DISEASE_STATUS: 'drughighestdiseasestatus',
    HIGHEST_DISEASE_STATUS_REACHED: 'drughigheststatusreached',
    TOTAL_VALUE: 'totalvalue',
    TOP_COMPANIES: 'topcompanies',
    ALLIANCE_COUNT: 'alliancecount',
    ACQUISITION_COUNT: 'acquisitioncount',
    FINANCING_COUNT: 'financingcount',
    TOP_DISEASES: 'topdiseases',
    TRIAL_TIMELINE: 'trialtimeline',
    DRUG_COUNT: 'drugcount',
    SPONSOR_COUNT: 'sponsorcount',
    DISEASE_COUNT: 'diseasecount',
    TOP_DRUGS_DEEP_DIVE: 'topdrugsdeepdive',
    TOP_DISEASES_DEEP_DIVE: 'topdiseasesdeepdive',
    TOP_SPONSORS_DEEP_DIVE: 'topsponsorsdeepdive',
    TOP_SPONSORS: 'topsponsors',
    TABLE: 'table',
    TOTAL_DEAL_VALUE: 'totaldealvalue',
    DEAL_TYPE: 'dealtype',
    TOTAL_VALUE_DEEP_DIVE: 'totalvaluedeepdive',
    TOP_PRODUCTS: 'topproducts',
    DEAL_STATUS: 'dealstatus',
    TOP_COMPANIES_DEEP_DIVE: 'topcompaniesdeepdive',
    APPROVED_COUNT: 'approvedcount',
    PIPELINE_COUNT: 'pipelinecount',
    INACTIVE_COUNT: 'inactivecount',
    AVGPOSITIVELOA: 'avgpositiveloa',
    PRODUCTSABOVEAVG: 'productsaboveavg',
    TOP_DISEASES_BY_SPONSORS: 'topdiseasesbysponsors',
    TOP_TARGETS: 'toptargets',
    GENDER_DISTRIBUTION: 'genderdistribution',
    GENDER_DISTRIBUTION_DOUGHNUT: 'genderdistributiondoughnut',
    AGE_GENDER_DISTRIBUTION: 'agegenderdistribution',
    FULL_FORECAST_PER_YEAR: 'fullforecastperyear',
    EPI_GEO_MAP: 'epigeomap',
    YEAR_BY_COUNTRY_DISTRIBUTION: 'yearbycountrydistribution',
    YEAR_BY_COUNTRY_DISTRIBUTION_DEEP_DIVE: 'yearbycountrydistributiondeepdive',
    DEVELOPMENT_STATUS: 'developmentstatus',
    APPROVED_DISEASES: 'approveddiseases',
    LATE_STAGE_PIPELINE: 'latestagepipeline',
    MAX_FORECAST_YEAR: 'maxforecastyear',
    LOCATION_TYPE: 'locationtype',
    CALCULATED_MANUALLY: 'calculatedmanually',
    CONTENT_TYPE: 'contenttype',
    TIMELINE: 'timeline',
    ARTICAL_SOURCE: 'articalsource',
    ARTICAL_BY_TIME: 'articlebytime',
    TOTAL_REVENUE_CURRENT_YEAR: 'totalrevenuecurrentyear',
    TOTAL_REVENUE_FORECAST_YEAR: 'totalrevenueforecastyear',
    TOP_TA_FOR_CURRENT_YEAR: 'toptacurrentyear',
    REVENUE_PER_TA_ALL_YEARS: 'revenuepertaforallyears',
    TOTAL_REVENUE_BY_DRUG: 'totalrevenuebydrug',
    REVENUE_PER_TA_CURRENT_YEAR: 'revenuepertacurrentyear',
    DRUG_FORECAST_DOUGHNUT: 'drugforecastdoughnut',
    DRUG_FORECAST_DOUGHNUT_DEEPDIVE: 'drugforecastdoughnutdeepdive',
    COUNT_BY_REGION: 'countbyregion',
    REVENUE_LINE_CHART: 'revenuelinechart',
    TOTAL_REVENUE_PER_YEAR: 'totalrevenueperyear',
    CURRENT_YEAR_SALES: 'currentyearsales',
    CAGR: 'cagr',
    LAST_YEAR_FORECAST: 'lastyearforecast',
    PATENT_EXPIRY_BY_REGION: 'patentexpirybyregion',
    REVENUE_FORECAST_BY_YEAR: 'revenueforecastbyyear',
    DEPENDENT_EVENTS: 'dependentevent',
    INDEPENDENT_EVENTS: 'independentevent',
    TOTAL_SALES_CURRENT_YEAR: 'totalsalescurrentyear',
    TOTAL_SALES_FORECAST_YEAR: 'totalsalesforecastyear',
    TOTAL_REVENUE_PER_REGION_CURRENT_YEAR: 'totalrevenueperregioncurrentyear',
    TOP_PRODUCTS_CURRENT_AND_FORECAST_YEAR: 'topproductscurrentandforecastyear',
    PIVOT_TABLE: 'pivot',
    DISEASE_FORECAST_DOUGHNUT: 'diseaseforecastdoughnut',
    REVENUE_PER_TA: 'revenueperta',
    REVENUE_REGION_ALL: 'revenuebyregionall',
    TOTAL_EVENTS_COUNT: 'totalEventsCount',
    POSITIVE_LOA_COUNT: 'positiveLOACount',
    NEGATIVE_LOA_COUNT: 'negativeLOACount',
    NO_IMPACT_LOA_COUNT: 'noImpactLOACount',
    EVENTS_TILE: 'eventsTile',
    UPCOMING_CATALYSTS_COUNT: 'upcomingCatalystCounts',
    LARGE_CATALYSTS_COUNT: 'largeCatalystsCount',
    OTHER_CATALYSTS_COUNT: 'otherCatalystsCount',
    CATALYSTS_TILE: 'catalystsTile',
    TOTAL_EVENTS_COUNT_DEEP_DIVE: 'totalEventsCountDeepDive',
    TOTAL_DRUGS_WITH_EVENTS: 'eventsTotalUniqueDrugs',
    TOTAL_CATALYSTS_COUNT_DEEP_DIVE: 'totalCatalystCountDeepDive',
    TOTAL_DRUGS_WITH_CATALYSTS: 'catalystTotalUniqueDrugs',
    EVENTS_TYPE: 'eventGroupDeepDive',
    CATALYSTS_TYPE: 'catalystGroupType',
    HISTORICAL_EVENTS_DEEP_DIVE: 'eventByQuarterDeepDive',
    FUTURE_CATALYSTS: 'catalystByQuarter',
    EVENTS_TABLE: 'eventTable',
    CATALYSTS_TABLE: 'catalystTable',
    TOTAL_EVENTS_COUNT_ALL_TIME: 'totalEventsCountForAllTime',
    TOTAL_CATALYSTS_COUNT_ALL_TIME: 'totalCatalystAllTime',
    NEAREST_CATALYST_DATE: 'catalystNextExpectedDate',
    INDICATIONS_OVER_PAST_5_YEARS: 'averageIndicationsDeepDive',
    DISEASE_SCOPE: 'diseasescope'
});

export const DASHBOARD_SECTION_TYPES = createTypedObjectBuilder<DashboardSectionType>()({
    NAME: 'name',
    OTHER_NAMES: 'othernames',
    DETAILS: 'details',
    SUMMARY: 'summary',
    TOTAL_REPORTS_COUNT: 'totalreportscount',
    MOST_RECENTLY_PUBLISHED_REPORT: 'mostrecentpublished',
    MOST_RECENT_PUBLISHED_ARTICLES: 'mostrecentpublishedarticles',
    TOTAL_ARTICLES_COUNT: 'totalarticlescount',
    DATA_MONITOR_DATA: 'datamonitordata'
});

export const ENTITY_TYPES = createTypedObjectBuilder<EntityType>()({
    TRIAL: 'trial',
    EPIDEMIOLOGY: 'epi',
    PIPELINE: 'pipeline',
    DEALS: 'deals',
    FORECAST: 'forecast',
    REPORTS: 'report',
    NEWS: 'news',
    EVENTS_CATALYSTS: 'eventcatalyst'
});

export const FILTER_TYPES = createTypedObjectBuilder<FilterType>()({
    MULTISELECT: 'MultiselectFilter',
    DATE_RANGE: 'DateFilter',
});

export const FILTER_NAMES = createTypedObjectBuilder<FilterName>()({
    DRUG: 'drug',
    TRIAL_PHASE: 'trialPhase',
    TRIAL_STATUS: 'trialStatus',
    THERAPEUTIC_AREA: 'therapeuticArea',
    DISEASE: 'disease',
    TRIAL_START_DATE: 'trialStartDate',
    TRIAL_PRIMARY_COMPLETION_DATE: 'trialPrimaryCompletionDate',
    DEAL_TYPE: 'dealType',
    DEAL_STATUS: 'dealStatus',
    DEAL_YEAR: 'dealYear',
    PRODUCT: 'product',
    SPONSORS: 'sponsors',
    COMPANY: 'company',
    DEV_STATUS: 'devStatus',
    DISEASE_STATUS: 'diseaseStatus',
    TARGET: 'target',
    COMPANY_TYPE: 'companyType',
    CONTENT_TYPE: 'contentType',
    COUNTRY: 'country',
    REGION: 'region',
    GENDER: 'gender',
    AGE_GROUP: 'ageGroup',
    FORECAST_YEAR: 'forecastYear',
    PUBLISH_DATE: 'publishDate',
    ARTICLE_TYPE: 'articleType',
    SOURCE_PRODUCT: 'sourceProduct',
    ARTICAL_PUBLISH_DATE: 'articalDate',
    SALES_YEAR: 'salesyear',
    REPORT_PUBLISH_DATE: 'reportPublishDate',
    EVENTS_TYPE: 'eventType',
    EVENTS_IMPACT_ON_LOA: 'eventImpact',
    CATALYSTS_TYPE: 'catalystGroupType',
    CATALYSTS_IMPACT_ON_LOA: 'catalystImpactType',
    EVENTS_DATE: 'eventDate',
    CATALYSTS_DATE: 'catalystDate',
    ROW_ID: 'rowId'
});

export const LOCATION_TYPES = createTypedObjectBuilder<LocationType>()({
    COUNTRY: 'country',
    REGION: 'region',
});

export const LOCATION_LABELS = {
    [LOCATION_TYPES.REGION]: 'Region',
    [LOCATION_TYPES.COUNTRY]: 'Country',
};

export const GENDER_TYPES = createTypedObjectBuilder<GenderType>()({
    MALE: 'male',
    FEMALE: 'female',
});

export const APP_DATE_FORMAT = 'yyyy/MM/dd';
export const PROJECT_DATE_FORMAT = 'dd MMM YYY';
export const ANALYTICS_DATE_FORMAT = 'MMM yyyy';

export const CHART_LEVELS_MAP = {
    FIRST: 1,
    SECOND: 2,
    THIRD: 3,
};

export const GENDER_ICONS = {
    [GENDER_TYPES.MALE]: maleIcon,
    [GENDER_TYPES.FEMALE]: femaleIcon,
};

export const GENDER_LABELS = {
    [GENDER_TYPES.MALE]: 'Male',
    [GENDER_TYPES.FEMALE]: 'Female',
};

export const USER_PREFERENCES_AREAS = {
    PROJECT: 'project',
    DASHBOARD: 'dashboard',
    PROJECT_FOLDERS: 'projectfolders'
};

export const SALES_FORECAST_REGIONS = {
    US: 'US',
    '5EU': '5EU',
    Row: 'RoW',
    Japan: 'Japan',
};

export const NO_DATA_AVAILABLE_MESSAGE = 'No data available';

export const PROVIDERS = {
    [ENTITY_TYPES.PIPELINE]: 'Pharmaprojects',
    [ENTITY_TYPES.EPIDEMIOLOGY]: 'Datamonitor Healthcare',
    [ENTITY_TYPES.FORECAST]: 'Datamonitor Healthcare',
    [ENTITY_TYPES.TRIAL]: 'Trialtrove',
    [ENTITY_TYPES.DEALS]: 'Biomedtracker',
    [ENTITY_TYPES.NEWS]: ['In Vivo', 'Scrip', 'Pink sheet', 'Medtech Insight'],
    [ENTITY_TYPES.REPORTS]: ['Datamonitor Healthcare', 'Biomedtracker'],
    [ENTITY_TYPES.EVENTS_CATALYSTS]: 'Biomedtracker'
};

export const REPORT_PANEL_TITLES = {
    REPORTS: 'Report Library',
    NEWS_AND_INSIGHTS: 'News & Insights',
};

export const PHARMA_360_CLAIM = 'pharma360';
