import React, { FunctionComponent, memo } from 'react';
import Grid from '@mui/material/Grid';
import { COUNTER_VARIANTS_MAP } from '@informa/pharma-common-react-components';
import { CounterChart } from '../CounterChart';
import { TileContentProps } from '../../TileContent/typings';
import { CHART_TYPES } from '../../../constants/common';
import { EntityType, RawFilterValues } from '../../../typings';

const LOA_COUNTER_COLOR = {
    POSITIVE: 'green',
    NEGATIVE: 'red'
};

interface SummaryChartProps extends Omit<TileContentProps, 'chart'> {
    entity: EntityType;
    filters?: RawFilterValues;
}

const EventsSummaryChart: FunctionComponent<SummaryChartProps> = (props) => (
    <Grid
        container
        direction='column'
        sx={{justifyContent: 'space-between'}}
        data-testid='events-summary-chart'
    >
        <CounterChart
            pathConfig={{ ...props, chart: CHART_TYPES.TOTAL_EVENTS_COUNT }}
            componentProps={{
                label: 'Events in last 12 months',
                size: COUNTER_VARIANTS_MAP.MEDIUM,
                inverted: true,
                labelAlignment: 'center',
            }}
        />
        <CounterChart
            pathConfig={{ ...props, chart: CHART_TYPES.POSITIVE_LOA_COUNT }}
            componentProps={{
                label: 'Positive LOA change',
                size: COUNTER_VARIANTS_MAP.MEDIUM,
                labelColor: LOA_COUNTER_COLOR.POSITIVE,
                valueColor: LOA_COUNTER_COLOR.POSITIVE,
                inverted: true
            }}
        />
        <CounterChart
            pathConfig={{ ...props, chart: CHART_TYPES.NO_IMPACT_LOA_COUNT }}
            componentProps={{
                label: 'No LOA change',
                size: COUNTER_VARIANTS_MAP.MEDIUM,
                inverted: true
            }}
        />
        <CounterChart
            pathConfig={{ ...props, chart: CHART_TYPES.NEGATIVE_LOA_COUNT }}
            componentProps={{
                label: 'Negative LOA change',
                size: COUNTER_VARIANTS_MAP.MEDIUM,
                labelColor: LOA_COUNTER_COLOR.NEGATIVE,
                valueColor: LOA_COUNTER_COLOR.NEGATIVE,
                inverted: true
            }}
        />
    </Grid>
);

export default memo(EventsSummaryChart);