import React, { memo } from 'react';
import _isFunction from 'lodash-es/isFunction';
import { ChartCommonProps, ChartRendererProps } from '../ChartContainer/ChartContainer';
import { ChartContainer } from '../ChartContainer';

export const InnerTitle = ({ data, componentProps, labelBuilder }: ChartRendererProps) => {
    const titleData = data || [];

    const value = titleData.map(x => x.name).join(', ');

    const label = labelBuilder && _isFunction(labelBuilder) ?
        labelBuilder({ data }) :
        componentProps?.label || '';

    const valueToRender = [label, value].filter(Boolean);

    return (
        <span className="innerTitle">{valueToRender.join(' ')}</span>
    );
};

export default memo((props: ChartCommonProps) => <ChartContainer {...props} component={InnerTitle} />);
