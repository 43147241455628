import React from 'react';
import Grid from '@mui/material/Grid';
import { COUNTER_VARIANTS_MAP } from '@informa/pharma-common-react-components';
import { CounterChart } from '../CounterChart';
import { TileContentProps } from '../../TileContent/typings';
import { CHART_TYPES, FILTER_TYPES } from '../../../constants/common';
import { ChartPath, ChartType, EntityType, RawFilterValues } from '../../../typings';
import { getForecastYearFromDependencies } from '../../../core/helpers/filter';
import { getCurrentYear } from '../../../core/helpers/dates';

interface SummaryChartProps extends TileContentProps {
	entity: EntityType;
	filters?: RawFilterValues;
}

const currentYear = getCurrentYear();

const counterFiltersBuilder = () => ({
	forecastYear: {
		value: {
			id: currentYear
		},
		filterType: FILTER_TYPES.MULTISELECT
	}
});

export const forecastChartFunctionalProps = {
	chart: CHART_TYPES.COUNT_FORECAST,
	dependencies: [CHART_TYPES.MAX_FORECAST_YEAR] as ChartType[],
	labelBuilder({ dependenciesData }) {
		const forecastYear = getForecastYearFromDependencies(dependenciesData);

		return `${forecastYear} cases`;
	},
	filtersBuilder({ dependenciesData }) {
		return {
			forecastYear: getForecastYearFromDependencies(dependenciesData)
		}
	}
}

export default (props: SummaryChartProps) => (
	<Grid
		container
		direction='column'
		sx={{justifyContent: 'space-between'}}
		data-testid='epi-summary-chart'
		classes={{
			container: 'summaryGridContainer'
		}}
	>
		<Grid item>
			<CounterChart
				pathConfig={{ ...props, chart: CHART_TYPES.COUNT } as ChartPath}
				componentProps={{
					label: `${currentYear} cases`,
					size: COUNTER_VARIANTS_MAP.LARGE,
					inverted: false
				}}
				filtersBuilder={counterFiltersBuilder}
				filters={props.filters}
			/>
		</Grid>

		<Grid item>
			<CounterChart
				pathConfig={{ ...props, chart: CHART_TYPES.COUNT_FORECAST } as ChartPath}
				componentProps={{
					size: COUNTER_VARIANTS_MAP.LARGE,
					inverted: false
				}}
				filters={props.filters}
				{...forecastChartFunctionalProps}
			/>
		</Grid>
	</Grid>
);