import React, { memo } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import cn from 'classnames'

import styles from './ErrorText.module.scss';

export interface IErrorTextProps {
    text: string;
    classNames?: string
}

const ErrorText: React.FC<IErrorTextProps> = ({ text, classNames }) => (
    <FormHelperText error className={cn(styles.validationError, classNames)} data-testid="validation-error">
        <ErrorOutlineIcon />
        <span className={styles.errorText}>{text}</span>
    </FormHelperText>
);

export default memo(ErrorText);
