import Highcharts from 'highcharts';

export const NewsTimelineFormatter = {
    chartOptions: {
        xAxis: {
            labels: {
                formatter: function () {
                    const chartValue: any = this;
                    if (!chartValue.value.name) {
                        return;
                    }

                    const [year, month] = chartValue.value.name.split(',');
                    const date: any = new Date(Date.UTC(parseInt(year, 10), parseInt(month, 10) - 1, 1));
                    return Highcharts.dateFormat('%b, %Y', date);
                },
            },
        },
    },
};
