import React, { useMemo, memo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import _isNumber from 'lodash-es/isNumber';
import TreemapChart from '../../Charts/TreemapChart/TreemapChart';
import { CounterChart } from '../../Charts/CounterChart';
import {
    createChartClickHandlerFactory,
    createChartLabelClickHandlerFactory,
    getCounterValueFromDependencies,
    getForecastYearFromDependencies,
} from '../../../core/helpers/filter';
import { ChartsBatchRequestPath } from '../../../core/actions/actionTypes/charts.actionsTypes';
import { DEEP_DIVE_REDUX_KEY } from '../../../constants/deepDiveConfig';
import { CHART_TYPES, FILTER_NAMES } from '../../../constants/common';
import DeepDivePanel from '../Shared/DeepDivePanel/DeepDivePanel';
import { DeepDiveContentProps } from '../typings';
import { DoughnutChart } from '../../Charts/DoughnutChart';
import LineChart from '../../Charts/LineChart/LineChart';

import styles from '../DeepDiveContent.module.scss';

const chartPaperProps = {
    className: styles.deepDiveChartsContainerItem,
    elevation: 0,
};

const today = new Date();

const percentageValueBuilder = ({ dependenciesData }) => {
    const total = getCounterValueFromDependencies(dependenciesData, CHART_TYPES.TOTAL_REVENUE_CURRENT_YEAR);
    const sub = getCounterValueFromDependencies(dependenciesData, CHART_TYPES.TOP_TA_FOR_CURRENT_YEAR);

    if (_isNumber(total) && total !== 0 && _isNumber(sub)) {
        return ((sub / total) * 100).toFixed(1);
    }
    return null;
};

const forecastChartLabelBuilder = ({ dependenciesData }) => {
    const forecastYear = getForecastYearFromDependencies(dependenciesData);

    return `Total Revenue ${forecastYear}`;
};

export default memo((props: DeepDiveContentProps) => {
    const chartPathProps = useMemo<ChartsBatchRequestPath>(
        () => ({
            ...props,
            subPath: DEEP_DIVE_REDUX_KEY,
        }),
        [props]
    );
    const createChartClickHandler = createChartClickHandlerFactory(props.onClick);
    const createChartLabelClickHandler = createChartLabelClickHandlerFactory(props.onClick);
    const onOpenDialog = (chartType) => props.onOpenDialog(chartType);

    const salesYear = props.filters && props?.filters[FILTER_NAMES.SALES_YEAR];
    const startYear =
        Array.isArray(salesYear?.value) && salesYear?.value.length ? salesYear?.value[0]?.name : salesYear?.value?.name;

    return (
        <Grid container spacing={3}>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TOTAL_REVENUE_CURRENT_YEAR,
                        }}
                        componentProps={{
                            label: `Total Revenue ${today.getFullYear() - 1}`,
                            inverted: true,
                        }}
                        isStatic
                        isCurrency
                    />
                    {/* not passing any filters on those as we want the number to be static */}
                </Paper>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TOTAL_REVENUE_FORECAST_YEAR,
                        }}
                        componentProps={{
                            inverted: true,
                        }}
                        dependencies={[CHART_TYPES.MAX_FORECAST_YEAR]}
                        labelBuilder={forecastChartLabelBuilder}
                        isStatic
                        isCurrency
                    />
                    {/* not passing any filters on those as we want the number to be static */}
                </Paper>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.CALCULATED_MANUALLY,
                        }}
                        componentProps={{
                            inverted: true,
                            label: `Revenue % from top TA ${today.getFullYear() - 1}`,
                            afterValue: '%',
                        }}
                        dependencies={[CHART_TYPES.TOTAL_REVENUE_CURRENT_YEAR, CHART_TYPES.TOP_TA_FOR_CURRENT_YEAR]}
                        valueBuilder={percentageValueBuilder}
                        isStatic
                    />
                    {/* not passing any filters on those as we want the number to be static */}
                </Paper>
            </Grid>

            <Grid item xs={12} md={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Revenue dynamics - top 5 TAs'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.REVENUE_PER_TA_ALL_YEARS)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.REVENUE_PER_TA_ALL_YEARS,
                    }}
                    filters={props.filters}>
                    <LineChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.REVENUE_PER_TA_ALL_YEARS,
                        }}
                        componentProps={{
                            titleXAxis: 'Year',
                            titleYAxis: 'Revenue',
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.REVENUE_PER_TA_ALL_YEARS),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12} md={4} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title={`Revenue per Therapeutic Area ${startYear}`}
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.REVENUE_PER_TA)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.REVENUE_PER_TA,
                    }}
                    filters={props.filters}>
                    <DoughnutChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.REVENUE_PER_TA,
                        }}
                        componentProps={{
                            dataLabelFormat: '{point.name}<br> ({point.percentage:.2f}%)',
                            onClick: createChartClickHandler(CHART_TYPES.REVENUE_PER_TA),
                        }}
                        filters={props.filters}
                        isCurrency
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12} md={8} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title={`Revenue per Product ${startYear}`}
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TOTAL_REVENUE_BY_DRUG)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.TOTAL_REVENUE_BY_DRUG,
                    }}
                    filters={props.filters}>
                    <TreemapChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.TOTAL_REVENUE_BY_DRUG,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.TOTAL_REVENUE_BY_DRUG),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
        </Grid>
    );
});
