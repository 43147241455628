import React, { FunctionComponent, memo, useCallback, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDialog } from '../../AppDialog';
import { sendFeedback } from '../../../core/services/feedbacks.service';
import { GENERIC_ERROR_MESSAGE } from '../../../constants/text';
import { setNotification } from '../../../core/actions/notifications.actions';

import styles from './FeedbackDialog.module.scss';
import ErrorText from '../../ErrorText/ErrorText';

export interface IFeedbackDialogProps {
    visible: boolean;
    onClose: () => void;
}

const FeedbackDialog: FunctionComponent<IFeedbackDialogProps> = ({ visible, onClose }) => {
    const dispatch = useDispatch();
    const [isSuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [showError, setShowError] = useState(false);

    const validateMessage = (value = message) => {
        const isValid = !!value && value.trim();
        setShowError(!isValid);
        return isValid;
    }

    const onChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = event.target.value
        validateMessage(text);
        setMessage(text);
    }, [])

    const onSubmit = () => {
        if(validateMessage()) {
            setDisabled(true);
            const payload = {
                message
            }
            sendFeedback(payload)
                .then(() => {
                    setIsSuccess(true)
                })
                .catch(({response}) => {
                    const message = response?.data?.message || GENERIC_ERROR_MESSAGE;
                    dispatch(setNotification(message))
                })
                .finally(() => {
                    setDisabled(false)
                });
        }
    };

    const onCancel = () => {
        setMessage('');
        setIsSuccess(false);
        setShowError(false);
        onClose();
    }

    return (
        <AppDialog
            title="Feedback"
            visible={visible}
            onClose={onCancel}
        >
            <div
                className={styles.feedbackDialog}
                data-testid="feedback-dialog"
            >
                { isSuccess ?
                    <Typography data-testid="feedback-success-message">
                        Thank you for your feedback! Your message has been passed along to our product team and we may reach out if additional follow up is required.
                    </Typography>
                    :
                    (
                        <>
                            {showError && <ErrorText text="Message must not be empty"/>}
                            <TextField
                                value={message}
                                onChange={onChange}
                                data-testid="feedback-text-field"
                                placeholder="Let us know what you think!"
                                variant="outlined"
                                rows={8}
                                fullWidth
                                multiline
                            />
                        </>
                    )
                }
                <div>
                    {!isSuccess &&
                        <Button
                            disabled={disabled}
                            className={styles.feedbackButton}
                            data-testid="feedback-submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={onSubmit}
                        >
                            Submit
                        </Button>
                    }
                    <Button
                        className={styles.feedbackButton}
                        data-testid="feedback-cancel-button"
                        color="primary"
                        onClick={onCancel}
                    >
                        {!isSuccess ? "Cancel" : "Close"}
                    </Button>
                </div>
            </div>
        </AppDialog>
    )
}

export default memo(FeedbackDialog)