import React, { FunctionComponent, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";
import UnfoldLessOutlinedIcon from "@mui/icons-material/UnfoldLessOutlined";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import CircularProgress from '@mui/material/CircularProgress';
import { LinkProps } from "react-router-dom";
import _isFunction from "lodash-es/isFunction";
import ViewMore from "../ViewMore/ViewMore";
import { isFeatureEnabled } from '../../core/selectors/user.selectors';
import { DownloadOptionsDialog } from "../Downloads";
import { adobeAnalytics } from '../../core/services/analytics/adobe';
import { TileContentProps } from "../TileContent/typings";
import Tooltip from '@mui/material/Tooltip';
import AddRemoveProjectIcon from '../DeepDiveContent/Shared/AddRemoveProjectIcon/AddRemoveProjectIcon';
import { FEATURE } from '../../typings';
import IconButton from '@mui/material/IconButton';

import styles from './TileHeaderActions.module.scss';

export interface ICollapseIconProps {
    isCollapsed: boolean;
    onCollapseClick?: (event: React.MouseEvent) => void;
}

export interface IDownloadIconProps {
    tileProps?: TileContentProps;
    filters?: any;
    exportingElement: any;
    onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    analyticsData?: ITileHeaderActionsProps['analyticsData'];
    searches?: any;
    isImageDisabled?: boolean,
    isExcelDisabled?: boolean
}

export interface ITileHeaderActionsProps extends ICollapseIconProps {
    deepDiveKey: string;
    isDataAvailable: boolean;
    isRemoveAvailable?: boolean;
    isDownloadAvailable?: boolean;
    removeVariant?: 'white' | undefined;
    viewMoreIconClasses?: any;
    onOpenDialog?: () => void;
    seeMoreURLBuilder?: LinkProps['to'];
    onClick?: () => any;
    dragButtonProps?: any;
    tileProps?: TileContentProps;
    filtersApplied?: boolean;
    exportingElement?: any;
    filters?: any;
    analyticsData?: {
        title: string;
        provider: string;
    };
    searches?: any;
    isImageDisabled?: boolean,
    isExcelDisabled?: boolean
}

export const CollapseIcon: FunctionComponent<ICollapseIconProps> = (props) => {
    return (
        <IconButton
            aria-label={props.isCollapsed ? 'collapse' : 'expand'}
            component="div"
            size="small"
            color="inherit"
            onClick={props?.onCollapseClick}
        >
            {props.isCollapsed ? (
                <UnfoldMoreOutlinedIcon
                    fontSize='small'
                    data-testid='collapsed-icon'
                />
            ) : (
                    <UnfoldLessOutlinedIcon
                        fontSize='small'
                        data-testid='expand-icon'
                    />
                )
            }
        </IconButton>
    )
};

export const DownloadIcon: FunctionComponent<IDownloadIconProps> = (props) => {
    const [showDialog, setShowDialog] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const handleClickDownload = () => {
        adobeAnalytics.trackDownloadChart({
            componentName: props.analyticsData?.title,
            productName: props.analyticsData?.provider,
        });

        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    const handleDownloadFinish = () => {
        setIsDownloading(false);
    }

    const handleDownloadStart = () => {
        setIsDownloading(true);
    }

    return (
        <>
            <DownloadOptionsDialog
                element={props.exportingElement}
                showDialog={showDialog}
                handleClose={handleClose}
                tileProps={props.tileProps}
                filters={props.filters}
                searches={props.searches}
                handleDownloadFinish={handleDownloadFinish}
                handleDownloadStart={handleDownloadStart}
                imageDisabled={props.isImageDisabled}
                excelDisabled={props.isExcelDisabled}
            />
            <Tooltip arrow disableFocusListener title="Download" placement="bottom">
                {
                    isDownloading ?
                        <CircularProgress size={20} /> :
                        <IconButton
                            aria-label="Download"
                            size="small"
                            onClick={handleClickDownload}
                            color="inherit"
                        >
                            <VerticalAlignBottomOutlinedIcon
                                fontSize='small'
                                data-testid='download-icon'
                            />
                        </IconButton>
                }
            </Tooltip>
        </>
    );
};

const TileHeaderActions: FunctionComponent<ITileHeaderActionsProps> = (props) => {
    const handleRemoveFromProjectClick = useCallback(() => {
        adobeAnalytics.trackRemoveFromProject({
            componentName: props.analyticsData?.title,
            productName: props.analyticsData?.provider,
        });

        if (typeof props.onOpenDialog !== 'undefined') {
            props.onOpenDialog();
        }
    }, [props.analyticsData, props.onOpenDialog]);

    const handleAddToProjectClick = useCallback(() => {
        adobeAnalytics.trackAddToProject({
            componentName: props.analyticsData?.title,
            productName: props.analyticsData?.provider,
        });
        if (typeof props.onOpenDialog !== 'undefined') {
            props.onOpenDialog();
        }
    }, [props.analyticsData, props.onOpenDialog]);

    const handleViewMoreClick = useCallback(() => {
        adobeAnalytics.trackViewMoreLinkClick({
            componentName: props.analyticsData?.title,
            productName: props.analyticsData?.provider,
        });

        props.onClick && props.onClick();
    }, [props.analyticsData, props.onClick]);

    const isDownloadsFeatureEnabled = useSelector((state: any) => isFeatureEnabled(state, FEATURE.DOWNLOADS));

    return (
        <>
            {!!props.dragButtonProps && <div className={cn('flex-center', styles.dragIconWrapper)} {...(props.dragButtonProps || {})}>
                <Tooltip arrow disableFocusListener title="Drag" placement="bottom">
                    <DragIndicatorOutlinedIcon
                        fontSize='large'
                        className={styles.dragIcon}
                        data-testid='drag-icon'
                    />
                </Tooltip>
            </div>}
            {props.isDataAvailable && (
                <div className={cn(styles.rightIconGroup, 'flex-center')}>
                    <AddRemoveProjectIcon removeIconVariant={props.removeVariant}
                        actionType={props.isRemoveAvailable ? 'remove' : 'add'}
                        onClick={props.isRemoveAvailable ? handleRemoveFromProjectClick : handleAddToProjectClick} />
                    {props.isDownloadAvailable && isDownloadsFeatureEnabled && (
                        <span className='flex-center'>
                            <DownloadIcon
                                tileProps={props.tileProps}
                                filters={props.filters}
                                exportingElement={props.exportingElement}
                                analyticsData={props.analyticsData}
                                searches={props.searches}
                                isImageDisabled={props.isImageDisabled}
                                isExcelDisabled={props.isExcelDisabled}
                            />
                        </span>
                    )}
                    <Tooltip arrow disableFocusListener title="View more" placement="bottom">
                        <span className='flex-center'>
                            <ViewMore
                                onClick={handleViewMoreClick}
                                url={
                                    _isFunction(props.seeMoreURLBuilder)
                                        ? props.seeMoreURLBuilder
                                        : (location) => `${location.pathname}/${props.deepDiveKey}`
                                }
                            />
                        </span>
                    </Tooltip>
                    <Tooltip arrow disableFocusListener title={props.isCollapsed ? 'Expand' : 'Collapse'} placement="bottom">
                        <span className='flex-center'>
                            <CollapseIcon onCollapseClick={props.onCollapseClick} isCollapsed={props.isCollapsed} />
                        </span>
                    </Tooltip>
                </div>
            )}
        </>
    );
};

export default TileHeaderActions;
