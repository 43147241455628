import { PRODUCT_URLS } from '../../api/endpoints';
import { formatAppDate } from '../dates';

const { citeline, biomedtracker } = PRODUCT_URLS;

export const trialRowsMapper = (rows: any[] = []) => {
      return rows.map((row) => {
            return {
                  ...row,
                  link: `${citeline}/trials/details/${row.trialid}`,
            };
      });
};

export const dealRowsMapper = (rows: any[] = []) => {
      return rows.map((row) => {
            return {
                  ...row,
                  link: `${biomedtracker}/DealReport.cfm?DealID=${row.dealid}`,
            };
      });
};

export const pipelineRowsMapper = (rows: any[] = []) => {
      return rows.map((row) => {
            return {
                  ...row,
                  link: `${citeline}/drugs/details/${row.drugid}`,
            };
      });
};

export const reportsRowsMapper = (rows: any[] = []) => {
      return rows.map((row) => {
            return {
                  ...row,
                  link: row.link || `${biomedtracker}/reportdetail.cfm?reportid=${row.id}`,
            };
      });
};

export const eventsRowsMapper = (rows: any[]) => (rows || []).map((row) => ({
      ...row,
      overallchange: row.overallchange ?? 'N/A',
      link: row.link || `${biomedtracker}/EventDetail.cfm?EventID=${row.recordid}`
}));

export const catalystsRowsMapper = (rows: any[]) => (rows || []).map((row) => ({
      ...row,
      expecteddaterangebegin:
          `${formatAppDate(row.expecteddaterangebegin, 'MMM, yyyy')} - ${formatAppDate(row.expecteddaterangeend, 'MMM, yyyy')}`,
      link: row.link || `${biomedtracker}/CatalystDetail.cfm?CatalystID=${row.catalystid}`
}));

export const defaultRowsMapper = (rows: any[] = []) => {
      return rows.map((row) => {
            return {
                  ...row,
            };
      });
};
