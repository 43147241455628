import { useState, useCallback } from 'react';
import { Auth0ProviderOptions } from '@auth0/auth0-react/dist/auth0-provider';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FORBIDDEN_ROUTE, PROFILE_CALLBACK_ROUTE } from '../constants/routes';
import { setUser } from '../core/actions/user.actions';
import { getOAuth0UserContent, getUser as parseIdentityJWT } from '../core/services/auth.service';
import useEffectOnce from './useEffectOnce';
import { api } from '../core/api';
import { FEATURE, ITokenContent } from '../typings';
import { SSO_AUTH_PARAMS } from '../core/api/endpoints';

const { profileURI, ...connections } = SSO_AUTH_PARAMS

const {
    REACT_APP_IS_OATH,
  } = process.env;

  export const clientOptions: Auth0ProviderOptions = {
    authorizationParams: {
      audience: 'https://pharma360/api',
      redirect_uri: window.location.origin,
      scope: 'Pharma360'
    },
    cacheLocation: 'localstorage',
    useCookiesForTransactions: true,
    ...connections
  };

export const localStorageCreatedUserFlag = 'user_created';
export const localStorageCheckOnRegistration = 'registration_completed';
export const localStorageToken = 'token';

export const onClearUpStorageAuthStrategy = () => {
  localStorage.clear();
}


export const useSSOAuthenticator = (): {
    isLoading: boolean,
} => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isVerifyTokenInProcess, setIsVerifyTokenInProgress] = useState<boolean>(true);

    const { getAccessTokenSilently, isLoading, logout, getIdTokenClaims, loginWithRedirect } = useAuth0();

    const getUserService = useCallback(async (content: ITokenContent, token: string): Promise<string[]> => {
        try {
          const isCreatedUser = localStorage.getItem(localStorageCreatedUserFlag);           
          if (isCreatedUser !== 'true') {
            new Promise<void>((resolve, reject) => {
              if (window.location.pathname.includes(FORBIDDEN_ROUTE)) return;
              api.subscriptions.createUser(content.parentServiceId as string, token)
                .then(() => {
                  localStorage.setItem(localStorageCreatedUserFlag, 'true');
                  resolve();
                })
                .catch(error => {
                  localStorage.setItem(localStorageCreatedUserFlag, JSON.stringify(error));
                  reject(error);
                });
            }).catch(() => {
                history.push(FORBIDDEN_ROUTE);
            });
          };

        const userFeatures = await api.subscriptions.getUserFeatures(
          content.parentServiceId as string,
          content.salesForceId as string,
          token
        );
    
          return userFeatures.data.features.map(x => x.name);
        } catch (e) {
          console.error('error getting user features', e)
          return []
        }
      }, [history]);

      const isRegistrationCompleted = useCallback(async (): Promise<boolean> => {
      const isUserCheckedOnRegistration = localStorage.getItem(localStorageCheckOnRegistration);

      if (isUserCheckedOnRegistration === 'true') return true

      const claims = await getIdTokenClaims();
      const isEmailVerified = claims?.email_verified ?? true;
      if (!isEmailVerified) {
        logout();
        return false;
      }

      const isSignUpCompletion = claims?.registration_completed;
      if(!isSignUpCompletion) {
        const source = encodeURIComponent(window.location.origin + PROFILE_CALLBACK_ROUTE);
        window.location.replace(`${profileURI}/?source=${source}`);
        return false;
      }
      
      localStorage.setItem(localStorageCheckOnRegistration, 'true')
      return true
    }, [getIdTokenClaims, logout]); 

    const getUserAccess = useCallback(async (): Promise<void> => {
      try {
        let accessToken = localStorage.getItem(localStorageToken);

        if (!accessToken) {
          try {
            accessToken = await getAccessTokenSilently();
            localStorage.setItem(localStorageToken, accessToken);
          } catch (getTokenError) {
            console.error('error with getting token', getTokenError)
            loginWithRedirect(clientOptions);
            return;
          } 
        }
        
      const isRegistered = await isRegistrationCompleted();

      if (!isRegistered) return;

      const content: ITokenContent = getOAuth0UserContent(accessToken);
      const isUserSubscriber = (content as ITokenContent).parentServiceId;

      if (!isUserSubscriber && !window.location.pathname.includes(FORBIDDEN_ROUTE)) {
        dispatch(setUser({ ...content, features: [] }));
        history.push(FORBIDDEN_ROUTE);
        return;
      };

        const features = isUserSubscriber ? await getUserService(content, accessToken) : [];
    
        dispatch(setUser({
          ...content,
            features: content.isTrialUser
            ? features.filter(x => x !== FEATURE.DOWNLOADS)
            : features
        }));

        if (window.location.pathname) {
          history.push(window.location.pathname)
        }

      } catch (e) {
        console.error('error with getting access_token', e);
        if (
          (e as any).error === 'missing_refresh_token' ||
          (e as any).error === 'invalid_grant' ||
          (e as any).error === 'login_required'
        ) {
            logout();
        }
      }
    }, [dispatch, getAccessTokenSilently, getUserService, history, isRegistrationCompleted, loginWithRedirect, logout]) 

      useEffectOnce(() => {
        const controller = new AbortController();
        if (REACT_APP_IS_OATH !== 'true') {
          dispatch(setUser(parseIdentityJWT()));
          return;
        };

        getUserAccess()
        .catch(error => console.error(error))
        .finally(() => {
          setIsVerifyTokenInProgress(false)
        })

        return () => {
          controller.abort();
        }
      })

      return {
        isLoading: isLoading || isVerifyTokenInProcess,
      }
}