import React, { useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from '../../../hooks';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { CounterChart } from '../../Charts/CounterChart';
import { DoughnutChart } from '../../Charts/DoughnutChart';
import { GroupedBarChart } from '../../Charts/GroupedBarChart';
import { ChartsBatchRequestPath } from '../../../core/actions/actionTypes/charts.actionsTypes';
import { DEEP_DIVE_REDUX_KEY } from '../../../constants/deepDiveConfig';
import { CHART_TYPES } from '../../../constants/common';
import DeepDivePanel from '../Shared/DeepDivePanel/DeepDivePanel';
import { createChartClickHandlerFactory, createChartLabelClickHandlerFactory } from '../../../core/helpers/filter';
import { DeepDiveContentProps } from '../typings';
import { getEntityUrlRequest } from '../../../core/actions/entityUrl.actions';
import { getEntityUrlDataSelector } from '../../../core/selectors/entityUrl.selectors';

import styles from '../DeepDiveContent.module.scss';

const chartPaperProps = {
    className: styles.deepDiveChartsContainerItem,
    elevation: 0,
};

export default memo((props: DeepDiveContentProps) => {
    const dispatch = useDispatch();

    const chartPathProps = useMemo<ChartsBatchRequestPath>(
        () => ({
            ...props,
            subPath: DEEP_DIVE_REDUX_KEY,
        }),
        [props]
    );
    const createChartClickHandler = createChartClickHandlerFactory(props.onClick);
    const createChartLabelClickHandler = createChartLabelClickHandlerFactory(props.onClick);
    const onOpenDialog = (chartType) => props.onOpenDialog(chartType);

    useEffectOnce(() => {
        dispatch(getEntityUrlRequest(chartPathProps));
    });

    const entityUrl = useSelector(getEntityUrlDataSelector(chartPathProps));

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.COUNT_DEEP_DIVE,
                        }}
                        componentProps={{
                            label: 'Diseases',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.APPROVED_DISEASES,
                        }}
                        componentProps={{
                            label: 'Diseases Approved for',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.LATE_STAGE_PIPELINE,
                        }}
                        componentProps={{
                            label: 'Late Stage pipeline',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Development Status Group'
                    heightVariant='large'
                    wrapperClasses={styles.noMarginBottom}
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.HIGHEST_DISEASE_STATUS)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.HIGHEST_DISEASE_STATUS,
                    }}
                    filters={props.filters}>
                    <DoughnutChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.HIGHEST_DISEASE_STATUS,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.HIGHEST_DISEASE_STATUS),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.HIGHEST_DISEASE_STATUS),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12} md={8} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Development Status'
                    heightVariant='large'
                    wrapperClasses={styles.noMarginBottom}
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED,
                    }}
                    filters={props.filters}>
                    <GroupedBarChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12}>
                <a
                    href={entityUrl}
                    target='_blank'
                    rel='noopener noreferrer'>
                    <Typography variant="subtitle1" component="span">
                        <strong>View the drug record on Pharmaprojects</strong>
                    </Typography>
                </a>
            </Grid>
        </Grid >
    );
});
