import * as actions from './actionTypes/notifications.actionTypes';

export function setNotification(message?: string): actions.ISetNotification {
    return {
        type: actions.SET_NOTIFICATION,
        message
    };
}

export function clearNotification(): actions.ISetNotification {
    return {
        type: actions.SET_NOTIFICATION,
        message: undefined
    };
}