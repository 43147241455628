import { createTheme, Theme } from '@mui/material/styles';
import { CORE_COLORS } from './colors';

const appThemeConfig: Theme = createTheme({
	palette: {
		background: {
			default: "#f5f6fa"
		},
		primary: {
			main: CORE_COLORS.PRIMARY
		},
		secondary: {
			main: CORE_COLORS.PURPLE1
		},
		error: {
			main: CORE_COLORS.ERROR
		}
	},
	typography: {
		fontSize: 14,
		fontFamily: [
			'\'FSMe\'',
			'helvetica',
			'sans-serif'
		].join(',')
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				MuiMenuItem: {
					root: {
						paddingBottom: '2px',
						paddingTop: '2px',
						height: '39px',
						'@media (min-width: 600px)': {
							height: '39px'
						}
					}
				},
				MuiFormLabel: {
					asterisk: {
						display: 'none'
					}
				},
				MuiTab: {
					root: {
						textTransform: 'none'
					}
				},
				MuiPaper: {
					root: {
						color: CORE_COLORS.GREY6
					}
				}
			}
		}
	}
});

export const appTheme = createTheme(appThemeConfig);