import { createElement, HTMLProps, ReactNode } from 'react';

function wrapIf<P = HTMLDivElement>(
	condition: boolean,
	children: ReactNode,
	wrapperProps: HTMLProps<P> = {},
	wrapperTag = 'div'
): ReactNode {
	return condition ? createElement(wrapperTag, {
		...wrapperProps,
		children
	}) : children;
}

export default wrapIf;