import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { CounterChart } from '../CounterChart';
import { COUNTER_VARIANTS_MAP } from '@informa/pharma-common-react-components';
import { TileContentProps } from '../../TileContent/typings';
import { CHART_TYPES } from '../../../constants/common';
import { ChartPath } from '../../../typings';

export default (props: TileContentProps) => {
    const DEALS_SUMMARY_CHART_CONFIG = useMemo(() => [
        {
            label: 'Alliance',
            chart: CHART_TYPES.ALLIANCE_COUNT,
            labelColor: "black",
            valueColor: "black",
        },
        {
            label: 'Acquisition',
            chart: CHART_TYPES.ACQUISITION_COUNT,
            labelColor: "#CC3399",
            valueColor: "#CC3399",
        },
        {
            label: 'Financing',
            chart: CHART_TYPES.FINANCING_COUNT
        }
    ], []);

    return (
        <Grid
            container
            direction='column'
            sx={{justifyContent: 'space-around'}}
            data-testid='deals-summary-chart'
        >
            {DEALS_SUMMARY_CHART_CONFIG.map(({ chart, label, labelColor,  valueColor }, idx) => (
                <Grid
                    item
                    key={idx}
                >
                    <CounterChart
                        pathConfig={{ ...props, chart } as ChartPath}
                        componentProps={{
                            label,
                            size: COUNTER_VARIANTS_MAP.MEDIUM,
                            inverted: true,
                            labelColor,
                            valueColor
                        }}
                    />
                </Grid>
            ))}
        </Grid>
    )
};