import React, { useMemo, FunctionComponent, memo } from 'react';
import { useSelector } from 'react-redux';
import _isEqual from 'lodash-es/isEqual';
import cx from 'classnames';
import { ChartPath, EntityType } from '../../../typings';
import { createChartDataSelector } from '../../../core/selectors/charts.selectors';
import TileContentWrapper from '../TileContentWrapper/TileContentWrapper';
import Grid from '@mui/material/Grid';
import { CHART_TYPES, ENTITY_TYPES } from '../../../constants/common';
import { TileContentProps } from '../typings';
import { EventsSummaryChart } from '../../Charts/EventsSummaryChart';
import { CatalystsSummaryChart } from '../../Charts/CatalystsSummaryChart';
import { StackedColumnChart } from '../../Charts/StackedColumnChart';

import styles from './EventsCatalysts.module.scss';

const style = {
    color: 'black',
};

const labels = {
    enabled: true,
    format: '',
    style
};

const commonYAxisConfig = {
    labels,
    allowDecimals: true,
    lineWidth: 0,
    minorGridLineWidth: 1,
    lineColor: 'rgb(220, 220, 220)',
    minorTickLength: 0,
    tickLength: 1,
    gridLineColor: 'rgb(220, 220, 220)'
};

export const eventsYAxisConfig = {
    title: {
        text: 'Number of events',
        style,
    },
    ...commonYAxisConfig
}

export const catalystsYAxisConfig = {
    title: {
        text: 'Number of catalysts',
        style,
    },
    opposite: true,
    ...commonYAxisConfig
};

interface TodayDividerProps extends ChartPath {
    className?: string;
}

export const TodayDivider = (props: TodayDividerProps) => {
    const tileConfig = useMemo(() => props, []) as ChartPath;
    const { isLoading, data } = useSelector(
        createChartDataSelector(tileConfig),
        _isEqual
    );

    return !isLoading && data ? <div className={cx(styles.todayDivider, props.className)}>Today</div> : null;
};

const EventsCatalysts: FunctionComponent<TileContentProps> = (props) => {
    const pathConfig = {
        ...props,
        entity: ENTITY_TYPES.EVENTS_CATALYSTS as EntityType
    };
    const eventsTileConfig = useMemo(() => ({
        ...pathConfig,
        chart: CHART_TYPES.EVENTS_TILE
    }), []) as ChartPath;
    const catalystsTileConfig = useMemo(() => ({
        ...pathConfig,
        chart: CHART_TYPES.CATALYSTS_TILE
    }), []) as ChartPath;

    return (
        <TileContentWrapper testId='forecast-tile-content'>
            <Grid item xs={1}>
                <EventsSummaryChart {...pathConfig} />
            </Grid>
            <Grid
                item
                xs={5}
                classes={{
                    root: cx(styles.todayDividerContainer, styles.eventsCatalystsGrid)
                }}
            >
                <StackedColumnChart
                    pathConfig={eventsTileConfig}
                    componentProps={{
                        label: 'Events',
                        labelPosition: 'bottom',
                        labelAlignment: 'center',
                        options: {
                            chartOptions: {
                                yAxis: eventsYAxisConfig
                            }
                        }
                    }}
                />
                <TodayDivider {...eventsTileConfig} />
            </Grid>
            <Grid
                item
                xs={5}
                classes={{
                    root: styles.eventsCatalystsGrid
                }}
            >
                <StackedColumnChart
                    pathConfig={catalystsTileConfig}
                    componentProps={{
                        label: 'Upcoming Catalysts',
                        labelPosition: 'bottom',
                        labelAlignment: 'center',
                        options: {
                            chartOptions: {
                                yAxis: catalystsYAxisConfig
                            }
                        }
                    }}
                />
            </Grid>
            <Grid item xs={1}>
                <CatalystsSummaryChart {...pathConfig} />
            </Grid>
        </TileContentWrapper>
    );
};

export default memo(EventsCatalysts);