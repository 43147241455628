import * as actionTypes from "../actions/actionTypes/projects.actionTypes";
import uniqBy from "lodash/unionBy";

export interface ProjectsState {
    isLoading: boolean;
    projects: any[];
    error?: any;
    count?: number;
    pagedProjects: actionTypes.Project[];
    currentProject: actionTypes.Project | null;
}

const projectsInitialState: ProjectsState = {
    isLoading: true,
    error: undefined,
    pagedProjects: [],
    projects: [],
    count: undefined,
    currentProject: null
};

export default function projectsReducer(state = projectsInitialState, action: actionTypes.ProjectsAction): ProjectsState {
    switch (action.type) {
        case actionTypes.GET_PROJECTS_PAGED: {
            return {
                ...state,
                error: undefined,
                isLoading: true
            };
        }
        case actionTypes.GET_PROJECTS_PAGED_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: undefined,
                pagedProjects: action.payload.pagedProjects,
                count: action.payload.count,
                projects: uniqBy([
                    ...state.projects,
                    ...action.payload.pagedProjects
                ], 'id')
            };
        }
        case actionTypes.GET_PROJECTS_PAGED_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        }
        case actionTypes.GET_PROJECTS: {
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };
        }
        case actionTypes.GET_PROJECTS_SUCCESS: {
            return {
                ...state,
                error: undefined,
                isLoading: false,
                projects: action.payload.projects,
            };
        }
        case actionTypes.GET_PROJECTS_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        }
        case actionTypes.ADD_PROJECTS: {
            return {
                ...state,
                projects: [ 
                    ...action.payload,
                    ...state.projects
                ]
            }
        }
        case actionTypes.CLEAR_PROJECTS: {
            return {
                ...state,
                projects: [],
                pagedProjects: []
            }
        }
        default:
            return state;
    }
}
