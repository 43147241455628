import { useEffect, useMemo, useState } from 'react';
import _isEqual from 'lodash-es/isEqual';
import _isEmpty from 'lodash-es/isEmpty';
import { ChartPath, ChartType, RawFilterValues } from '../typings';
import { chartDataRequest } from '../core/actions/charts.actions';
import { useDispatch, useSelector } from 'react-redux';
import { createChartDependenciesSelector } from '../core/selectors/charts.selectors';
import { prepareFilters } from '../core/helpers/filter';

export default (
	commonChartPath: Pick<ChartPath, 'search' | 'entity' | 'value'>,
	dependencies: ChartType[] = [],
	useFilters: boolean = false,
	filters: RawFilterValues = {}
) => {
	const dispatch = useDispatch();
	const dependenciesData = useSelector(
		createChartDependenciesSelector(commonChartPath, dependencies),
		_isEqual
	);
	const [isDataRequested, setIsDataRequested] = useState(false);

	const isEveryDependencyReady = useMemo(() => Object.values(dependenciesData).every(
		(chartSlice: any) => !chartSlice.isLoading && chartSlice.data
	), [dependenciesData]);

	useEffect(() => {
		if (!isEveryDependencyReady && !isDataRequested && (useFilters ? !_isEmpty(filters) : true)) {
			dependencies.forEach((chart) => {
				dispatch(chartDataRequest(
					{
						...commonChartPath,
						chart
					},
					useFilters ? prepareFilters(filters || {}) : []
				))
			});

			setIsDataRequested(true);
		}
	}, [isDataRequested]);

	return {
		dependenciesData,
		isEveryDependencyReady
	};
};