import React, { memo, useMemo } from 'react';
import { Map } from '@informa/pharma-common-react-components';
import { ChartCommonProps, ChartRendererProps } from '../ChartContainer/ChartContainer';
import { ChartContainer } from '../ChartContainer';
import { getLocationTypeFromDependencies } from '../../../core/helpers/filter';
import { CHART_TYPES, LOCATION_TYPES } from '../../../constants/common';
import {
    COUNTRY_NAMES_OVERRIDES,
    LATIN_AMERICA_CARIBBEAN,
    RAW_LATIN_AMERICA_CARIBBEAN,
    SPECIAL_REGIONS
} from '../../../constants/geo';
import { applyRegionMapPatch } from '../../../core/helpers/map';

export const countriesMapData = require('@highcharts/map-collection/custom/world-highres2.geo.json');

applyRegionMapPatch(countriesMapData);

const tooltipFormatterFactory = (mapData, formatter) => (point) => {
    const percentage = point.value || 0;

    return formatter(point, percentage);
};

const getCountryTooltip = (point, percentage) => `${point.name}: <b>${percentage}%</b>`;

const getRegionTooltip = (point, percentage) => {
    const pointName = point.properties['region-wb'] === LATIN_AMERICA_CARIBBEAN ?
        point.properties['region-wb'] :
        point.properties.continent;

    return `${pointName}: <b>${percentage} %</b><br/>`;
};

const setPointState = (point, pointState) => {
    const region = point.target.properties['region-wb'];
    const series = point.target.series;

    if (SPECIAL_REGIONS.includes(region)) {
        series.data.forEach(p => {
            if (p.properties && p.properties['region-wb'] === region) {
                p.setState(pointState);
            }
        })
    } else {
        const continent = point.target.properties.continent;
        series.data.forEach(p => {
            if (p.properties && p.properties['continent'] === continent &&
                p.properties['region-wb'] !== LATIN_AMERICA_CARIBBEAN) {
                p.setState(pointState);
            }
        });
    }
};

// extracted this logic as this is p360 related only
const handleMouseOver = (point) => {
    setPointState(point, 'hover');
};

const handleMouseOut = (point) => {
    setPointState(point, '');
};

const getCountriesInContinent = (name): any[] => {
    return countriesMapData.features.filter(({ properties }) => {
        const mappedName = name === RAW_LATIN_AMERICA_CARIBBEAN ?
            LATIN_AMERICA_CARIBBEAN :
            name;

        return [properties.continent, properties['region-wb']].includes(mappedName);
    });
};

const buildRegionMapData = (regionData) => {
    const temp = [];

    regionData.forEach(region => {
        const countries = getCountriesInContinent(region.name);
        const mappedCountries = countries.map(country => ({ name: country.properties.name, value: region.value }));

        // @ts-ignore
        temp.push(...mappedCountries);
    });

    return temp;
};

const mapCountryNames = (data: any[]) => data.map(({ name, ...rest }) => ({
    name: COUNTRY_NAMES_OVERRIDES.hasOwnProperty(name) ? COUNTRY_NAMES_OVERRIDES[name] : name,
    ...rest
}));

const MapChartRenderer = memo(({
    data: { data, color },
    dependenciesData,
    componentProps
}: ChartRendererProps) => {
    if (!data || data.length === 0) {
        return <span>No map data available</span>
    }

    const isCountryLevelData = useMemo(
        () => getLocationTypeFromDependencies(dependenciesData) === LOCATION_TYPES.COUNTRY,
        [dependenciesData]
    );

    const mapData = useMemo(
        () => isCountryLevelData ? mapCountryNames(data) : buildRegionMapData(data),
        [isCountryLevelData, data]
    );

    const tooltipFormatter = useMemo(
        () => tooltipFormatterFactory(
            mapData,
            isCountryLevelData ? getCountryTooltip : getRegionTooltip
        ),
        [isCountryLevelData, mapData]
    );

    const regionMapProps = useMemo(
        () => isCountryLevelData ? {} : {
            onMouseOver: handleMouseOver,
            onMouseOut: handleMouseOut
        },
        [isCountryLevelData]
    );

    return (
        <Map
            toolTipFormatter={tooltipFormatter}
            color={color}
            data={mapData}
            onClick={componentProps?.onClick}
            {...regionMapProps}
        />
    )
});

export default memo((props: ChartCommonProps) => (
    <ChartContainer
        {...props}
        dependencies={[CHART_TYPES.LOCATION_TYPE]}
        component={MapChartRenderer}
    />
));
