import React, { FunctionComponent, memo, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { CounterChart } from '../../Charts/CounterChart';
import { DoughnutChart } from '../../Charts/DoughnutChart';
import { SummaryChart } from '../../Charts/SummaryChart';
import { StackedBarChart } from '../../Charts/StackedBarChart';
import { TileContentProps } from '../typings';
import { ChartType, EntityType } from '../../../typings';
import TileContentWrapper from '../TileContentWrapper/TileContentWrapper';
import { CHART_TYPES, SEARCH_TYPES } from '../../../constants/common';

const TRIALS_STACKED_BAR_CHART_CONFIG = {
      [SEARCH_TYPES.DISEASE]: {
            chart: CHART_TYPES.TOP_DRUGS as ChartType,
            label: 'Top 10 Drugs (Last 10 Years)'
      },
      [SEARCH_TYPES.DRUG]: {
            chart: CHART_TYPES.TOP_DISEASES as ChartType,
            label: 'Top 10 Diseases (Last 10 Years)'
      },
      [SEARCH_TYPES.COMPANY]: {
            chart: CHART_TYPES.TOP_DISEASES as ChartType,
            label: 'Top 10 Diseases (Last 10 Years)'
      }
};

/**
 * Tile content component props should
 * contain the following configuration
 * schema for querying required data
 *
 * const tileContentProps = {
	value: 'Breast', - here you specify the item you've clicked in the search-box dropdown
	search: 'disease', - disease, drug, company
  };
 *
 * `chart` field should be specified directly for each chart component
 */
const Trials: FunctionComponent<TileContentProps> = (props) => {
      const pathConfig = {
            ...props,
            entity: 'trial' as EntityType,
      };

      const { chart, label } = useMemo(
            () => TRIALS_STACKED_BAR_CHART_CONFIG[props.search],
            [props.search]
      );

      return (
            <TileContentWrapper testId='trials-tile-content'>
                  <Grid item xs={12} sm={4} md={2}>
                        <CounterChart
                              pathConfig={{
                                    ...pathConfig,
                                    chart: 'count',
                              }}
                              componentProps={{
                                    label: 'Clinical trials',
                              }}
                        />
                  </Grid>
                  <Grid item xs={12} sm={8} md={4}>
                        <DoughnutChart
                              pathConfig={{
                                    ...pathConfig,
                                    chart: 'phase',
                              }}
                        />
                  </Grid>
                  <Grid item xs={12} sm={4} md={1}>
                        <SummaryChart {...pathConfig} />
                  </Grid>
                  <Grid item xs={12} sm={8} md={5}>
                        <StackedBarChart
                              pathConfig={{
                                    ...pathConfig,
                                    chart
                              }}
                              componentProps={{ label }}
                        />
                  </Grid>
            </TileContentWrapper>
      );
};

export default memo(Trials);
