import { EntityUrlParams, EntityUrlWithFiltersParams } from './../../typings';
import { api } from '../api';

export function getEntityUrl(params: EntityUrlParams): Promise<string> {
    return api.entityUrls.getUrl(params);
}

export function getEntityUrlWithFilters(params: EntityUrlWithFiltersParams): Promise<string> {
    return api.entityUrls.getUrlWithFilters(params);
}
