import {
    DASHBOARD_HEADER_DATA_REQUEST,
    DashboardHeaderAction,
    DATA_ERROR,
    DATA_READY,
    DATA_REQUEST,
    EntityActionPayload,
    EntityErrorAction,
    EntityLoadedAction,
    EntityRequestAction,
    DashboardHeaderActionPayload,
} from './actionTypes/entity.actionTypes';

export function entityDataRequest(pathConfig: EntityActionPayload): EntityRequestAction {
    return {
        type: DATA_REQUEST,
        payload: pathConfig,
    };
}

export function dashboardHeaderDataRequest(payload: DashboardHeaderActionPayload): DashboardHeaderAction {
    return {
        type: DASHBOARD_HEADER_DATA_REQUEST,
        payload
    };
}

export function entityDataLoaded(pathConfig: EntityActionPayload, data: any): EntityLoadedAction {
    return {
        type: DATA_READY,
        payload: {
            ...pathConfig,
            data,
        },
    };
}

export function entityDataError(pathConfig: EntityActionPayload, error: Error | string): EntityErrorAction {
    return {
        type: DATA_ERROR,
        payload: {
            ...pathConfig,
            error,
        },
    };
}
