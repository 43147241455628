import React, { memo } from 'react';
import { ChartRendererProps, ChartCommonProps } from '../ChartContainer/ChartContainer';
import { ChartContainer } from '../ChartContainer';
import { PharmaPivotTable } from '@informa/pharma-common-react-components';
import { DEEP_DIVE_PIVOT_TABLE_CONFIG, PIVOT_TABLE_CONFIG } from '../../../constants/deepDiveConfig';
import styles from './PivotTable.module.scss';

const PivotTableChartRenderer = ({ pathConfig, data }: ChartRendererProps) => {
    const tableConfig: any =
        PIVOT_TABLE_CONFIG[pathConfig.chart] || DEEP_DIVE_PIVOT_TABLE_CONFIG[pathConfig.search][pathConfig.entity];
    const componentHeight: any = data.length > 0 ? tableConfig.height : '100px';
    return (
        <div style={{ marginTop: 15, height: componentHeight, width: '100%', overflow: 'auto' }}>
            {data.length > 0 ? (
                <PharmaPivotTable
                    data={data}
                    rows={tableConfig.rows}
                    columns={tableConfig.columns}
                    values={tableConfig.values}
                />
            ) : (
                <div className={styles.noDataContainer}>
                    <span className={styles.noData}>No Data Available</span>
                </div>
            )}
        </div>
    );
};

const PivotTable = (props: ChartCommonProps) => <ChartContainer {...props} component={PivotTableChartRenderer} />;

export default memo(PivotTable);
