import { FilterConfig, RawFilterValue, RawFilterValues } from '../../typings';
import {
    ANALYTICS_DATE_FORMAT,
    CHART_LEVELS_MAP,
    CHART_TYPES,
    FILTER_NAMES,
    FILTER_TYPES,
} from '../../constants/common';
import _flatten from 'lodash-es/flatten';
import _isFunction from 'lodash-es/isFunction';
import _get from 'lodash-es/get';
import _find from 'lodash-es/find';
import _remove from 'lodash-es/remove';
import _map from 'lodash-es/map';
import _identity from 'lodash-es/identity';
import _isArray from 'lodash-es/isArray';
import { startOfMonth, endOfMonth } from 'date-fns';
import { formatAppDate, getCurrentYear, parseISODate } from './dates';
import { PointClickEventObject } from 'highcharts';
import { CHART_VARIANTS_MAP } from '@informa/pharma-common-react-components';
import { countriesMapData } from '../../components/Charts/MapChart/MapChart';
import {
    COUNTRY_NAMES_TO_CODES_OVERRIDES,
    LATIN_AMERICA_CARIBBEAN,
    LATIN_AMERICA_CARIBBEAN_OPTION,
    RAW_LATIN_AMERICA_CARIBBEAN,
} from '../../constants/geo';
import { startQuarter, endQuarter } from './common';
import { UNSPECIFIED } from '../../constants/analytics';

// Use it to map your multiselect filter values to the request-fit form
const prepareMultiselectFilter = ({ value }: RawFilterValue, filterName?: string) => {
    if (!value) {
        return;
    }
    if (Array.isArray(value)) {
        const values = _flatten(value.map((dataItem) => dataItem.id));

        if (filterName === FILTER_NAMES.REGION && values.includes(LATIN_AMERICA_CARIBBEAN)) {
            _remove(values, LATIN_AMERICA_CARIBBEAN);
            values.push(RAW_LATIN_AMERICA_CARIBBEAN);
        }

        if (
            filterName === FILTER_NAMES.COUNTRY &&
            values.some((value) => COUNTRY_NAMES_TO_CODES_OVERRIDES.hasOwnProperty(value))
        ) {
            const transformedCountries = values
                .filter((value) => COUNTRY_NAMES_TO_CODES_OVERRIDES.hasOwnProperty(value))
                .map((value) => COUNTRY_NAMES_TO_CODES_OVERRIDES[value]);
            _remove(values, (value) => COUNTRY_NAMES_TO_CODES_OVERRIDES.hasOwnProperty(value));
            values.push(...transformedCountries);
        }

        return values;
    }

    return value.id;
};

// Use it to map your date range filter values to the request-fit form
const prepareDateRangeFilter = ({ value }: RawFilterValue, dateFormatString?: string) => {
    return [
        formatAppDate(startOfMonth(new Date(value.fromDate)), dateFormatString),
        formatAppDate(endOfMonth(new Date(value.toDate)), dateFormatString),
    ];
};

const prepareQuarterValue = (value: string) => {
    let [year, quarter] = value.split(value.includes('-') ? '-' : ',');
    quarter = quarter.replace('Q', '');

    return {
        fromDate: startQuarter(parseInt(quarter, 10), parseInt(year, 10)),
        toDate: endQuarter(parseInt(quarter, 10), parseInt(year, 10)),
    };
};

const prepareMonthValueFromShortMonthName = (value: string) => {
    const [month, year] = value.split(',');
    const monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const date = new Date(Date.UTC(parseInt(year, 10), monthShortNames.indexOf(month), 1));
    return constructDateObj(date, endOfMonth(date));
};

// From dates that have this pattern 'YYYY, MM' e.g. '2020, 04'
const prepareFromToDates = (value: string) => {
    const [year, month] = value.split(',');
    const stringDate = `${year.trim()}-${month.trim()}-01`;

    const date = parseISODate(stringDate);
    return constructDateObj(date, endOfMonth(date));
};

const constructDateObj = (fromDate: Date, toDate: Date) => {
    return {
        fromDate,
        toDate,
    };
};

export const prepareFilters = (rawFilters: RawFilterValues) => {
    if (!rawFilters) {
        return [];
    }

    return Object.keys(rawFilters).map((fieldName) => {
        const rawFilter = rawFilters[fieldName];
        let value: any = {};

        switch (rawFilter?.filterType) {
            case FILTER_TYPES.MULTISELECT:
                value = prepareMultiselectFilter(rawFilter, fieldName);
                break;
            case FILTER_TYPES.DATE_RANGE:
                value = prepareDateRangeFilter(rawFilter);
                break;
            default:
                value = rawFilter;
        }

        return {
            fieldName,
            value,
        };
    });
};

export const prepareCitelineFilters = (rawFilters: RawFilterValues) => {
    if (!rawFilters) {
        return [];
    }

    return Object.keys(rawFilters).map((fieldName) => {
        const rawFilter = rawFilters[fieldName];
        let value: any = {};

        switch (rawFilter?.filterType) {
            case FILTER_TYPES.MULTISELECT:
                value = prepareCitelineMultiselectFilter(rawFilter);
                break;
            case FILTER_TYPES.DATE_RANGE:
                value = prepareDateRangeFilter(rawFilter);
                break;
            default:
                value = rawFilter;
        }

        return {
            fieldName,
            value,
        };
    });
}

const prepareCitelineMultiselectFilter = ({ value }: RawFilterValue) => {
    if (!value) {
        return;
    }
    if (Array.isArray(value)) {
        return _flatten(value.map(({ name, filterName }) => filterName || name));
    }

    return value.originalName || value.name;
};

export const createChartLabelClickHandlerFactory = (onClick) => (chartType) => {
    return (chartVariant, axisType, event) =>
        _isFunction(onClick) ? onClick(chartVariant, chartType, event, true) : null;
};

export const createChartClickHandlerFactory = (onClick) => (
    chartType,
    eventCustomizer: (event: any) => any = _identity
) => {
    return (chartVariant, event) =>
        _isFunction(onClick) ? onClick(chartVariant, chartType, eventCustomizer(event)) : null;
};

export const getDataFromChartPointEvent = (event: PointClickEventObject, chartVariant: string, filterName?: string) => {
    const { point } = event;

    switch (chartVariant) {
        case CHART_VARIANTS_MAP.GROUPED_BAR_CHART:
            return (point?.category as any).name;
        case CHART_VARIANTS_MAP.COLUMN_CHART_WITH_LINE:
            return (point?.category as any).name;
        case CHART_VARIANTS_MAP.DOUGHNUT_CHART:
            return point?.name;
        case CHART_VARIANTS_MAP.STACKED_BAR_CHART: {
            if ([
                FILTER_NAMES.DRUG,
                FILTER_NAMES.SPONSORS,
                FILTER_NAMES.DISEASE,
                FILTER_NAMES.PRODUCT,
                FILTER_NAMES.COMPANY,
                FILTER_NAMES.ARTICLE_TYPE,
                FILTER_NAMES.CONTENT_TYPE,
                FILTER_NAMES.TARGET,
                FILTER_NAMES.EVENTS_TYPE,
                FILTER_NAMES.CATALYSTS_TYPE
            ].includes(filterName!)) {
                return (point?.category as any).name;
            }

            return point?.series?.name;
        }
        case CHART_VARIANTS_MAP.STACKED_COLUMN_CHART: {
            if (
                [
                    FILTER_NAMES.TRIAL_START_DATE,
                    FILTER_NAMES.AGE_GROUP,
                    FILTER_NAMES.FORECAST_YEAR,
                    FILTER_NAMES.PUBLISH_DATE
                ].includes(filterName!)
            ) {
                return (point?.category as any).name;
            }

            if ([FILTER_NAMES.SALES_YEAR].includes(filterName!)) {
                return (point?.category as any).name.toString();
            }

            if ([FILTER_NAMES.ARTICAL_PUBLISH_DATE].includes(filterName!)) {
                return prepareFromToDates((point?.category as any).name);
            }

            if ([
                FILTER_NAMES.REPORT_PUBLISH_DATE,
                FILTER_NAMES.EVENTS_DATE,
                FILTER_NAMES.CATALYSTS_DATE
            ].includes(filterName!)
            ) {
                return prepareQuarterValue((point?.category as any).name);
            }

            return point?.series?.name;
        }
        case CHART_VARIANTS_MAP.SPLIT_STACKED_BAR_CHART: {
            return point?.series?.name;
        }
        case CHART_VARIANTS_MAP.SUNBURST_CHART:
            return point?.name;
        case CHART_VARIANTS_MAP.MAP_CHART:
            if (filterName === FILTER_NAMES.REGION) {
                const regionItem = _find(countriesMapData.features, ['properties.name', point?.name]);

                return _get(regionItem, ['properties', 'continent'], null);
            }

            if (COUNTRY_NAMES_TO_CODES_OVERRIDES.hasOwnProperty(point?.name)) {
                return (point as any).isCountryLevel ? COUNTRY_NAMES_TO_CODES_OVERRIDES[point?.name] : null;
            }

            return point?.name;
        case CHART_VARIANTS_MAP.TREEMAP_CHART: {
            return point?.name;
        }
    }

    return null;
};

export const getDataFromChartLabelEvent = (event: { value: any }) => event.value;

export const getDataFromChartEvent = (event: any, chartVariant: string, isLabelClick = false, filterName?: string) => {
    if (isLabelClick) {
        const value: any = getDataFromChartLabelEvent(event);

        if (chartVariant === CHART_VARIANTS_MAP.STACKED_COLUMN_CHART) {
            switch (filterName) {
                case FILTER_NAMES.REPORT_PUBLISH_DATE:
                    return prepareQuarterValue(value);
                case FILTER_NAMES.EVENTS_DATE:
                    return prepareQuarterValue(value);
                case FILTER_NAMES.CATALYSTS_DATE:
                    return prepareQuarterValue(value);
                case FILTER_NAMES.ARTICAL_PUBLISH_DATE:
                    return prepareMonthValueFromShortMonthName(value);
            }
        }

        return value;
    }

    return getDataFromChartPointEvent(event, chartVariant, filterName);
};

export const getChartEventLevel = (event: PointClickEventObject): number => _get(event, 'point.node.level', 0);

export const shouldHandleChartClick = (
    chartVariant: string,
    event: PointClickEventObject,
    isLabelClick: boolean
): boolean => {
    return !(
        chartVariant === CHART_VARIANTS_MAP.SUNBURST_CHART &&
        getChartEventLevel(event) === CHART_LEVELS_MAP.FIRST &&
        chartVariant === CHART_VARIANTS_MAP.GROUPED_BAR_CHART &&
        isLabelClick
    );
};

export const getDataFromDependencies = (dependenciesData: any, path: string) => dependenciesData[path]?.data;

export const getCounterValueFromDependencies = (dependenciesData: any, path: string) => {
    const dataContainer = getDataFromDependencies(dependenciesData, path);

    if (!dataContainer) {
        return null;
    }

    if (dataContainer.hasOwnProperty('c')) {
        return dataContainer.c;
    } else if (dataContainer.hasOwnProperty('value')) {
        return dataContainer.value;
    }

    return null;
};

export const getForecastYearFromDependencies = (dependenciesData: any) =>
    getCounterValueFromDependencies(dependenciesData, CHART_TYPES.MAX_FORECAST_YEAR);

export const getLocationTypeFromDependencies = (dependenciesData: any) =>
    getCounterValueFromDependencies(dependenciesData, CHART_TYPES.LOCATION_TYPE)?.toLowerCase();

export const getWorkaroundFiltersOptions = (filtersOptions: any): any => {
    const resultOptions = Object.assign({}, filtersOptions);

    if (resultOptions.hasOwnProperty(FILTER_NAMES.REGION)) {
        resultOptions.region = resultOptions[FILTER_NAMES.REGION].map((regionOption) => {
            if (regionOption.name === RAW_LATIN_AMERICA_CARIBBEAN) {
                return LATIN_AMERICA_CARIBBEAN_OPTION;
            }

            return regionOption;
        });
    }

    return resultOptions;
};

export const serializeFiltersForAnalytics = (rawFilters: RawFilterValues, filtersConfig: FilterConfig) =>
    Object.keys(rawFilters)
        .map((filterKey) => {
            const { filterType, value, singleMode } = rawFilters[filterKey];
            const filterConfigEntry = Array.isArray(filtersConfig)
                ? filtersConfig.find((configEntry) => configEntry.hasOwnProperty(filterKey))
                : filtersConfig;
            const filterPrefix = `${filterConfigEntry[filterKey].label}: `;

            if (filterType === FILTER_TYPES.MULTISELECT) {
                return filterPrefix.concat(
                    singleMode
                        ? value
                        : Array.isArray(value) && value.length
                            ? _map(value, 'name').join(' | ')
                            : value.hasOwnProperty('name')
                                ? value.name
                                : UNSPECIFIED
                );
            }

            if (filterType === FILTER_TYPES.DATE_RANGE) {
                return filterPrefix.concat(
                    prepareDateRangeFilter(rawFilters[filterKey], ANALYTICS_DATE_FORMAT).join(' to ')
                );
            }

            return UNSPECIFIED;
        })
        .join(' || ');

export const saleDefaultActionYear = (options: Array<{id: Array<string>, name: number}>) => {
        let value = options.find(({ name }) => name === (getCurrentYear() - 1));
        if (!value && _isArray(options)) {
            value = options[0];
        }
        return value;
}
