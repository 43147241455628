import React from 'react';
import classnames from 'classnames';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import styles from './SubscriptionIcon.module.scss';

interface ISubscriptionIconProps {
    isActive: boolean;
    variant?: 'bright' | 'normal';
}

const SubscriptionIcon: React.FunctionComponent<ISubscriptionIconProps> = (props) => {

    const colourClassName = props.variant === 'bright' ? styles.activeSubIconBright : styles.activeSubIcon;

    if (props.isActive) {
        return <CheckCircleIcon data-testid="active-subscription" fontSize="large"
            className={classnames(colourClassName, styles.subscriptionIcon)} />;
    }

    return <RemoveCircleIcon data-testid="inactive-subscription" fontSize="large"
        className={classnames(styles.inactiveSubIcon, styles.subscriptionIcon)} />;
};

export default SubscriptionIcon;
