import React from "react";
import { Link } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/Visibility';
import IconButton from "@mui/material/IconButton";

import styles from './ViewMore.module.scss';

export interface IViewMoreProps {
    url?: any;
    text?: string;
    onClick?: () => void;
    classNames?: any;
}

const ViewMore: React.FunctionComponent<IViewMoreProps> = (props) => {
    if (props.text) {
        return <span className={styles.text} onClick={props.onClick}>{props.text}</span>;
    }

    if (props.url) {
        return (
            <IconButton
                aria-label="View more"
                size="small"
                component={Link}
                to={props.url}
                onClick={props.onClick || (() => null)}
                data-testid="url"
                className="flex-center"
                color="inherit"
            >
                <VisibilityOutlinedIcon
                    fontSize="small"
                    data-testid="view-more-icon"
                />
            </IconButton>
        );
    }

    return null;
};

export default ViewMore;