import * as actions from './actionTypes/projects.actionTypes';
import { Project } from './actionTypes/projects.actionTypes';

export function getProjectsPagedRequest({
    pageSize,
    page,
    order
}: actions.IGetProjectsPaged): actions.GetProjectsPagedAction {
    return {
        type: actions.GET_PROJECTS_PAGED,
        payload: {
            pageSize,
            page,
            order
        },
    };
}

export function getProjectsPagedSuccess({
    pagedProjects,
    count,
}: actions.IGetProjectsPagedSuccess): actions.GetProjectsPagedSuccessAction {
    return {
        type: actions.GET_PROJECTS_PAGED_SUCCESS,
        payload: {
            pagedProjects,
            count,
        },
    };
}

export function getProjectsPagedError(data: any): actions.GetProjectsPagedErrorAction {
    return {
        type: actions.GET_PROJECTS_PAGED_ERROR,
        payload: data,
    };
}

export function getProjectsSuccess({ projects }: actions.IGetProjectsSuccess): actions.GetProjectsSuccessAction {
    return {
        type: actions.GET_PROJECTS_SUCCESS,
        payload: {
            projects,
        },
    };
}

export function getProjectsError(data: any): actions.GetProjectsErrorAction {
    return {
        type: actions.GET_PROJECTS_ERROR,
        payload: data,
    };
}

export function getProjectsRequest(): actions.GetProjectsAction {
    return {
        type: actions.GET_PROJECTS
    };
}

export function addProjects(projects: Array<Project>): actions.SaveProjectsAction {
    return {
        type: actions.ADD_PROJECTS,
        payload: projects
    }
}

export function clearProjects(): actions.ClearProjectsAction {
    return {
        type: actions.CLEAR_PROJECTS
    }
}
