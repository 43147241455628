import { AppState } from '../reducers/root.reducer';
import { createSelector } from 'reselect';
import { TILE_CONFIG } from '../../constants/tileConfig';
import { SearchType, FEATURE, DashboardSectionType } from '../../typings';
import { SUPPORTED_DEEP_DIVES } from '../../constants/deepDiveConfig';
import { ENTITY_TYPES, DASHBOARD_SECTION_TYPES, SEARCH_TYPES } from '../../constants/common';

const emptyUser: any = {};
const emptyFeatures = [];

export const DEEPDIVE_FEATURE_CONFIG = {
    [ENTITY_TYPES.TRIAL]: FEATURE.TRIALS,
    [ENTITY_TYPES.DEALS]: FEATURE.DEALS,
    [ENTITY_TYPES.PIPELINE]: FEATURE.PIPELINE,
    [ENTITY_TYPES.EPIDEMIOLOGY]: FEATURE.EPIDEMIOLOGY,
    [ENTITY_TYPES.REPORTS]: FEATURE.REPORTS,
    [ENTITY_TYPES.NEWS]: FEATURE.INSIGHTS,
    [ENTITY_TYPES.FORECAST]: FEATURE.FORECAST,
    [ENTITY_TYPES.EVENTS_CATALYSTS]: FEATURE.EVENTS_AND_CATALYSTS
}

export const SUPPORTED_ENTITY_SECTIONS: Record<SearchType, DashboardSectionType[]> = {
    [SEARCH_TYPES.DRUG]: [
        DASHBOARD_SECTION_TYPES.NAME,
        DASHBOARD_SECTION_TYPES.OTHER_NAMES,
        DASHBOARD_SECTION_TYPES.DETAILS,
        DASHBOARD_SECTION_TYPES.SUMMARY,
        DASHBOARD_SECTION_TYPES.TOTAL_REPORTS_COUNT,
        DASHBOARD_SECTION_TYPES.MOST_RECENTLY_PUBLISHED_REPORT,
        DASHBOARD_SECTION_TYPES.TOTAL_ARTICLES_COUNT,
        DASHBOARD_SECTION_TYPES.MOST_RECENT_PUBLISHED_ARTICLES,
        DASHBOARD_SECTION_TYPES.DATA_MONITOR_DATA,
    ],
    [SEARCH_TYPES.COMPANY]: [
        DASHBOARD_SECTION_TYPES.NAME,
        DASHBOARD_SECTION_TYPES.OTHER_NAMES,
        DASHBOARD_SECTION_TYPES.SUMMARY,
        DASHBOARD_SECTION_TYPES.TOTAL_REPORTS_COUNT,
        DASHBOARD_SECTION_TYPES.MOST_RECENTLY_PUBLISHED_REPORT,
        DASHBOARD_SECTION_TYPES.TOTAL_ARTICLES_COUNT,
        DASHBOARD_SECTION_TYPES.MOST_RECENT_PUBLISHED_ARTICLES,
        DASHBOARD_SECTION_TYPES.DATA_MONITOR_DATA,
    ],
    [SEARCH_TYPES.DISEASE]: [
        DASHBOARD_SECTION_TYPES.NAME,
        DASHBOARD_SECTION_TYPES.OTHER_NAMES,
        DASHBOARD_SECTION_TYPES.SUMMARY,
        DASHBOARD_SECTION_TYPES.TOTAL_REPORTS_COUNT,
        DASHBOARD_SECTION_TYPES.MOST_RECENTLY_PUBLISHED_REPORT,
        DASHBOARD_SECTION_TYPES.TOTAL_ARTICLES_COUNT,
        DASHBOARD_SECTION_TYPES.MOST_RECENT_PUBLISHED_ARTICLES,
        DASHBOARD_SECTION_TYPES.DATA_MONITOR_DATA,
    ],
};

const DASHBOARD_SECTIONS_BASED_ON_FEATURES = [
    {
        featureName: FEATURE.REPORTS,
        sections: [
            DASHBOARD_SECTION_TYPES.TOTAL_REPORTS_COUNT,
            DASHBOARD_SECTION_TYPES.MOST_RECENTLY_PUBLISHED_REPORT,
            DASHBOARD_SECTION_TYPES.DATA_MONITOR_DATA
        ],
    },
    {
        featureName: FEATURE.INSIGHTS,
        sections: [
            DASHBOARD_SECTION_TYPES.TOTAL_ARTICLES_COUNT,
            DASHBOARD_SECTION_TYPES.MOST_RECENT_PUBLISHED_ARTICLES,
        ]
    },
    {
        featureName: FEATURE.DRUG_SUMMARY,
        sections: [
            DASHBOARD_SECTION_TYPES.DETAILS,
        ]
    }
];

const DASHBOARD_SUMMARY_SECTION_FEATURES = [FEATURE.REPORTS, FEATURE.INSIGHTS, FEATURE.DRUG_SUMMARY];

export const getUser = (state: AppState): any => state?.userData?.user || emptyUser;

export const getUserFeatures = createSelector(getUser, (user) => user?.features || emptyFeatures);

export const isFeatureEnabled = (state, featureName) => {
    const features = getUserFeatures(state);
    return hasFeature(features, featureName);
};

const hasFeature = (features, featureName) => {
    return features.includes(featureName);
};

export const getTilesBasedOnFeatures = createSelector(getUserFeatures,
    (features: string[]) => TILE_CONFIG.filter(x => hasFeature(features, x.feature)));

export const getDeepDivesBasedOnFeatures = (searchType: SearchType) => createSelector(getUserFeatures,
    (features: string[]) => {
        const allDeepDives = SUPPORTED_DEEP_DIVES[searchType];
        const allowedDeepDives = allDeepDives.filter(dd => features.includes(DEEPDIVE_FEATURE_CONFIG[dd]));
        return allowedDeepDives;
    });

export const getSummarySectionsBasedOnFeatures = (searchType: SearchType) => createSelector(getUserFeatures,
    (features: string[]) => {
        const sectionsForEntity = SUPPORTED_ENTITY_SECTIONS[searchType];
        const userFeatureSections = features.filter(x => DASHBOARD_SUMMARY_SECTION_FEATURES.includes(x as FEATURE));

        const sectionsToRemove = DASHBOARD_SECTIONS_BASED_ON_FEATURES
            .filter(x => !userFeatureSections.includes(x.featureName))
            .map(x => x.sections).flat();

        return sectionsForEntity.filter(section => !sectionsToRemove.includes(section));
    });