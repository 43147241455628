import React, { FunctionComponent, memo } from 'react';
import Grid from '@mui/material/Grid';
import TileContentWrapper from './TileContentWrapper/TileContentWrapper';
import { TileContentProps } from './typings';
import { EntityType } from '../../typings';
import { DoughnutChart } from '../Charts/DoughnutChart';
import { ForecastSummaryChart } from '../Charts/SummaryChart';
import { ColumnChartWithLine } from '../Charts/ColumnChartWithLine';
import { CHART_TYPES } from '../../constants/common';
import { formatCurrency } from '../../core/helpers/number';
import { getCurrentYear } from '../../core/helpers/dates';

const Forecast: FunctionComponent<TileContentProps> = (props) => {
    const pathConfig = {
        ...props,
        entity: 'forecast' as EntityType,
    };

    return (
        <TileContentWrapper testId='forecast-tile-content'>
            <Grid item xs={4}>
                <DoughnutChart
                    pathConfig={{
                        ...pathConfig,
                        chart: CHART_TYPES.REVENUE_PER_TA_CURRENT_YEAR,
                    }}
                    componentProps={{
                        label: `Revenue by Therapeutic Area, ${getCurrentYear() - 1}`,
                        dataLabelFormat: '{point.name}<br> ({point.percentage:.2f}%)',
                    }}
                    isCurrency
                />
            </Grid>
            <Grid item xs={2}>
                <ForecastSummaryChart
                    pathConfig={{
                        ...pathConfig,
                        chart: CHART_TYPES.REVENUE_REGION_ALL,
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <ColumnChartWithLine
                    pathConfig={{
                        ...pathConfig,
                        chart: CHART_TYPES.TOTAL_REVENUE_PER_YEAR,
                    }}
                    componentProps={{
                        barDataAxisName: 'Sales',
                        lineDataAxisName: 'Annual Growth Change %',
                        lineUnit: '{value} %',
                        options: {
                            chartOptions: {
                                tooltip: {
                                    formatter: function () {
                                        const data: any = this;

                                        const value: any =
                                            data?.series?.options?.type === 'column'
                                                ? formatCurrency(data?.y)
                                                : data?.y;
                                        return `<b>${data?.x?.name}</b><br/>${data?.series?.name}: ${value}<br/>`;
                                    },
                                },
                                yAxis: [
                                    {
                                        allowDecimals: true,
                                    },
                                    {
                                        allowDecimals: true,
                                        labels: {
                                            formatter: function () {
                                                //@ts-ignore
                                                return formatCurrency(this.value);
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    }}
                    isCurrency
                />
            </Grid>
        </TileContentWrapper>
    );
};

export default memo(Forecast);
