/* istanbul ignore file */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as serviceWorker from './serviceWorker';
import configureStore from './core/store/configure-store';
import AppContainer from './AppContainer';
import { Provider } from 'react-redux';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { appTheme } from './constants/theme';
import CssBaseline from '@mui/material/CssBaseline';
import { Auth0Provider } from '@auth0/auth0-react';
import { clientOptions } from './hooks/useSSOAuthenticator';
import './main.scss';

const history = createBrowserHistory();
const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ThemeProvider theme={appTheme}>
                    <CssBaseline />
                    <Auth0Provider {...clientOptions} useCookiesForTransactions={true}>
                        <AppContainer />
                    </Auth0Provider>
                </ThemeProvider>
            </LocalizationProvider>
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
