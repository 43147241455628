import React from 'react';
import { Switch, RouteComponentProps, Route } from 'react-router-dom';

import App from './App';
import { PrivateRoute } from './components/AuthComponents/PrivateRoute/PrivateRoute';
import BaseLayout from './components/BaseLayout/BaseLayout';
import LoginCallback from './components/AuthComponents/LoginCallback/LoginCallback';

export interface IAppRoutingProps extends RouteComponentProps {
    location: any;
}

export default class AppRouting extends React.PureComponent<IAppRoutingProps> {
    public render() {
        return (
            <BaseLayout location={this.props.location}>
                <Switch>
                    <Route path='/' component={App} />
                    <PrivateRoute path='/login/callback' component={LoginCallback} />
                </Switch>
            </BaseLayout>
        );
    }
}
