import React, { FC, useCallback, useEffect } from 'react';
import ProgressBar from '../../ProgressBar/ProgressBar';
import { useAuth0 } from '@auth0/auth0-react';
import { clientOptions, localStorageToken } from '../../../hooks/useSSOAuthenticator';
import { useHistory } from 'react-router-dom';
import { HOME_ROUTE } from '../../../constants/routes';
import { logout } from '../../../core/services/auth.service';

const ProfileCallback: FC = () => {
    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
    const history = useHistory();
    const refreshTokenCallback = useCallback(async (): Promise<string | undefined> => {
        let refreshToken;
        try {
            refreshToken = await getAccessTokenSilently({...clientOptions, cacheMode: 'off'});
        } catch (e) {  
            try {
                refreshToken  = await getAccessTokenWithPopup({...clientOptions, cacheMode: 'off'})
            } catch (popError) {
                logout()                
            }
        } finally {
            if (refreshToken) {
                localStorage.setItem(localStorageToken, refreshToken);
            }
            return refreshToken;
        }
    }, [getAccessTokenSilently, getAccessTokenWithPopup]);

    useEffect(() => {
        refreshTokenCallback().then(() => {
            history.push(HOME_ROUTE)
        }).catch((error) => console.log(error))
    }, [history, refreshTokenCallback])

    return (
        <ProgressBar/>
    )
}

export default ProfileCallback;