import {
    PipelineTileContent,
    TrialsTileContent,
    DealsTileContent,
    EpiTileContent,
    ForecastTileContent,
    DrugForecastTileContent,
    DiseaseForecastTileContent,
    EventsCatalystsTileContent,
} from '../components/TileContent';
import { ENTITY_TYPES, SEARCH_TYPES, PROVIDERS } from './common';
import { TileConfiguration, FEATURE } from '../typings';
import { PRODUCT_URLS } from '../core/api/endpoints';

export const TILE_CONFIG: TileConfiguration = [
    {
        title: 'Pipeline',
        entity: ENTITY_TYPES.PIPELINE,
        deepDiveKey: ENTITY_TYPES.PIPELINE,
        provider: PROVIDERS[ENTITY_TYPES.PIPELINE],
        productUrl: PRODUCT_URLS.citeline,
        enquireUrl:  'https://www.citeline.com/en/products-services/clinical/pharmaprojects',
        content: PipelineTileContent,
        feature: FEATURE.PIPELINE
    },
    {
        title: 'Epidemiology',
        entity: ENTITY_TYPES.EPIDEMIOLOGY,
        deepDiveKey: ENTITY_TYPES.EPIDEMIOLOGY,
        provider: PROVIDERS[ENTITY_TYPES.EPIDEMIOLOGY],
        productUrl: PRODUCT_URLS.datamonitorhealthcare,
        enquireUrl:
        'https://www.citeline.com/en/products-services/commercialization/datamonitor-healthcare',
        content: EpiTileContent,
        displayFor: [SEARCH_TYPES.DISEASE],
        feature: FEATURE.EPIDEMIOLOGY
    },
    {
        title: 'Sales Forecast',
        entity: ENTITY_TYPES.FORECAST,
        deepDiveKey: ENTITY_TYPES.FORECAST,
        provider: PROVIDERS[ENTITY_TYPES.FORECAST],
        feature: FEATURE.FORECAST,
        productUrl: PRODUCT_URLS.datamonitorhealthcare,
        enquireUrl:
        'https://www.citeline.com/en/products-services/commercialization/datamonitor-healthcare',
        searchTypeConfig: {
            [SEARCH_TYPES.DRUG]: {
                content: DrugForecastTileContent,
            },
            [SEARCH_TYPES.COMPANY]: {
                content: ForecastTileContent,
            },
            [SEARCH_TYPES.DISEASE]: {
                content: DiseaseForecastTileContent
            },
        },
    },
    {
        title: 'Trials',
        entity: ENTITY_TYPES.TRIAL,
        provider: PROVIDERS[ENTITY_TYPES.TRIAL],
        deepDiveKey: ENTITY_TYPES.TRIAL,
        productUrl: PRODUCT_URLS.citeline,
        enquireUrl: 'https://www.citeline.com/en/products-services/clinical/trialtrove',
        content: TrialsTileContent,
        feature: FEATURE.TRIALS
    },
    {
        title: 'Deals',
        entity: ENTITY_TYPES.DEALS,
        provider: PROVIDERS[ENTITY_TYPES.DEALS],
        deepDiveKey: ENTITY_TYPES.DEALS,
        productUrl: PRODUCT_URLS.biomedtracker,
        enquireUrl: 'https://www.citeline.com/en/products-services/commercialization/biomedtracker',
        content: DealsTileContent,
        innerTitleBuilder: (value: string) => `10 year view`,
        feature: FEATURE.DEALS
    },
    {
        title: 'Events & Catalysts',
        entity: ENTITY_TYPES.EVENTS_CATALYSTS,
        provider: PROVIDERS[ENTITY_TYPES.EVENTS_CATALYSTS],
        deepDiveKey: ENTITY_TYPES.EVENTS_CATALYSTS,
        productUrl: PRODUCT_URLS.biomedtracker,
        enquireUrl: 'https://www.citeline.com/en/products-services/commercialization/biomedtracker',
        content: EventsCatalystsTileContent,
        feature: FEATURE.EVENTS_AND_CATALYSTS
    }
];
