import React, { useState, useRef, FC, useMemo, useCallback, RefObject } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { logout, isAuthenticated, userHasAccessToPharma360 } from '../../core/services/auth.service';
import SubscriptionIcon from '../SubscriptionIcon/SubscriptionIcon';
import { IHeaderMenuProps } from './IHeaderMenu.interface';
import MenuPopper from '../MenuPopper/MenuPopper';
import { getUser } from '../../core/selectors/user.selectors';
import { PHARMA_360_CLAIM } from '../../constants/common';
import styles from './HeaderMenu.module.scss';

const isSSOAuthenticator = process.env.REACT_APP_IS_OATH === 'true';

export const HeaderMenu: FC<IHeaderMenuProps> = ({
    subscriptions,
    ssoLogOut
}) => {
    const user = useSelector(getUser);
    const anchorRef = useRef(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const logOut = useCallback((): void => {
        setIsOpen(false)
        process.env.REACT_APP_IS_OATH ? ssoLogOut() : logout();
    }, [ssoLogOut]);

    const renderProductMenuItems = useMemo(() => {
        return subscriptions
            .filter((item) => item.name.toLowerCase() !== PHARMA_360_CLAIM)
            .map((x) => (
                <MenuItem key={x.name} disableRipple={true}>
                    <SubscriptionIcon isActive={x.isActive} variant='normal' />
                    {x.name}
                </MenuItem>
            ));
    }, [subscriptions])

    const renderMenu = useMemo(() => {
        const showSubscriptions = isAuthenticated() && userHasAccessToPharma360() && !isSSOAuthenticator;
        const displayName = [user?.firstName, user?.lastName].filter(Boolean).join(' ');
        const nameTitle = displayName.length ? displayName : 'unknown';

        return (
            <div data-testid='menu'>
                <div className={styles.userSection}>
                    <div className={styles.userLogo}>{user.initials}</div>
                    <Typography variant='subtitle1'>
                        <strong>{nameTitle}</strong>
                    </Typography>
                </div>
                <div className={styles.menuItemsSection}>
                    {showSubscriptions && (
                        <Typography variant='subtitle1'>
                            <strong>Subscriptions</strong>
                        </Typography>
                    )}
                    <MenuList
                        autoFocusItem={false}
                        id='menu-list-grow'
                        disablePadding={true}
                        className={styles.menuListItems}>
                        {!isSSOAuthenticator && renderProductMenuItems}
                        <MenuItem onClick={logOut}>Log out</MenuItem>
                    </MenuList>
                </div>
            </div>
        );
    }, [logOut, renderProductMenuItems, user.firstName, user.initials, user.lastName])

    const handleClose = (): void => {
       setIsOpen(false)
    };

    const handleToggle = useCallback((): void => {
        setIsOpen(!isOpen)
    }, [isOpen]);

        return (
            <div data-testid='menu-wrapper'>
                <button
                    className={styles.menuIcon}
                    data-testid='menu-icon'
                    ref={anchorRef as RefObject<HTMLButtonElement>}
                    aria-controls={isOpen ? 'menu-list-grow' : undefined}
                    aria-haspopup='true'
                    onClick={handleToggle}>
                    <span className={styles.userLogo}>{user.initials}</span>
                </button>
                <MenuPopper
                    isOpen={isOpen}
                    anchorRef={anchorRef}
                    onClickAway={handleClose}
                    position='center top'>
                    {renderMenu}
                </MenuPopper>
            </div>
        );
    }

export default HeaderMenu;
