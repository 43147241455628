import React, { memo, FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';
import { COUNTER_VARIANTS_MAP } from '@informa/pharma-common-react-components';
import { CounterChart } from '../CounterChart';
import { TileContentProps } from '../../TileContent/typings';
import { CHART_TYPES } from '../../../constants/common';
import { EntityType, RawFilterValues } from '../../../typings';

const CATALYSTS_COUNTER_COLOR = {
    LARGE: '#391F56',
    OTHER: '#864FC2'
};

interface SummaryChartProps extends Omit<TileContentProps, 'chart'> {
    entity: EntityType;
    filters?: RawFilterValues;
}

const CatalystSummary: FunctionComponent<SummaryChartProps> = (props) => (
    <Grid
        container
        direction='column'
        sx={{justifyContent:'space-between'}}
        data-testid='catalysts-summary-chart'
    >
        <CounterChart
            pathConfig={{ ...props, chart: CHART_TYPES.UPCOMING_CATALYSTS_COUNT }}
            componentProps={{
                label: 'Catalysts in next 12 months',
                size: COUNTER_VARIANTS_MAP.MEDIUM,
                inverted: true,
                labelAlignment: 'center',
            }}
        />
        <CounterChart
            pathConfig={{ ...props, chart: CHART_TYPES.LARGE_CATALYSTS_COUNT }}
            componentProps={{
                label: 'Large',
                size: COUNTER_VARIANTS_MAP.MEDIUM,
                labelColor: CATALYSTS_COUNTER_COLOR.LARGE,
                valueColor: CATALYSTS_COUNTER_COLOR.LARGE,
                inverted: true
            }}
        />
        <CounterChart
            pathConfig={{ ...props, chart: CHART_TYPES.OTHER_CATALYSTS_COUNT }}
            componentProps={{
                label: 'Other',
                size: COUNTER_VARIANTS_MAP.MEDIUM,
                labelColor: CATALYSTS_COUNTER_COLOR.OTHER,
                valueColor: CATALYSTS_COUNTER_COLOR.OTHER,
                inverted: true
            }}
        />
    </Grid>
);

export default memo(CatalystSummary);