import _find from 'lodash-es/find';
import { RAW_NORTHERN_AMERICA } from '../../constants/geo';

const FEATURE_TO_PATCH_IDS = ['US', 'CA', 'GL'];
const PATCH_NAME = RAW_NORTHERN_AMERICA;

export const applyRegionMapPatch = (countriesMapData) => {
    const featuresToPatch = FEATURE_TO_PATCH_IDS.map(
        (featureID) => _find(countriesMapData.features, ['id', featureID])
    );

    featuresToPatch.forEach((feature) => {
        feature.properties = {
            ...feature.properties,
            'region-wb': PATCH_NAME,
            continent: PATCH_NAME
        };
    });
};