import React, { memo } from 'react';
import { PharmaColumnChartWithLine } from '@informa/pharma-common-react-components';
import { ChartCommonProps, ChartRendererProps } from '../ChartContainer/ChartContainer';
import { ChartContainer } from '../ChartContainer';
import { formatCurrency } from '../../../core/helpers/number';
import _isUndefined from 'lodash-es/isUndefined';

export const ColumnChartWithLineRenderer = ({ data, componentProps }: ChartRendererProps) => {
    const hasZeroValueBars = data?.bars && (data.bars || []).some(x => x === 0);

    const defaultOptions: any = {
        chartOptions: {
            tooltip: {
                formatter: function () {
                    const data: any = this;
                    const value: any = data?.series?.options?.type === 'line' ? formatCurrency(data?.y) : data?.y;
                    return `<b>${data?.x?.name}</b><br/>${data?.series?.name}: ${value}<br/>`;
                },
            },
            yAxis: [
                {
                    allowDecimals: true,
                    labels: {
                        formatter: function () {
                            //@ts-ignore
                            return formatCurrency(this.value);
                        },
                    },
                },
                {
                    allowDecimals: false,
                },
            ]
        },
    };

    const options = _isUndefined(componentProps?.options) ? defaultOptions : componentProps?.options;

    if (hasZeroValueBars) {
        options.chartOptions.plotOptions = {
            column: {
                minPointLength: 0
            }
        }
    }

    return (
        <PharmaColumnChartWithLine
            barDataName={componentProps?.barDataAxisName || 'Number of Deals'}
            lineDataName={componentProps?.lineDataAxisName || 'Value'}
            barUnit={componentProps?.barUnit || ''}
            lineUnit={componentProps?.lineUnit || ''}
            data={data}
            onClick={componentProps?.onClick}
            onLabelClick={componentProps?.onLabelClick}
            options={options}
        />
    );
};

export default memo((props: ChartCommonProps) => <ChartContainer {...props} component={ColumnChartWithLineRenderer} />);
