export const GET_SUBSCRIPTIONS = "SUBSCRIPTIONS/GET_SUBSCRIPTIONS";
export interface IGetSubscriptions {
    type: typeof GET_SUBSCRIPTIONS;
}

export const GET_SUBSCRIPTIONS_SUCCESS = "SUBSCRIPTIONS/GET_SUBSCRIPTIONS_SUCCESS";
export interface IGetSubscriptionsSuccess {
    type: typeof GET_SUBSCRIPTIONS_SUCCESS;
    subscriptions: Array<any>;
}

export const GET_SUBSCRIPTIONS_ERROR = "SUBSCRIPTIONS/GET_SUBSCRIPTIONS_ERROR";
export interface IGetSubscriptionsError {
    type: typeof GET_SUBSCRIPTIONS_ERROR;
    error: Error | string;
}

export type SubscriptionAction =
    | IGetSubscriptions
    | IGetSubscriptionsSuccess
    | IGetSubscriptionsError;
