import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { FC, ComponentType } from 'react';

interface IGuardComponentPropsType {
    component: ComponentType,
    props: any
}

const GuardComponent:FC<IGuardComponentPropsType> = ({ component, props }) => {
    const GuardComponent = withAuthenticationRequired(component, {
            returnTo: window.location.pathname + window.location.search ?? ''
        }); 

    return <GuardComponent {...props} />
} 

export default GuardComponent;