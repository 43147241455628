import { buildEntityUrlPath } from './../helpers/common';
import * as actionTypes from '../actions/actionTypes/entityUrl.actionTypes';

export interface EntityUrlDataState {
    isLoading: boolean;
    error: any;
    url: string | null;
}

const entityUrlInitialState: EntityUrlDataState = {
    isLoading: false,
    error: undefined,
    url: null,
};

export default function searchReducer(
    state = entityUrlInitialState,
    action: actionTypes.EntityUrlAction
): EntityUrlDataState {
    switch (action.type) {
        case actionTypes.GET_ENTITY_URL_WITH_FILTERS_DATA:
        case actionTypes.GET_ENTITY_URL_DATA: {
            const entityPath = buildEntityUrlPath({ ...action.payload });
            return {
                ...state,
                [entityPath]: {
                    error: undefined,
                    isLoading: true,
                    url: undefined,
                },
            };
        }
        case actionTypes.GET_ENTITY_URL_WITH_FILTERS_SUCESS:
        case actionTypes.GET_ENTITY_URL_SUCCESS: {
            const entityPath = buildEntityUrlPath({ ...action.payload });
            return {
                ...state,
                [entityPath]: {
                    isLoading: false,
                    error: undefined,
                    url: action.payload.url,
                },
            };
        }
        case actionTypes.GET_ENTITY_URL_WITH_FILTERS_ERROR:
        case actionTypes.GET_ENTITY_URL_ERROR: {
            const entityPath = buildEntityUrlPath({ ...action.payload });
            return {
                ...state,
                [entityPath]: {
                    isLoading: false,
                    error: action.payload.error,
                },
            };
        }

        default:
            return state;
    }
}
