import React, { memo } from 'react';
import { PharmaBarChart } from '@informa/pharma-common-react-components';
import { ChartCommonProps, ChartRendererProps } from '../ChartContainer/ChartContainer';
import { ChartContainer } from '../ChartContainer';
import { formatCurrency } from '../../../core/helpers/number';

const BarChartRenderer = ({ data, componentProps }: ChartRendererProps) => {
    const getTooltip = (point) => {
        const value = formatCurrency(point?.options.y);
        return `${point?.category?.name} ${point?.series?.name}: <b>${value}</b><br/>`;
    };

    return (
        <React.Fragment>
            {componentProps && componentProps?.label && <h4>{componentProps.label}</h4>}
            <PharmaBarChart
                series={data.series}
                categories={data.categories}
                onClick={componentProps?.onClick}
                onLabelClick={componentProps?.onLabelClick}
                options={{
                    chartOptions: {
                        legend: {
                            enabled: true,
                        },
                        tooltip: {
                            formatter: function () {
                                //@ts-ignore
                                return getTooltip(this.point);
                            },
                        },
                        plotOptions: {
                            bar: {
                                stacking: null,
                            },
                        },
                    },
                }}
            />
        </React.Fragment>
    );
};

const BarChart = (props: ChartCommonProps) => <ChartContainer {...props} component={BarChartRenderer} />;

export default memo(BarChart);
