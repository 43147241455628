import { EntityUrlActionPayload } from './../actions/actionTypes/entityUrl.actionTypes';
import _pick from 'lodash-es/pick';
import _mapValues from 'lodash-es/mapValues';
import _values from 'lodash-es/values';
import { EntityActionPayload } from '../actions/actionTypes/entity.actionTypes';
import { ChartPath } from '../../typings';

const buildPath = <PathConfigType>(pathConfig: PathConfigType, configSchema: string[]): string => {
    const values = _pick(pathConfig, configSchema);

    return _values(_mapValues(values, (value: string) => value.replace(/\s+/g, '').toLowerCase())).join('/');
};

export const buildChartPath = (pathConfig: ChartPath): string => {
    const pathConfigSchema = ['search', 'entity', 'chart', 'value'];

    if (pathConfig.subPath) {
        pathConfigSchema.push('subPath');
    }

    return buildPath(pathConfig, pathConfigSchema);
};

export const buildEntityUrlPath = (pathConfig: EntityUrlActionPayload): string =>
    buildPath(pathConfig, ['search', 'entity', 'value']);

export const buildEntityPath = (pathConfig: EntityActionPayload): string =>
    buildPath(pathConfig, ['entityType', 'entityId', 'sectionType']);

export const stringArrayToString = (values: string[], maxLen: number): string[] => {
    let len = 0;
    return values
        .map((s, i) => {
            if (i > 0) {
                len += values[i - 1].length;
            }
            return {
                name: s,
                totalLen: s.length + len,
            };
        })
        .filter((item) => item.totalLen < maxLen)
        .map((item) => item.name);
};

export const preventEventDefault = (e: any) => e.preventDefault();
export const startQuarter = (quarter: number, year: number): Date => {
    return new Date(Date.UTC(year, (quarter - 1) * 3, 1));
};

export const endQuarter = (quarter: number, year: number): Date => {
    return new Date(Date.UTC(year, quarter * 3 - 1, 1));
};

export const generateId = (search: string, entity: string, chart: string, value: string): string =>
    `${search}_${entity}_${chart}_${value}`;

export const setDocumentTitle = (title: string) => {
    document.title = `Pharma360 | ${title}`;
};
