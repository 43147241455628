import React, { memo } from 'react';
import { PharmaTreeMapChart } from '@informa/pharma-common-react-components';
import { ChartCommonProps, ChartRendererProps } from '../ChartContainer/ChartContainer';
import { ChartContainer } from '../ChartContainer';
import { formatCurrency } from '../../../core/helpers/number';

const TreemapChartRenderer = ({ data, componentProps }: ChartRendererProps) => {
    const getTooltip = (point) => {
        const value = formatCurrency(point.value);
        return `${point.name}: <b>${value}</b><br/>`;
    };

    const dataLabelFormatter = (item) => {
        const key = item.key;
        const point = item.point;
        const value = formatCurrency(point.value);

        return `${key}<br/>${value}`;
    };

    return (
        <PharmaTreeMapChart
            data={data}
            minColor='#E6DCF2'
            maxColor='#63428C'
            toolTipFormatter={getTooltip}
            showColorAxis={false}
            dataLabelFormatter={dataLabelFormatter}
            onClick={componentProps?.onClick}
        />
    );
};

const TreemapChart = (props: ChartCommonProps) => <ChartContainer {...props} component={TreemapChartRenderer} />;

export default memo(TreemapChart);
