import * as actions from './actionTypes/search.actionTypes';

export function getSearchRequest(data: string): actions.GetSearchAction {
    return {
        type: actions.GET_SEARCH_DATA,
        payload: data,
    };
}

export function getSearchSuccess(data: actions.IGetSearchSuccess): actions.GetSearchSuccessAction {
    return {
        type: actions.GET_SEARCH_SUCCESS,
        payload: data,
    };
}

export function getSearchError(data: actions.IGetSearchError): actions.GetSearchErrorAction {
    return {
        type: actions.GET_SEARCH_ERROR,
        payload: data,
    };
}
