import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import logo from '../../images/logos/PharmaIntelligence.png'
import { getCurrentYear } from '../../core/helpers/dates';
import { PRODUCT_URLS } from '../../core/api/endpoints';
import styles from './Footer.module.scss';

export default () => (
    <footer>
        <Container maxWidth="lg">
            <Box display="flex" alignItems="center">
                <div className={styles.disclaimer} data-testid="disclaimer">
                    <Typography variant="caption">
                        <p>Copyright © {getCurrentYear()} Pharma Intelligence UK Limited</p>
                        <p>Pharma Intelligence UK Limited is a company registered in England and Wales with company number 13787459 whose registered office is Suite 1, 3rd Floor, 11 - 12 St. James's Square, London, England, SW1Y 4LB. The Pharma Intelligence group is owned by Caerus Topco S.à r.l. and all copyright resides with the group.</p>
                        <a
                            className={styles.privacyPolicy}
                            href="https://www.norstella.com/privacy-notice"
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Privacy Policy
                        </a>
                    </Typography>
                </div>
                <div>
                    <a href="https://www.citeline.com" target="_blank" rel="noopener noreferrer">
                        <img src={logo} alt="Pharma Intelligence Logo" data-testid="pharma-intelligence-logo" />
                    </a>
                </div>
            </Box>
        </Container>
    </footer>
);