import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _isEqual from 'lodash-es/isEqual';
import { DASHBOARD_SECTION_TYPES } from './../../../../constants/common';
import { createEntityDataSelector } from './../../../../core/selectors/entities.selectors';
import { DashboardSectionType } from '../../../../typings';
import { PartialEntityActionPayload } from './../../../../core/actions/actionTypes/entity.actionTypes';

const createSelectorForSection = (commonConfig: PartialEntityActionPayload, sectionType: DashboardSectionType) =>
    createEntityDataSelector({ ...commonConfig, sectionType });

export function GetDatamonitorData(props: any) {
    const value: any = props.hasOwnProperty('value') ? props.value : props.id;
    const commonConfig = useMemo(
        () => ({
            entityType: props.search,
            entityId: value,
        }),
        [props.search, value]
    );
    return useSelector(createSelectorForSection(commonConfig, DASHBOARD_SECTION_TYPES.DATA_MONITOR_DATA), _isEqual);
}
