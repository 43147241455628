import { put, call, takeLatest, all, fork } from 'redux-saga/effects';
import * as actions from '../actions/filters.actions';
import * as actionTypes from '../actions/actionTypes/filters.actionTypes';
import { getFilters } from '../services/filters.service';

export function* onGetFilters({ payload }: any): Generator<any, any, any> {
    try {
        const data = yield call(getFilters, payload);
        yield put(actions.getFiltersSuccess(data));
    } catch (error) {
        yield put(actions.getFiltersError(error));
    }
}

function* watchOnGetFilters() {
    yield takeLatest(actionTypes.GET_FILTERS, onGetFilters);
}

export default function* filtersSaga() {
    yield all([
        fork(watchOnGetFilters)
    ]);
}