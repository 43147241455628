import { put, call, takeEvery, all, fork } from 'redux-saga/effects';
import * as actions from '../actions/projects.actions';
import * as actionTypes from '../actions/actionTypes/projects.actionTypes';
import { getProjectsPaged, getProjects } from '../services/projects.service';

export function* onGetProjectPaged({ payload }: actionTypes.GetProjectsPagedAction): Generator<any, any, any> {
    try {
        const { data } = yield call(getProjectsPaged, payload);
        const { projects, count } = data;
        yield put(actions.getProjectsPagedSuccess({ pagedProjects: projects, count }));
    } catch (error) {
        yield put(actions.getProjectsPagedError(error));
    }
}

export function* onGetProjectsPaged({ payload }: actionTypes.GetProjectsPagedAction): Generator<any, any, any> {
    try {
        const { data } = yield call(getProjectsPaged, payload);
        const { projects, count } = data;
        yield put(actions.getProjectsPagedSuccess({ pagedProjects: projects, count }));
    } catch (error) {
        yield put(actions.getProjectsPagedError(error));
    }
}

export function* onGetProjects(): Generator<any, any, any> {
    try {
        const { data } = yield call(getProjects);
        const { projects } = data;
        yield put(actions.getProjectsSuccess({ projects }));
    } catch (error) {
        yield put(actions.getProjectsError(error));
    }
}

function* watchOnGetProjectsPaged() {
    yield takeEvery(actionTypes.GET_PROJECTS_PAGED, onGetProjectsPaged);
}

function* watchOnGetProjects() {
    yield takeEvery(actionTypes.GET_PROJECTS, onGetProjects);
}

export default function* projectsSaga() {
    yield all([
        fork(watchOnGetProjectsPaged),
        fork(watchOnGetProjects)
    ]);
}