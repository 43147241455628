import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { SortDirection } from 'react-virtualized';
import { CounterChart } from '../../Charts/CounterChart';
import { StackedColumnChart } from '../../Charts/StackedColumnChart';
import { StackedBarChart } from '../../Charts/StackedBarChart';
import { TableChart } from '../../Charts/TableChart';
import { CHART_TYPES } from '../../../constants/common';
import DeepDivePanel from '../Shared/DeepDivePanel/DeepDivePanel';
import { createChartClickHandlerFactory, createChartLabelClickHandlerFactory } from '../../../core/helpers/filter';
import {
    averageEventsPerIndicationValueBuilder,
    nearestCatalystDateValueBuilder,
} from '../../../core/helpers/deep-dive';
import { DeepDiveContentProps } from '../typings';
import {
    TodayDivider,
    catalystsYAxisConfig,
    eventsYAxisConfig
} from '../../TileContent/EventsCatalysts/EventsCatalysts';

import styles from '../DeepDiveContent.module.scss';

const chartPaperProps = {
    className: styles.deepDiveChartsContainerItem,
    elevation: 0,
};

export default memo((props: DeepDiveContentProps) => {
    const createChartClickHandler = createChartClickHandlerFactory(props.onClick);
    const createChartLabelClickHandler = createChartLabelClickHandlerFactory(props.onClick);
    const onOpenDialog = (chartType) => props.onOpenDialog(chartType);

    return (
        <Grid container spacing={3}>
            {/*First line top*/}
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.TOTAL_EVENTS_COUNT_DEEP_DIVE,
                        }}
                        componentProps={{
                            label: 'Total Events (5 years)',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.TOTAL_EVENTS_COUNT_ALL_TIME,
                        }}
                        componentProps={{
                            label: 'Total Events',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.CALCULATED_MANUALLY,
                        }}
                        componentProps={{
                            label: 'Average Events per Indication',
                            inverted: true,
                        }}
                        dependencies={[
                            CHART_TYPES.TOTAL_EVENTS_COUNT_DEEP_DIVE,
                            CHART_TYPES.INDICATIONS_OVER_PAST_5_YEARS,
                        ]}
                        valueBuilder={averageEventsPerIndicationValueBuilder}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>

            {/*First line bottom*/}
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.TOTAL_CATALYSTS_COUNT_DEEP_DIVE,
                        }}
                        componentProps={{
                            label: 'Total Catalysts (5 years)',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.TOTAL_CATALYSTS_COUNT_ALL_TIME,
                        }}
                        componentProps={{
                            label: 'Total Catalysts',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.NEAREST_CATALYST_DATE,
                        }}
                        componentProps={{
                            label: 'Earliest Catalyst',
                            inverted: true,
                        }}
                        valueBuilder={nearestCatalystDateValueBuilder}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>

            {/*Second line*/}
            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Event Type'
                    disableElevation
                    heightVariant='large'
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.EVENTS_TYPE)}
                    tileProps={{
                        ...props,
                        chart: CHART_TYPES.EVENTS_TYPE,
                    }}
                    filters={props.filters}>
                    <StackedBarChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.EVENTS_TYPE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.EVENTS_TYPE),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.EVENTS_TYPE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Catalyst Type'
                    disableElevation
                    heightVariant='large'
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.CATALYSTS_TYPE)}
                    tileProps={{
                        ...props,
                        chart: CHART_TYPES.CATALYSTS_TYPE,
                    }}
                    filters={props.filters}>
                    <StackedBarChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.CATALYSTS_TYPE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.CATALYSTS_TYPE),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.CATALYSTS_TYPE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>

            {/*Third line*/}
            <Grid item xs={6} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Historical Events'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.HISTORICAL_EVENTS_DEEP_DIVE)}
                    tileProps={{
                        ...props,
                        chart: CHART_TYPES.HISTORICAL_EVENTS_DEEP_DIVE,
                    }}
                    filters={props.filters}>
                    <StackedColumnChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.HISTORICAL_EVENTS_DEEP_DIVE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.HISTORICAL_EVENTS_DEEP_DIVE),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.HISTORICAL_EVENTS_DEEP_DIVE),
                            options: {
                                chartOptions: {
                                    yAxis: eventsYAxisConfig,
                                },
                            },
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
                <TodayDivider
                    {...props}
                    chart={CHART_TYPES.HISTORICAL_EVENTS_DEEP_DIVE}
                    className={styles.deepDiveChartsContainerTodayDivider}
                />
            </Grid>
            <Grid item xs={6} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Upcoming Catalysts'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.FUTURE_CATALYSTS)}
                    tileProps={{
                        ...props,
                        chart: CHART_TYPES.FUTURE_CATALYSTS,
                    }}
                    filters={props.filters}>
                    <StackedColumnChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.FUTURE_CATALYSTS,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.FUTURE_CATALYSTS),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.FUTURE_CATALYSTS),
                            options: {
                                chartOptions: {
                                    yAxis: catalystsYAxisConfig,
                                },
                            },
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>

            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Events Table'
                    disableElevation
                    heightVariant='large'
                    isDownloadAvailable
                    isImageDisabled
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.EVENTS_TABLE)}
                    tileProps={{
                        ...props,
                        chart: CHART_TYPES.EVENTS_TABLE,
                    }}
                    filters={props.filters}>
                    <TableChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.EVENTS_TABLE,
                        }}
                        componentProps={{
                            filters: props.filters,
                            defaultOrderByColumn: {
                                fieldName: 'eventdate',
                                direction: SortDirection.ASC,
                            },
                        }}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Catalysts Table'
                    disableElevation
                    heightVariant='large'
                    isDownloadAvailable
                    isImageDisabled
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.CATALYSTS_TABLE)}
                    tileProps={{
                        ...props,
                        chart: CHART_TYPES.CATALYSTS_TABLE,
                    }}
                    filters={props.filters}>
                    <TableChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.CATALYSTS_TABLE,
                        }}
                        componentProps={{
                            filters: props.filters,
                            defaultOrderByColumn: {
                                fieldName: 'expecteddaterangebegin',
                                direction: SortDirection.ASC,
                            },
                        }}
                    />
                </DeepDivePanel>
            </Grid>
        </Grid>
    );
});
