import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import HeaderNavigation from '../HeaderNavigation/HeaderNavigation';
import logo from '../../images/logos/pharma-inteligence-brand.png';
import styles from './Header.module.scss';
import cn from 'classnames';
import { userHasAccessToPharma360 } from '../../core/services/auth.service';

export interface IHeaderProps {
    showSearchBox: boolean;
}

const Header: FC<IHeaderProps> = ({
    showSearchBox
}) => {
    const isUserSubscriber = userHasAccessToPharma360()

        return (
            <header className={styles.header} data-testid="header">
                <div>
                    <Link className={cn(styles.navbarBrand, {
                        [styles.disable_area]: !isUserSubscriber
                    })} to="/">
                        <img src={logo} alt="Citeline" data-testid="brand-logo" />
                    </Link>
                </div>
                <HeaderNavigation showSearchBox={showSearchBox} isUserSubscriber={isUserSubscriber} />
            </header>
        );
}

export default Header;