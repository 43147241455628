import React, { FunctionComponent, memo } from 'react';
import { ChartContainer } from '../ChartContainer';
import { ChartCommonProps, ChartRendererProps } from '../ChartContainer/ChartContainer';
import { GENDER_ICONS, GENDER_LABELS, LOCATION_TYPES } from '../../../constants/common';
import { GenderType } from '../../../typings';
import { COUNTER_CHART_COLOR } from '../../../constants/colors';
import { PharmaCounterChart, COUNTER_VARIANTS_MAP } from '@informa/pharma-common-react-components';

import styles from './GenderDistributionChart.module.scss';
import { getCurrentYear } from '../../../core/helpers/dates';

interface GenderStackProps {
    value: number;
    percentage: number;
    genderType: GenderType;
}

const genderStackYearLabel = `${getCurrentYear()} cases`;

const genderStackCommonProps = {
    variant: COUNTER_VARIANTS_MAP.MEDIUM,
    labelColor: COUNTER_CHART_COLOR,
    valueColor: COUNTER_CHART_COLOR
};

const GenderStack = ({ value, percentage, genderType }: GenderStackProps) => (
    <div
        className={styles.genderDistributionStack}
        data-testid={`${genderType}-stack`}
    >
        <div
            className={styles.genderDistributionStackMeasurer}
            style={{ height: `${percentage}%` }}
        />
        <div className={styles.genderDistributionStackContent}>
            <PharmaCounterChart
                label={genderStackYearLabel}
                value={value}
                {...genderStackCommonProps}
            />

            <img src={GENDER_ICONS[genderType]} alt={genderType} />

            <PharmaCounterChart
                label={GENDER_LABELS[genderType]}
                value={percentage}
                afterValue='%'
                {...genderStackCommonProps}
            />
        </div>
    </div>
);

const GenderDistributionChartRenderer: FunctionComponent<ChartRendererProps> = ({ data }) => {
    if (!(data && data.hasOwnProperty('distributionData'))) {
        return null;
    }


    const { distributionData } = data;

    return (
        <React.Fragment>
            {data.locationType === LOCATION_TYPES.COUNTRY && (
                Object.keys(distributionData).map((genderKey) => (
                    <GenderStack
                        key={genderKey}
                        value={distributionData[genderKey].value}
                        percentage={distributionData[genderKey].percentage}
                        genderType={genderKey as GenderType}
                    />
                ))
            )}
        </React.Fragment>
    )
};

export default memo((props: ChartCommonProps) => (
    <ChartContainer
        {...props}
        component={GenderDistributionChartRenderer}
    />
));