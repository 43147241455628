import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { COUNTER_VARIANTS_MAP } from '@informa/pharma-common-react-components';
import { CounterChart } from '../../Charts/CounterChart';
import { SummaryChart } from '../../Charts/SummaryChart';
import { DoughnutChart } from '../../Charts/DoughnutChart';
import { StackedColumnChart } from '../../Charts/StackedColumnChart';
import { StackedBarChart } from '../../Charts/StackedBarChart';
import { TableChart } from '../../Charts/TableChart';
import { CHART_TYPES } from '../../../constants/common';
import DeepDivePanel from '../Shared/DeepDivePanel/DeepDivePanel';
import { createChartClickHandlerFactory, createChartLabelClickHandlerFactory } from '../../../core/helpers/filter';
import { DeepDiveContentProps } from '../typings';

import styles from '../DeepDiveContent.module.scss';

const chartPaperProps = {
    className: styles.deepDiveChartsContainerItem,
    elevation: 0,
};

export default memo((props: DeepDiveContentProps) => {
    const createChartClickHandler = createChartClickHandlerFactory(props.onClick);
    const createChartLabelClickHandler = createChartLabelClickHandlerFactory(props.onClick);
    const onOpenDialog = (chartType) => props.onOpenDialog(chartType);

    return (
        <Grid container spacing={3}>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.COUNT,
                        }}
                        componentProps={{
                            label: 'Total Number of Trials',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <SummaryChart
                        {...props}
                        direction='row'
                        size={COUNTER_VARIANTS_MAP.LARGE}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Trial Phase'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.PHASE)}
                    tileProps={{
                        ...props,
                        chart: CHART_TYPES.PHASE,
                    }}
                    filters={props.filters}>
                    <DoughnutChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.PHASE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.PHASE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Trial Timeline'
                    titleCaption='(Start Date)'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TRIAL_TIMELINE)}
                    tileProps={{
                        ...props,
                        chart: CHART_TYPES.TRIAL_TIMELINE,
                    }}
                    filters={props.filters}>
                    <StackedColumnChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.TRIAL_TIMELINE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.TRIAL_TIMELINE),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.TRIAL_TIMELINE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={6} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.SPONSOR_COUNT,
                        }}
                        componentProps={{
                            label: 'Number of Sponsors',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={6} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.DRUG_COUNT,
                        }}
                        componentProps={{
                            label: 'Number of Drugs',
                            inverted: true,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>

            <Grid item xs={6} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Top 10 Sponsors'
                    disableElevation
                    heightVariant='large'
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TOP_SPONSORS)}
                    tileProps={{
                        ...props,
                        chart: CHART_TYPES.TOP_SPONSORS,
                    }}
                    filters={props.filters}>
                    <StackedBarChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.TOP_SPONSORS,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.TOP_SPONSORS),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.TOP_SPONSORS),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={6} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Top 10 Drugs'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TOP_DRUGS_DEEP_DIVE)}
                    tileProps={{
                        ...props,
                        chart: CHART_TYPES.TOP_DRUGS_DEEP_DIVE,
                    }}
                    filters={props.filters}>
                    <StackedBarChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.TOP_DRUGS_DEEP_DIVE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.TOP_DRUGS_DEEP_DIVE),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.TOP_DRUGS_DEEP_DIVE),
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title={''}
                    disableElevation
                    isDownloadAvailable
                    isImageDisabled
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.TABLE)}
                    tileProps={{
                        ...props,
                        chart: CHART_TYPES.TABLE,
                    }}
                    filters={props.filters}>
                    <TableChart
                        pathConfig={{
                            ...props,
                            chart: CHART_TYPES.TABLE,
                        }}
                        componentProps={{
                            filters: props.filters,
                        }}
                    />
                </DeepDivePanel>
            </Grid>
        </Grid>
    );
});
