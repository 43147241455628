import React, { ReactElement, JSXElementConstructor } from 'react';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
import ClickAwayListener from '@mui/base/ClickAwayListener'
import Paper from '@mui/material/Paper';

export interface IMenuPopperProps {
    onClickAway: (event: MouseEvent | TouchEvent) => void;
    isOpen: boolean;
    anchorRef: React.RefObject<any> | null;
    children: ReactElement<any, string | JSXElementConstructor<any>>;
    position: string;
}

export default class MenuPopper extends React.Component<IMenuPopperProps> {
    public render() {
        return (
            <Popper open={this.props.isOpen} anchorEl={this.props?.anchorRef?.current} role={undefined} transition disablePortal={false} placement="bottom-end">
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: this.props.position }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={this.props.onClickAway}>
                                {this.props.children}
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        );
    }
}
