import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from '../reducers/root.reducer';
import rootSaga from '../sagas/root.saga';

const sagaMiddleware = createSagaMiddleware();

const createStoreWithMiddleware = composeWithDevTools(
    compose(
        applyMiddleware(
            sagaMiddleware
        )
    )
)(createStore);

const configureStore = () => {
    const store = createStoreWithMiddleware(rootReducer);
    sagaMiddleware.run(rootSaga);
    return store;
};

export default configureStore;
