import React, { useMemo, memo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import _isNumber from 'lodash-es/isNumber';
import _isArray from 'lodash-es/isArray';
import { CounterChart } from '../../Charts/CounterChart';
import { SmallTableChart } from '../../Charts/TableChart';
import { ChartsBatchRequestPath } from '../../../core/actions/actionTypes/charts.actionsTypes';
import { DEEP_DIVE_REDUX_KEY } from '../../../constants/deepDiveConfig';
import { CHART_TYPES, FILTER_NAMES } from '../../../constants/common';
import DeepDivePanel from '../Shared/DeepDivePanel/DeepDivePanel';
import {
    createChartClickHandlerFactory,
    createChartLabelClickHandlerFactory,
    getForecastYearFromDependencies,
} from '../../../core/helpers/filter';
import { DeepDiveContentProps } from '../typings';
import { DoughnutChart } from '../../Charts/DoughnutChart';
import styles from '../DeepDiveContent.module.scss';
import { StackedColumnChart } from '../../Charts/StackedColumnChart';
import { formatCurrency } from '../../../core/helpers/number';
import { PivotTable } from '../../Charts/PivotTable';

const chartPaperProps = {
    className: styles.deepDiveChartsContainerItem,
    elevation: 0,
};

const today = new Date();

const valueFormatter = (value) => (_isNumber(value) ? formatCurrency(value) : 'No data');

const forecastChartLabelBuilder = ({ dependenciesData }) => {
    const forecastYear = getForecastYearFromDependencies(dependenciesData);

    return `Sales ${forecastYear}`;
};

export default memo((props: DeepDiveContentProps) => {
    const chartPathProps = useMemo<ChartsBatchRequestPath>(
        () => ({
            ...props,
            subPath: DEEP_DIVE_REDUX_KEY,
        }),
        [props]
    );
    const createChartClickHandler = createChartClickHandlerFactory(props.onClick);
    const createChartLabelClickHandler = createChartLabelClickHandlerFactory(props.onClick);
    const salesYearParam = props.filters && props?.filters[FILTER_NAMES.SALES_YEAR];
    const salesYear = salesYearParam?.value
        ? _isArray(salesYearParam?.value)
            ? salesYearParam?.value[0].name
            : salesYearParam?.value?.name
        : '';
    const onOpenDialog = (chartType) => props.onOpenDialog(chartType);

    return (
        <Grid container spacing={3}>
            <Grid item xs={3} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.CURRENT_YEAR_SALES,
                        }}
                        componentProps={{
                            label: `Sales ${today.getFullYear() - 1}`,
                            inverted: false,
                            beforeValue: '',
                            valueFormatter,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={3} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.LAST_YEAR_FORECAST,
                        }}
                        componentProps={{
                            label: 'Sales ',
                            inverted: false,
                            valueFormatter,
                            beforeValue: '',
                        }}
                        dependencies={[CHART_TYPES.MAX_FORECAST_YEAR]}
                        labelBuilder={forecastChartLabelBuilder}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={2} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <CounterChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.CAGR,
                        }}
                        componentProps={{
                            label: 'CAGR',
                            inverted: false,
                        }}
                        filters={props.filters}
                    />
                </Paper>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <Paper {...chartPaperProps}>
                    <SmallTableChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.PATENT_EXPIRY_BY_REGION,
                        }}
                    />
                </Paper>
            </Grid>
            <Grid item xs={4} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title={`Revenue by Region ${salesYear}`}
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.DRUG_FORECAST_DOUGHNUT_DEEPDIVE)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.DRUG_FORECAST_DOUGHNUT_DEEPDIVE,
                    }}
                    filters={props.filters}>
                    <DoughnutChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.DRUG_FORECAST_DOUGHNUT_DEEPDIVE,
                        }}
                        componentProps={{
                            onClick: createChartClickHandler(CHART_TYPES.DRUG_FORECAST_DOUGHNUT_DEEPDIVE),
                            dataLabelFormat: '{point.name}<br> {point.y}B ({point.percentage:.2f}%)',
                        }}
                        filters={props.filters}
                        isCurrency
                    />
                </DeepDivePanel>
            </Grid>
            <Grid item xs={8} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Forecast by region'
                    heightVariant='large'
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.REVENUE_FORECAST_BY_YEAR)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.REVENUE_FORECAST_BY_YEAR,
                    }}
                    filters={props.filters}>
                    <StackedColumnChart
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.REVENUE_FORECAST_BY_YEAR,
                        }}
                        componentProps={{
                            showLabels: true,
                            onClick: createChartClickHandler(CHART_TYPES.REVENUE_FORECAST_BY_YEAR),
                            onLabelClick: createChartLabelClickHandler(CHART_TYPES.REVENUE_FORECAST_BY_YEAR),
                            options: {
                                chartOptions: {
                                    tooltip: {
                                        formatter: function () {
                                            const point: any = this;
                                            const value = formatCurrency(point?.y);
                                            return `<span style="color:${point.color}">●</span><b>${point?.x?.name}</b><br/>${point?.series?.name}: ${value}<br/>`;
                                        },
                                    },
                                    yAxis: {
                                        stackLabels: {
                                            formatter: function () {
                                                const arg: any = this;
                                                return valueFormatter(arg.total);
                                            },
                                        },
                                    },
                                },
                            },
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>

            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Independent events'
                    isDownloadAvailable={false}
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.INDEPENDENT_EVENTS)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.INDEPENDENT_EVENTS,
                    }}
                    filters={props.filters}>
                    <PivotTable
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.INDEPENDENT_EVENTS,
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>

            <Grid item xs={12} className={styles.deepDiveChartsContainer}>
                <DeepDivePanel
                    title='Dependent events'
                    isDownloadAvailable={false}
                    disableElevation
                    onOpenDialog={() => onOpenDialog(CHART_TYPES.DEPENDENT_EVENTS)}
                    tileProps={{
                        ...chartPathProps,
                        chart: CHART_TYPES.DEPENDENT_EVENTS,
                    }}
                    filters={props.filters}>
                    <PivotTable
                        pathConfig={{
                            ...chartPathProps,
                            chart: CHART_TYPES.DEPENDENT_EVENTS,
                        }}
                        filters={props.filters}
                    />
                </DeepDivePanel>
            </Grid>
        </Grid>
    );
});
