import { FilterPanels } from '../typings';
import { CHART_TYPES, ENTITY_TYPES, FILTER_NAMES, FILTER_TYPES, LOCATION_TYPES, SEARCH_TYPES } from './common';
import { getLocationTypeFromDependencies, saleDefaultActionYear } from '../core/helpers/filter';

export const FILTER_PANELS: FilterPanels = {
    // trial and deal config is currently just a copy of pipeline filters
    [SEARCH_TYPES.COMPANY]: {
        [ENTITY_TYPES.TRIAL]: {
            drug: {
                label: 'Drug',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            trialPhase: {
                label: 'Trial Phase',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            trialStatus: {
                label: 'Trial Status',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            therapeuticArea: {
                label: 'Therapeutic Area',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            disease: {
                label: 'Disease',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            trialStartDate: {
                label: 'Start Date of Trial',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
            trialPrimaryCompletionDate: {
                label: 'Primary Completion of Trial',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
        },
        [ENTITY_TYPES.DEALS]: {
            dealType: {
                label: 'Deal Type',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            product: {
                label: 'Product',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            dealYear: {
                label: 'Deal Year',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
            dealStatus: {
                label: 'Deal Status',
                filterType: FILTER_TYPES.MULTISELECT,
            },
        },
        [ENTITY_TYPES.PIPELINE]: {
            // Commenting for now as it is not needed but not deleting these lines as it maybe needed until multi-layer filtering is possible
            // disease: {
            //     label: 'Disease',
            //     filterType: FILTER_TYPES.MULTISELECT,
            // },
            target: {
                label: 'Target',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            devStatus: {
                label: 'Development Status Group',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            diseaseStatus: {
                label: 'Development Status',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            companyType: {
                label: 'Company Type',
                filterType: FILTER_TYPES.MULTISELECT,
            },
        },
        [ENTITY_TYPES.REPORTS]: {
            reportPublishDate: {
                label: 'Publish Date',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
            contentType: {
                label: 'PDF Content Type',
                filterType: FILTER_TYPES.MULTISELECT,
            },
        },
        [ENTITY_TYPES.NEWS]: {
            articalDate: {
                label: 'Publish Date',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
            sourceProduct: {
                label: 'Source Product',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            articleType: {
                label: 'Content Type',
                filterType: FILTER_TYPES.MULTISELECT,
            },
        },
        [ENTITY_TYPES.FORECAST]: {
            therapeuticArea: {
                label: 'Therapeutic Area',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            region: {
                label: 'Region',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            drug: {
                label: 'Product Name',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            salesyear: {
                label: 'Forecast Year',
                filterType: FILTER_TYPES.MULTISELECT,
                singleMode: true,
                defaultValueSelector (options) {
                    return saleDefaultActionYear(options)
                }
            },
        },
        [ENTITY_TYPES.EVENTS_CATALYSTS]: [
            {
                eventDrug: {
                    label: 'Events Drug',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                eventImpact: {
                    label: 'Events Impact on LoA',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                eventIndication: {
                    label: 'Events Indication',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                eventType: {
                    label: 'Event Type Group',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                eventDate: {
                    label: 'Events Date',
                    filterType: FILTER_TYPES.DATE_RANGE
                }
            },
            {
                catalystDrug: {
                    label: 'Catalysts Drug',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                catalystImpactType: {
                    label: 'Catalysts Impact',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                catalystIndication: {
                    label: 'Catalysts Indication',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                catalystGroupType: {
                    label: 'Catalysts Type',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                catalystDate: {
                    label: 'Catalysts Date',
                    filterType: FILTER_TYPES.DATE_RANGE
                }
            }
        ]
    },
    [SEARCH_TYPES.DISEASE]: {
        [ENTITY_TYPES.TRIAL]: {
            drug: {
                label: 'Drug',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            trialPhase: {
                label: 'Trial Phase',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            trialStatus: {
                label: 'Trial Status',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            sponsorType: {
                label: 'Sponsor Type',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            sponsors: {
                label: 'Sponsor',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            trialStartDate: {
                label: 'Start Date of Trial',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
            trialPrimaryCompletionDate: {
                label: 'Primary Completion of Trial',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
        },
        [ENTITY_TYPES.EPIDEMIOLOGY]: {
            region: {
                label: 'Region',
                filterType: FILTER_TYPES.MULTISELECT,
                visible({ dependenciesData }) {
                    return getLocationTypeFromDependencies(dependenciesData) === LOCATION_TYPES.REGION;
                },
            },
            country: {
                label: 'Country',
                filterType: FILTER_TYPES.MULTISELECT,
                visible({ dependenciesData }) {
                    return getLocationTypeFromDependencies(dependenciesData) === LOCATION_TYPES.COUNTRY;
                },
            },
            gender: {
                label: 'Gender',
                filterType: FILTER_TYPES.MULTISELECT,
                visible({ dependenciesData }) {
                    return getLocationTypeFromDependencies(dependenciesData) === LOCATION_TYPES.COUNTRY;
                },
            },
            ageGroup: {
                label: 'Age Group',
                filterType: FILTER_TYPES.MULTISELECT,
                visible({ dependenciesData }) {
                    return getLocationTypeFromDependencies(dependenciesData) === LOCATION_TYPES.COUNTRY;
                },
            },
            forecastYear: {
                label: 'Forecast Year',
                filterType: FILTER_TYPES.MULTISELECT,
                singleMode: true,
                defaultValueSelector(options) {
                    return saleDefaultActionYear(options)
                }
            },
        },
        [ENTITY_TYPES.DEALS]: {
            drug: {
                label: 'Drug',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            company: {
                label: 'Company',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            dealYear: {
                label: 'Deal Year',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
            dealStatus: {
                label: 'Deal Status',
                filterType: FILTER_TYPES.MULTISELECT,
            },
        },
        [ENTITY_TYPES.PIPELINE]: {
            drug: {
                label: 'Drug',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            target: {
                label: 'Target',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            devStatus: {
                label: 'Development Status Group',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            diseaseStatus: {
                label: 'Development Status',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            company: {
                label: 'Company',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            companyType: {
                label: 'Company Type',
                filterType: FILTER_TYPES.MULTISELECT,
            },
        },
        [ENTITY_TYPES.REPORTS]: {
            reportPublishDate: {
                label: 'Publish Date',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
            contentType: {
                label: 'PDF Content Type',
                filterType: FILTER_TYPES.MULTISELECT,
            },
        },
        [ENTITY_TYPES.NEWS]: {
            articalDate: {
                label: 'Publish Date',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
            sourceProduct: {
                label: 'Source Product',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            articleType: {
                label: 'Content Type',
                filterType: FILTER_TYPES.MULTISELECT,
            },
        },
        [ENTITY_TYPES.FORECAST]: {
            drug: {
                label: 'Drug',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            salesyear: {
                label: 'Forecast Year',
                filterType: FILTER_TYPES.MULTISELECT,
                singleMode: true,
                defaultValueSelector (options) {
                    return saleDefaultActionYear(options)
                }
            },
            region: {
                label: 'Region',
                filterType: FILTER_TYPES.MULTISELECT,
            },
        },
        [ENTITY_TYPES.EVENTS_CATALYSTS]: [
            {
                eventDrug: {
                    label: 'Events Drug',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                eventCompany: {
                    label: 'Events Company',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                eventType: {
                    label: 'Events Type',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                eventImpact: {
                    label: 'Events Impact',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                eventDate: {
                    label: 'Events Date',
                    filterType: FILTER_TYPES.DATE_RANGE
                }
            },
            {
                catalystDrug: {
                    label: 'Catalysts Drug',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                catalystImpactType: {
                    label: 'Catalysts Impact Type',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                catalystGroupType: {
                    label: 'Catalysts Group Type',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                catalystCompany: {
                    label: 'Catalysts Company',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                catalystDate: {
                    label: 'Catalysts Date',
                    filterType: FILTER_TYPES.DATE_RANGE
                }
            }
        ]
    },
    [SEARCH_TYPES.DRUG]: {
        [ENTITY_TYPES.TRIAL]: {
            disease: {
                label: 'Disease',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            trialPhase: {
                label: 'Trial Phase',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            trialStatus: {
                label: 'Trial Status',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            sponsorType: {
                label: 'Sponsor Type',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            sponsors: {
                label: 'Sponsor',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            trialStartDate: {
                label: 'Start Date of Trial',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
            trialPrimaryCompletionDate: {
                label: 'Primary Completion of Trial',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
        },
        [ENTITY_TYPES.DEALS]: {
            disease: {
                label: 'Disease',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            company: {
                label: 'Company',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            dealYear: {
                label: 'Deal Year',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
            dealStatus: {
                label: 'Deal Status',
                filterType: FILTER_TYPES.MULTISELECT,
            },
        },
        [ENTITY_TYPES.PIPELINE]: {
            disease: {
                label: 'Disease',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            target: {
                label: 'Target',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            devStatus: {
                label: 'Development Status Group',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            diseaseStatus: {
                label: 'Development Status',
                filterType: FILTER_TYPES.MULTISELECT,
            },
        },
        [ENTITY_TYPES.REPORTS]: {
            reportPublishDate: {
                label: 'Publish Date',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
            contentType: {
                label: 'PDF Content Type',
                filterType: FILTER_TYPES.MULTISELECT,
            },
        },
        [ENTITY_TYPES.NEWS]: {
            articalDate: {
                label: 'Publish Date',
                filterType: FILTER_TYPES.DATE_RANGE,
            },
            sourceProduct: {
                label: 'Source Product',
                filterType: FILTER_TYPES.MULTISELECT,
            },
            articleType: {
                label: 'Content Type',
                filterType: FILTER_TYPES.MULTISELECT,
            },
        },
        [ENTITY_TYPES.FORECAST]: {
            salesyear: {
                label: 'Forecast Year',
                filterType: FILTER_TYPES.MULTISELECT,
                singleMode: true,
                defaultValueSelector (options) {
                    return saleDefaultActionYear(options)
                }
            },
            region: {
                label: 'Region',
                filterType: FILTER_TYPES.MULTISELECT,
            },
        },
        [ENTITY_TYPES.EVENTS_CATALYSTS]: [
            {
                eventIndication: {
                    label: 'Events Indication',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                eventImpact: {
                    label: 'Events Impact on LoA',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                eventCompany: {
                    label: 'Events Lead Company',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                eventType: {
                    label: 'Events Type',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                eventDate: {
                    label: 'Events Date',
                    filterType: FILTER_TYPES.DATE_RANGE
                }
            },
            {
                catalystIndication: {
                    label: 'Catalysts Indication',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                catalystImpactType: {
                    label: 'Catalysts Impact',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                catalystCompany: {
                    label: 'Catalysts Lead Company',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                catalystGroupType: {
                    label: 'Catalysts Type',
                    filterType: FILTER_TYPES.MULTISELECT
                },
                catalystDate: {
                    label: 'Catalysts Date',
                    filterType: FILTER_TYPES.DATE_RANGE
                }
            }
        ]
    },
};

export const DEEP_DIVE_DEPENDENCIES = {
    [SEARCH_TYPES.DISEASE]: {
        [ENTITY_TYPES.EPIDEMIOLOGY]: [CHART_TYPES.LOCATION_TYPE],
    },
};

const EVENTS_CATALYSTS_DEPENDENCIES = {
    [`${CHART_TYPES.EVENTS_TYPE}_LABEL`]: [FILTER_NAMES.EVENTS_TYPE],
    [CHART_TYPES.EVENTS_TYPE]: [FILTER_NAMES.EVENTS_IMPACT_ON_LOA, FILTER_NAMES.EVENTS_TYPE],
    [`${CHART_TYPES.CATALYSTS_TYPE}_LABEL`]: [FILTER_NAMES.CATALYSTS_TYPE],
    [CHART_TYPES.CATALYSTS_TYPE]: [FILTER_NAMES.CATALYSTS_IMPACT_ON_LOA, FILTER_NAMES.CATALYSTS_TYPE],
    [`${CHART_TYPES.HISTORICAL_EVENTS_DEEP_DIVE}_LABEL`]: [FILTER_NAMES.EVENTS_DATE],
    [CHART_TYPES.HISTORICAL_EVENTS_DEEP_DIVE]: [FILTER_NAMES.EVENTS_IMPACT_ON_LOA, FILTER_NAMES.EVENTS_DATE],
    [`${CHART_TYPES.FUTURE_CATALYSTS}_LABEL`]: [FILTER_NAMES.CATALYSTS_DATE],
    [CHART_TYPES.FUTURE_CATALYSTS]: [FILTER_NAMES.CATALYSTS_IMPACT_ON_LOA, FILTER_NAMES.CATALYSTS_DATE]
}

export const CHART_FILTER_DEPENDENCIES = {
    [SEARCH_TYPES.DISEASE]: {
        [ENTITY_TYPES.TRIAL]: {
            [CHART_TYPES.PHASE]: FILTER_NAMES.TRIAL_PHASE,
            [CHART_TYPES.TRIAL_TIMELINE]: [FILTER_NAMES.TRIAL_PHASE, FILTER_NAMES.TRIAL_START_DATE],
            [`${CHART_TYPES.TRIAL_TIMELINE}_LABEL`]: FILTER_NAMES.TRIAL_START_DATE,
            [CHART_TYPES.TOP_SPONSORS]: [FILTER_NAMES.SPONSORS, FILTER_NAMES.TRIAL_PHASE],
            [`${CHART_TYPES.TOP_SPONSORS}_LABEL`]: FILTER_NAMES.SPONSORS,
            [CHART_TYPES.TOP_DRUGS_DEEP_DIVE]: [FILTER_NAMES.DRUG, FILTER_NAMES.TRIAL_PHASE],
            [`${CHART_TYPES.TOP_DRUGS_DEEP_DIVE}_LABEL`]: FILTER_NAMES.DRUG,
        },
        [ENTITY_TYPES.DEALS]: {
            [CHART_TYPES.DEAL_STATUS]: FILTER_NAMES.DEAL_STATUS,
            [CHART_TYPES.TOP_COMPANIES_DEEP_DIVE]: [FILTER_NAMES.DEAL_STATUS, FILTER_NAMES.COMPANY],
            [`${CHART_TYPES.TOP_COMPANIES_DEEP_DIVE}_LABEL`]: FILTER_NAMES.COMPANY,
            [CHART_TYPES.TOTAL_VALUE_DEEP_DIVE]: FILTER_NAMES.DEAL_YEAR,
            [`${CHART_TYPES.TOTAL_VALUE_DEEP_DIVE}_LABEL`]: FILTER_NAMES.DEAL_YEAR,
        },
        [ENTITY_TYPES.PIPELINE]: {
            [CHART_TYPES.HIGHEST_DISEASE_STATUS]: FILTER_NAMES.DEV_STATUS,
            [CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED]: FILTER_NAMES.DISEASE_STATUS,
            [`${CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED}_LABEL`]: [
                FILTER_NAMES.DEV_STATUS,
                FILTER_NAMES.DISEASE_STATUS,
            ],
            [`${CHART_TYPES.TOP_TARGETS}_LABEL`]: FILTER_NAMES.TARGET,
            [CHART_TYPES.TOP_TARGETS]: [FILTER_NAMES.TARGET, FILTER_NAMES.DEV_STATUS],
            [CHART_TYPES.TOP_DISEASES_BY_SPONSORS]: [FILTER_NAMES.COMPANY, FILTER_NAMES.DEV_STATUS],
            [`${CHART_TYPES.TOP_DISEASES_BY_SPONSORS}`]: FILTER_NAMES.COMPANY,
        },
        [ENTITY_TYPES.EPIDEMIOLOGY]: {
            [CHART_TYPES.GENDER_DISTRIBUTION_DOUGHNUT]: FILTER_NAMES.GENDER,
            [CHART_TYPES.AGE_GENDER_DISTRIBUTION]: [FILTER_NAMES.GENDER, FILTER_NAMES.AGE_GROUP],
            [`${CHART_TYPES.AGE_GENDER_DISTRIBUTION}_LABEL`]: FILTER_NAMES.AGE_GROUP,
            [`${CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED}_LABEL`]: [
                FILTER_NAMES.DEV_STATUS,
                FILTER_NAMES.DISEASE_STATUS,
            ],
            [CHART_TYPES.EPI_GEO_MAP]: [FILTER_NAMES.COUNTRY, FILTER_NAMES.REGION],
            [CHART_TYPES.YEAR_BY_COUNTRY_DISTRIBUTION]: [FILTER_NAMES.COUNTRY, FILTER_NAMES.REGION],
            [CHART_TYPES.YEAR_BY_COUNTRY_DISTRIBUTION_DEEP_DIVE]: [FILTER_NAMES.COUNTRY, FILTER_NAMES.REGION],
            [CHART_TYPES.FULL_FORECAST_PER_YEAR]: [FILTER_NAMES.REGION, FILTER_NAMES.COUNTRY, FILTER_NAMES.FORECAST_YEAR],
            [`${CHART_TYPES.FULL_FORECAST_PER_YEAR}_LABEL`]: FILTER_NAMES.FORECAST_YEAR,
        },
        [ENTITY_TYPES.REPORTS]: {
            [CHART_TYPES.CONTENT_TYPE]: [FILTER_NAMES.CONTENT_TYPE],
            [`${CHART_TYPES.CONTENT_TYPE}_LABEL`]: [FILTER_NAMES.CONTENT_TYPE],
            [CHART_TYPES.TIMELINE]: [FILTER_NAMES.REPORT_PUBLISH_DATE],
            [`${CHART_TYPES.TIMELINE}_LABEL`]: [FILTER_NAMES.REPORT_PUBLISH_DATE],
        },
        [ENTITY_TYPES.NEWS]: {
            [CHART_TYPES.ARTICAL_SOURCE]: FILTER_NAMES.SOURCE_PRODUCT,
            [CHART_TYPES.CONTENT_TYPE]: [FILTER_NAMES.SOURCE_PRODUCT, FILTER_NAMES.ARTICLE_TYPE],
            [`${CHART_TYPES.CONTENT_TYPE}_LABEL`]: [FILTER_NAMES.ARTICLE_TYPE],
            [CHART_TYPES.ARTICAL_BY_TIME]: [FILTER_NAMES.SOURCE_PRODUCT, FILTER_NAMES.ARTICAL_PUBLISH_DATE],
            [`${CHART_TYPES.ARTICAL_BY_TIME}_LABEL`]: [FILTER_NAMES.ARTICAL_PUBLISH_DATE],
        },
        [ENTITY_TYPES.FORECAST]: {
            [`${CHART_TYPES.REVENUE_FORECAST_BY_YEAR}_LABEL`]: [FILTER_NAMES.SALES_YEAR],
            [CHART_TYPES.DISEASE_FORECAST_DOUGHNUT]: FILTER_NAMES.REGION,
            [CHART_TYPES.REVENUE_FORECAST_BY_YEAR]: [FILTER_NAMES.REGION, FILTER_NAMES.SALES_YEAR]
        },
        [ENTITY_TYPES.EVENTS_CATALYSTS]: EVENTS_CATALYSTS_DEPENDENCIES
    },
    [SEARCH_TYPES.COMPANY]: {
        [ENTITY_TYPES.TRIAL]: {
            [CHART_TYPES.PHASE]: FILTER_NAMES.TRIAL_PHASE,
            [CHART_TYPES.TRIAL_TIMELINE]: [FILTER_NAMES.TRIAL_PHASE, FILTER_NAMES.TRIAL_START_DATE],
            [`${CHART_TYPES.TRIAL_TIMELINE}_LABEL`]: FILTER_NAMES.TRIAL_START_DATE,
            [CHART_TYPES.TOP_DRUGS_DEEP_DIVE]: [FILTER_NAMES.TRIAL_PHASE, FILTER_NAMES.DRUG],
            [`${CHART_TYPES.TOP_DRUGS_DEEP_DIVE}_LABEL`]: FILTER_NAMES.DRUG,
            [CHART_TYPES.TOP_DISEASES_DEEP_DIVE]: [FILTER_NAMES.TRIAL_PHASE, FILTER_NAMES.DISEASE],
            [`${CHART_TYPES.TOP_DISEASES_DEEP_DIVE}_LABEL`]: FILTER_NAMES.DISEASE,
        },
        [ENTITY_TYPES.DEALS]: {
            [CHART_TYPES.DEAL_TYPE]: FILTER_NAMES.DEAL_TYPE,
            [CHART_TYPES.TOP_PRODUCTS]: [FILTER_NAMES.PRODUCT, FILTER_NAMES.DEAL_TYPE],
            [`${CHART_TYPES.TOP_PRODUCTS}_LABEL`]: FILTER_NAMES.PRODUCT,
            [CHART_TYPES.TOTAL_VALUE_DEEP_DIVE]: FILTER_NAMES.DEAL_YEAR,
            [`${CHART_TYPES.TOTAL_VALUE_DEEP_DIVE}_LABEL`]: FILTER_NAMES.DEAL_YEAR,
        },
        [ENTITY_TYPES.PIPELINE]: {
            [CHART_TYPES.HIGHEST_DISEASE_STATUS]: FILTER_NAMES.DEV_STATUS,
            [`${CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED}_LABEL`]: [
                FILTER_NAMES.DEV_STATUS,
                FILTER_NAMES.DISEASE_STATUS,
            ],
            [CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED]: FILTER_NAMES.DISEASE_STATUS,
            [`${CHART_TYPES.TOP_DISEASES}_LABEL`]: FILTER_NAMES.TARGET,
            [CHART_TYPES.TOP_DISEASES]: [FILTER_NAMES.DEV_STATUS, FILTER_NAMES.TARGET],
        },
        [ENTITY_TYPES.REPORTS]: {
            [CHART_TYPES.CONTENT_TYPE]: [FILTER_NAMES.CONTENT_TYPE],
            [CHART_TYPES.CONTENT_TYPE]: [FILTER_NAMES.CONTENT_TYPE],
            [`${CHART_TYPES.CONTENT_TYPE}_LABEL`]: [FILTER_NAMES.CONTENT_TYPE],
            [CHART_TYPES.TIMELINE]: [FILTER_NAMES.REPORT_PUBLISH_DATE],
            [`${CHART_TYPES.TIMELINE}_LABEL`]: [FILTER_NAMES.REPORT_PUBLISH_DATE],
        },
        [ENTITY_TYPES.NEWS]: {
            [CHART_TYPES.ARTICAL_SOURCE]: FILTER_NAMES.SOURCE_PRODUCT,
            [CHART_TYPES.CONTENT_TYPE]: [FILTER_NAMES.SOURCE_PRODUCT, FILTER_NAMES.ARTICLE_TYPE],
            [`${CHART_TYPES.CONTENT_TYPE}_LABEL`]: [FILTER_NAMES.ARTICLE_TYPE],
            [CHART_TYPES.ARTICAL_BY_TIME]: [FILTER_NAMES.SOURCE_PRODUCT, FILTER_NAMES.ARTICAL_PUBLISH_DATE],
            [`${CHART_TYPES.ARTICAL_BY_TIME}_LABEL`]: [FILTER_NAMES.ARTICAL_PUBLISH_DATE],
        },
        [ENTITY_TYPES.FORECAST]: {
            [`${CHART_TYPES.REVENUE_PER_TA_ALL_YEARS}_LABEL`]: [FILTER_NAMES.SALES_YEAR],
            [CHART_TYPES.TOTAL_REVENUE_BY_DRUG]: FILTER_NAMES.DRUG,
            [CHART_TYPES.REVENUE_PER_TA]: FILTER_NAMES.THERAPEUTIC_AREA,
        },
        [ENTITY_TYPES.EVENTS_CATALYSTS]: EVENTS_CATALYSTS_DEPENDENCIES
    },
    [SEARCH_TYPES.DRUG]: {
        [ENTITY_TYPES.TRIAL]: {
            [CHART_TYPES.PHASE]: FILTER_NAMES.TRIAL_PHASE,
            [CHART_TYPES.TRIAL_TIMELINE]: [FILTER_NAMES.TRIAL_PHASE, FILTER_NAMES.TRIAL_START_DATE],
            [`${CHART_TYPES.TRIAL_TIMELINE}_LABEL`]: FILTER_NAMES.TRIAL_START_DATE,
            [CHART_TYPES.TOP_DISEASES_DEEP_DIVE]: [FILTER_NAMES.DISEASE, FILTER_NAMES.TRIAL_PHASE],
            [`${CHART_TYPES.TOP_DISEASES_DEEP_DIVE}_LABEL`]: FILTER_NAMES.DISEASE,
            [CHART_TYPES.TOP_SPONSORS_DEEP_DIVE]: [FILTER_NAMES.SPONSORS, FILTER_NAMES.TRIAL_PHASE],
            [`${CHART_TYPES.TOP_SPONSORS_DEEP_DIVE}_LABEL`]: FILTER_NAMES.SPONSORS,
        },
        [ENTITY_TYPES.PIPELINE]: {
            [CHART_TYPES.HIGHEST_DISEASE_STATUS]: FILTER_NAMES.DEV_STATUS,
            [`${CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED}_LABEL`]: [
                FILTER_NAMES.DEV_STATUS,
                FILTER_NAMES.DISEASE_STATUS,
            ],
            [CHART_TYPES.HIGHEST_DISEASE_STATUS_REACHED]: FILTER_NAMES.DISEASE_STATUS,
            [`${CHART_TYPES.TOP_DISEASES}_LABEL`]: FILTER_NAMES.DISEASE_STATUS,
            [CHART_TYPES.TOP_DISEASES]: [FILTER_NAMES.DEV_STATUS, FILTER_NAMES.DISEASE],
        },
        [ENTITY_TYPES.DEALS]: {
            [CHART_TYPES.DEAL_STATUS]: FILTER_NAMES.DEAL_STATUS,
            [CHART_TYPES.TOP_COMPANIES_DEEP_DIVE]: [FILTER_NAMES.DEAL_STATUS, FILTER_NAMES.COMPANY],
            [`${CHART_TYPES.TOP_COMPANIES_DEEP_DIVE}_LABEL`]: FILTER_NAMES.COMPANY,
            [CHART_TYPES.TOTAL_VALUE_DEEP_DIVE]: FILTER_NAMES.DEAL_YEAR,
            [`${CHART_TYPES.TOTAL_VALUE_DEEP_DIVE}_LABEL`]: FILTER_NAMES.DEAL_YEAR,
        },
        [ENTITY_TYPES.REPORTS]: {
            [CHART_TYPES.CONTENT_TYPE]: [FILTER_NAMES.CONTENT_TYPE],
            [`${CHART_TYPES.CONTENT_TYPE}_LABEL`]: [FILTER_NAMES.CONTENT_TYPE],
            [CHART_TYPES.TIMELINE]: [FILTER_NAMES.REPORT_PUBLISH_DATE],
            [`${CHART_TYPES.TIMELINE}_LABEL`]: [FILTER_NAMES.REPORT_PUBLISH_DATE],
        },
        [ENTITY_TYPES.NEWS]: {
            [CHART_TYPES.ARTICAL_SOURCE]: FILTER_NAMES.SOURCE_PRODUCT,
            [CHART_TYPES.CONTENT_TYPE]: [FILTER_NAMES.SOURCE_PRODUCT, FILTER_NAMES.ARTICLE_TYPE],
            [`${CHART_TYPES.CONTENT_TYPE}_LABEL`]: [FILTER_NAMES.ARTICLE_TYPE],
            [CHART_TYPES.ARTICAL_BY_TIME]: [FILTER_NAMES.SOURCE_PRODUCT, FILTER_NAMES.ARTICAL_PUBLISH_DATE],
            [`${CHART_TYPES.ARTICAL_BY_TIME}_LABEL`]: [FILTER_NAMES.ARTICAL_PUBLISH_DATE],
        },
        [ENTITY_TYPES.FORECAST]: {
            [`${CHART_TYPES.REVENUE_FORECAST_BY_YEAR}_LABEL`]: [FILTER_NAMES.SALES_YEAR],
            [CHART_TYPES.DRUG_FORECAST_DOUGHNUT_DEEPDIVE]: FILTER_NAMES.REGION,
            [CHART_TYPES.REVENUE_FORECAST_BY_YEAR]: [FILTER_NAMES.REGION, FILTER_NAMES.SALES_YEAR]
        },
        [ENTITY_TYPES.EVENTS_CATALYSTS]: EVENTS_CATALYSTS_DEPENDENCIES
    }
};
