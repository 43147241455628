import React, { FC, useEffect  } from 'react';
import ProgressBar from '../../ProgressBar/ProgressBar';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import styles from './logoutCallback.module.scss';
import { onClearUpStorageAuthStrategy } from '../../../hooks/useSSOAuthenticator';

const LogOutCallback:FC = () => {
    const { logout } = useAuth0();

    useEffect(() => {
        onClearUpStorageAuthStrategy();
        logout();
    }, [logout])

    return (
        <div className={styles.logout_callback_container}>
            <h1>Your session is expired. Redirecting you to the login page...</h1>
            <ProgressBar/>
        </div>
    )
}

export default withAuthenticationRequired(LogOutCallback)