import React from 'react';
import ProgressBar from '../../ProgressBar/ProgressBar';
import { loginCallback } from '../../../core/services/auth.service';

export class LoginCallback extends React.Component {
    componentDidMount() {
        loginCallback();
    }

    public render() {
        return <ProgressBar />;
    }
}

export default LoginCallback;